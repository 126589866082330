import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ReactRoundedImage from "react-rounded-image";
import GitIcon from "../resources/GitIcon.JPG";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/FindInPage";
import { useHistory } from "react-router-dom";
import {signOutLogOut} from '../firebaseConfig'
export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small">
            <ReactRoundedImage
              image={localStorage.userImage || GitIcon}
              roundedSize="2"
              imageWidth="70"
              imageHeight="75"
            ></ReactRoundedImage>

            {/* <Avatar sx={{ width: 32, height: 32 }}>...</Avatar> */}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
      {localStorage.getItem("profile") === "provider" || localStorage.getItem("profile") === "seeker" ?
        <MenuItem
          onClick={() => {
            if (localStorage.getItem("profile") === "provider")
              history.push("/gigproviderjobposts/");
            if (localStorage.getItem("profile") === "seeker")
              history.push(
                "/gigseekersignup/"
              );
            if (localStorage.getItem("profile") === "admin")
              history.push("/admin");
          }}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "black",
          }}
        >
          <Avatar sx={{ color: "black" }} /> Profile
        </MenuItem>
        :
        <MenuItem
          onClick={() => {
            if (localStorage.getItem("profile") === "provider")
              history.push("/gigproviderjobposts/");
            if (localStorage.getItem("profile") === "seeker")
              history.push(
                "/gigseekersignup/"
              );
            if (localStorage.getItem("profile") === "admin")
              history.push("/admin");
          }}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "black",
          }}
        >
          <Avatar sx={{ color: "black" }} /> Home
        </MenuItem>
        }
      
       
        <MenuItem
          onClick={() => {
            if (localStorage.getItem("profile") === "provider")
            history.push("/providersearchjobListing");
            if(localStorage.getItem("profile") === "seeker") 
            history.push("/seekersearchjobListing");
            if(localStorage.getItem("profile") === "admin")
            history.push("/admin/SeekerList");
          }}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "black",
          }}
        >
          <ListItemIcon sx={{ color: "black" }}>
            <SearchIcon />
          </ListItemIcon>{" "}
          {"Search"}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            localStorage.clear();
            signOutLogOut();
            history.push("/");
          }}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "black",
          }}
        >
          <ListItemIcon sx={{ color: "black" }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
