import { React, useEffect, useState } from "react";
import { Grid, TextField, makeStyles, IconButton } from "@material-ui/core";
import axios from "axios";
import Download from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Accordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { backendUrl } from "../config";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Controls from "../resusable/controls/Controls";
import TimeSheet from "../home/timeSheet";
import Snackbar from "@material-ui/core/Snackbar";
import CachedIcon from "@mui/icons-material/Cached";
import download from "downloadjs";
import {_auth} from "../../ThirdpartyConfig"
const WhiteTextTypography = withStyles({
  root: {
    color: "#34495e",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right !important",
  },
  textFeild: {
    margin: "10px !important",
  },
  pageTitle: {
    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

const ExtraSeekerDetails = (props) => {
  let hourlyRate = props.hourlyPay;
  const [totalPay, setTotalPay] = useState(0);
  const [totalhrs, setTotalhrs] = useState(0);
  const [applicantStatusData, setApplicantStatusData] = useState({
    gigSeeker: props.gigSeekerId,
    gigProvider: props.gigProviderId,
    gigJob: props.gigJobId,
    gigSeekerCoverNote: "",
    gigProviderComments: "",
    resume: "",
    resumeId: "",
    comments: "",
    timeLog: [
      {
        date: "",
        time: "",
        paymentId: "-",
        paymentStatus: "Submited",
        payoutAmount: "",
      },
    ],
  });
  const [applicantStatus, setApplicantStatus] = useState("");

  const [CircularProgressHidden, setCircularProgressHidden] = useState(true);
  const [timeLogItem, setTimeLog] = useState({
    date: "",
    time: "",
    paymentId: "-",
    paymentStatus: "Submited",
    payoutAmount: "",
  });

  const paymentList = ["Razorpay", ""];
  const [ammountToPay, setAmmountToPay] = useState(0);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [TimeSheetOpen, sethandleTimeSheetOpen] = useState(true);
  const [paymentDataToShow, setPaymentDataToShow] = useState("");
  const handlepaymentOpen = (dataToShow) => {
    setPaymentDataToShow(dataToShow);
    setPaymentOpen(true);
  };
  const handlepaymentClose = () => {
    setPaymentOpen(false);
  };

  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };

  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitOpen = () => {
    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };
  const handleRejectSubmit = () =>{

    console.log("Test++", applicantStatusData)
    let reqData = {
      gigProvider : applicantStatusData.gigProvider._id,
      gigSeeker : applicantStatusData.gigSeeker._id,
      gigJob : applicantStatusData.gigJob._id,
      status: "Rejected"
    };
    let _status=updateApplicantStatus();
    axios
      .post(`${backendUrl}/applicantRejectStatus`, {
        ...reqData,
      })
      .then(
        (response) => {  
        sendApplicantStausChangedNotification(_status)
        //console.log("-sent status data-", response.data);
        },
        (error) => {
          console.log("error" + error);
        }
      );  
  }

  const [selectedRows, setSelection] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleTimeSheetOpen = () => {
    sethandleTimeSheetOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getApplicantStaus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (applicantStatusData.resume != "") handleOnUploadResume(e);
    applicantStatusData.resume = "";

     postApplicantStaus();
  };

  const handleDisable = (e) => {
    if (
      props.from === "seeker" &&
      applicantStatus != "" &&
      applicantStatus != undefined
    ) {
      return true;
    } else if (props.from === "provider" && applicantStatus != "Reviewed") {
      return true;
    } else if (props.from === "admin" && applicantStatus != "Applied") {
      return true;
    } else return false;
  };

  const updateApplicantStatus = () => {
    if (applicantStatus === "Hired") return "Hired";
    else {
      if (props.from === "seeker") {
        setApplicantStatus("Applied");
        setShowText("Applied");
        return "Applied";
      } else if (props.from === "provider") {
        setApplicantStatus("Hired");
        return "Hired";
      } else if (props.from === "admin") {
       // alert("admin here")
        setApplicantStatus("Reviewed");
        return "Reviewed";
      }
    }
  };

  const sendApplicantStausChangedNotification = (status) => {
    let reqData = { emailId:"", msg: "<br/> Your application status has changed to "+status };
   let seekerId="",providerId="",jobId="";
   if(props.from==="seeker")
{
 seekerId= applicantStatusData.gigSeeker;
 providerId= applicantStatusData.gigProvider;
 jobId= applicantStatusData.gigJob;
}
else
{
  seekerId= applicantStatusData.gigSeeker._id;
  providerId= applicantStatusData.gigProvider._id;
  jobId= applicantStatusData.gigJob._id;
}
    console.log(
      "applicationstatusdata" +
      seekerId+
      "/" +
      providerId+
      "/" +
      jobId
    );

      axios
        .get(
          `${backendUrl}/applicantstatus/` +
          seekerId+
            "/" +
            providerId+
            "/" +
            jobId
        )
        .then((response) => {
          if(response.data.status[0].gigSeeker!=undefined)
          {
            reqData.emailId = response.data.status[0].gigSeeker.email;

            reqData.msg+=" \n <br/>Details of the GigJob \n<br/> Gig Title: "+response.data.status[0].gigJob.gigTitle
            +"\n <br/>Gig Type : "+response.data.status[0].gigJob.gigTitle+"\n Description : "+response.data.status[0].gigJob.description;
            reqData.msg+=" \n<br/> Other Details  Gig Seeker : "+response.data.status[0].gigSeeker.firstName  
            
            reqData.msg+="\n <br/> Thank You, \n <br/> Gig Jobs Team."
          }
        })
        .then(() => {
          axios
            .post(`${backendUrl}/notifier/sendNotification`, {
              ...reqData,
            })
            .then(
              (response) => { 
                if(localStorage.getItem("reject_msg") == "yes"){
                  setLoginData({
                    snackbarOpen: true,
                    snackbarMsg: "Your time sheet request is rejected",
                  });
                  localStorage.setItem("reject_msg", "");
                }else{
                  setLoginData({
                    snackbarOpen: true,
                    snackbarMsg: "Notification Successful sent!",
                  });
                }
              
              },
              (error) => {
                //console.log("error" + error);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Notification failure!"+error,
                });
              }
            );
        });
  };

  const postApplicantStaus = (obj) => {
    let reqData = obj != undefined ? obj : applicantStatusData;
    let _status=updateApplicantStatus();
    axios
      .post(`${backendUrl}/applicantstatus`, {
        ...reqData,
        status: updateApplicantStatus(),
      })
      .then(
        (response) => {  
        sendApplicantStausChangedNotification(_status)
        //console.log("-sent status data-", response.data);
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };

  const downloadResumeBySeekerAndJobId = async (
    source,
    jobId,
    fileId,
    type
  ) => {
    let ext = fileId.split('.').pop();
    if (source != undefined)
      try {
        const result = await axios.get(
          `${backendUrl}/downloadBySeekerId/` +
            source +
            "/" +
            jobId +
            "/" +
            type+
            "/"+
            ext,
          {
            responseType: "blob",
          }
        );
        const filename = fileId;
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Download Successful!",
        });
        return download(result.data, filename, "application/"+ext);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Error Downloading!",
          });
        }
      }
  };

  const updateTimeLogData = () => {
    setApplicantStatusData((prev) => {
      let obj = {
        ...prev,
        timeLog: [...prev.timeLog, { ...timeLogItem }],
      };

      postApplicantStaus(obj);
      return obj;
    });
  };

  const getApplicantStaus = () => {
    axios
      .get(
        `${backendUrl}/applicantstatus/` +
          props.gigSeekerId +
          "/" +
          props.gigProviderId +
          "/" +
          props.gigJobId
      )
      .then(
        (response) => {
          if (response.data.status[0] != undefined) {
            setApplicantStatusData(response.data.status[0]);
            setApplicantStatus(response.data.status[0].status);
          } else {
            setApplicantStatusData({
              gigSeeker: props.gigSeekerId,
              gigProvider: props.gigProviderId,
              gigJob: props.gigJobId,
              resume: "",
              resumeId: "",
              gigSeekerCoverNote: "",
              gigProviderComments: "",
              comments: "",
              timeLog: [
                {
                  date: "",
                  time: "",
                  paymentId: "",
                  paymentStatus: "",
                  payoutAmount: "",
                },
              ],
            });
          }
          //console.log("-got status data-", response);
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };

  let payoutReq = {
    account_number: "4564565826097866",    //"4564562032252248", //razzorpay acc
    contact: {
      name: "" + props.name,
      contact: props.phoneNo, //contact
      email: "" + props.email,
      type: "employee",
    },
    amount: ammountToPay * 100, //as per razorpay 1rs = 100inr
    currency: "INR",
    purpose: "payout",
    description: "Payout link for " + props.name,
    receipt: "rec",
    send_sms: true,
    send_email: true,
    queue_if_low_balance: true,
    notes: {
      notes_key_1: "Payment",
      notes_key_2: "Payment",
    },
  };

  const handlePayDisablity = (hasSelectedRows) => {
    if (
      localStorage.getItem("profile") === "admin" &&
      hasSelectedRows.length > 0
    ) {
      let flag = 0;

      hasSelectedRows.forEach((ele) => {
        if (applicantStatusData.timeLog[ele].paymentStatus != "Approved") {
          flag += 1;
        }
      });
      if (flag === 0) return false;
    }
    return true;
  };

  //live keys
  const authKeys = {
    key_id: "rzp_live_Zu4w81dHHyb2n9",
    key_secret: "EuaR9hlaIPDuiXBwI6V0Q6TA",
  };

  const handleUpdateTimeLogPaymentStatus = () => {
    setCircularProgressHidden(false);
    let reqData = applicantStatusData;
    axios
      .post(`${backendUrl}/applicantstatus/updateAllPaymentStatus`, reqData)
      .then((res) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Payment Status Updated from Razzorpay ",
        });
        applicantStatusData.timeLog = res.data.timeLogArray;
      })
      .catch(() => {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Payment Status Updated from Razzorpay ",
        });
      });
  };

  const handleOnUploadResume = async (event) => {
    event.preventDefault();
    let file = applicantStatusData.resume;
    try {
      const title = applicantStatusData.resumeId;
      const description = applicantStatusData.resumeId;
      if (title.trim() !== "" && description.trim() !== "") {
        if (file) {
          const formData = new FormData();
          formData.append("gigSeekerId", localStorage.getItem("_id") + "");
          formData.append("gigJob", props.gigJobId + "");
          formData.append("file", file);
          formData.append("title", title);
          formData.append("description", description);
          await axios
            .post(`${backendUrl}/uploadByJob`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              setLoginData({
                snackbarOpen: true,
                snackbarMsg: "File Uploaded Successfull.",
              });
            });
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please select a file to add.",
          });
        }
      } else {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Please enter all the field values.",
        });
      }
    } catch (error) {
      error.response &&
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "" + error.response.data,
        });
    }
  };

  const handlePayout = () => {
    let _paymentId = "00000";
    setCircularProgressHidden(false);
    axios
      .post(`${backendUrl}/sendPayoutLink`, payoutReq, {
        auth: {
          username: _auth.username,
          password: _auth.password,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
      })
      .then((res) => {
        setCircularProgressHidden(true);
        selectedRows.forEach((element) => {
          applicantStatusData.timeLog[element].paymentStatus =
            res.data.message.status;
          applicantStatusData.timeLog[element].paymentId = res.data.message.id;
          applicantStatusData.timeLog[element].payoutAmount =
            payoutReq.amount / 100;
          rows = getRows();
        });
        let reqData = applicantStatusData;
        _paymentId = res.data.message.id;
        setLoginData({
          snackbarOpen: true,
          snackbarMsg:
            "Status from Razzorpay: Successful payment id - " +
            res.data.message.id,
        });
        //alert("Successful payment id: " + res.data.message.id);
        axios
          .post(`${backendUrl}/applicantstatus/updatePaymentStatus`, {
            ...reqData,
            indexes: [...selectedRows],
          })
          .then((res) => {
            handlepaymentClose();
          })
          .catch(() => {
            alert("Error while updating status to server");
            handlepaymentClose();
          });
      })
      .catch((err) => {
        selectedRows.forEach((element) => {
          applicantStatusData.timeLog[element].paymentStatus = err;
          applicantStatusData.timeLog[element].paymentId = _paymentId;
          rows = getRows();
        });

        let reqData = applicantStatusData;

        selectedRows.forEach((element) => {
          applicantStatusData.timeLog[element].paymentStatus = err;
          applicantStatusData.timeLog[element].paymentId = _paymentId;
          rows = getRows();
        });
        axios
          .post(`${backendUrl}/applicantstatus/updatePaymentStatus`, {
            ...reqData,
            indexes: [...selectedRows],
          })
          .then((res) => {
            handlepaymentClose();
          });
        handlepaymentClose();
        setLoginData({
          snackbarOpen: true,
          snackbarMsg:
            "Razzorpay : " + err + ": please try again after some time",
        });
      });
  };

  const trackApplicationStatus = () => {
    if (props.from === "seeker") {
      return applicantStatus;
    }
    if (props.from === "provider") {
      if (applicantStatus === "Reviewed") return "Hire";
      else return applicantStatus;
    }
    if (props.from === "admin") {
      if (applicantStatus === "Applied") return "Review";
      else return applicantStatus;
    }
  };

  const columns = [
    { field: "date", headerName: "Date", width: 210 },
    { field: "time", headerName: "Working hours", width: 190 },
    { field: "paymentId", headerName: "Payment ID", width: 220 },
    { field: "paymentStatus", headerName: "Status", width: 220 },
    { field: "payoutAmount", headerName: "Payout (INR)", width: 220 },
    // { field: "action", headerName: "Action", width: 200 },
  ];

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [showText, setShowText] = useState(
    localStorage.getItem("profile") === "provider" ? "Hire" : "Apply"
  );
  const getRows = () => {
    let log = [];
    for (var i = 0; i < applicantStatusData.timeLog.length; i++) {
      log.push({
        id: i,
        date:
          applicantStatusData.timeLog[i].date != ""
            ? applicantStatusData.timeLog[i].date
            : "eg: 2020-11-31 - 2020-11-31",
        time:
          applicantStatusData.timeLog[i].time != ""
            ? applicantStatusData.timeLog[i].time
            : "00",
        paymentId:
          applicantStatusData.timeLog[i].paymentId != ""
            ? applicantStatusData.timeLog[i].paymentId
            : "paout_0000xx00uns",
        paymentStatus:
          applicantStatusData.timeLog[i].paymentStatus != ""
            ? applicantStatusData.timeLog[i].paymentStatus
            : "Submited/Approved/Paid",

        payoutAmount:
          applicantStatusData.timeLog[i].payoutAmount != ""
            ? applicantStatusData.timeLog[i].payoutAmount
            : "0",
        action: "...",
      });
    }
    return log;
  };
  let rows = getRows();
  const classes = useStyles();
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loginData.snackbarOpen}
        autoHideDuration={5000}
        message={loginData.snackbarMsg}
        action={[
          <IconButton key="close" color="inherit" onClick={snackBarCloseEvent}>
            x
          </IconButton>,
        ]}
        onClose={() => setLoginData({ snackbarOpen: false })}
      />

      <Grid item sm={12}>
        <Accordion elevation={0}>
          <AccordionSummary>
            <Grid item sm={10} />
            <Grid item sm={2}>
            <div>
                <Controls.Button
                  text={
                    <a
                      size="small"
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Reject
                    </a>
                  }
                  onClick={() => {
                    handleRejectSubmit();
                  }}
                />
              </div>
              </Grid>
              <Grid item sm={2}>
              <div>
                <Controls.Button
                  type="submit"
                  text={
                    <a
                      size="small"
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      {applicantStatusData.status === "" ||
                      applicantStatusData.status === undefined
                        ? "" + showText
                        : " " + trackApplicationStatus()}
                    </a>
                  }
                />
              </div>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid sm={12} justify="flex-end">
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid item sm={12}>
                    <WhiteTextTypography variant="body1">
                      <br />
                      Cover Note
                      <br />
                    </WhiteTextTypography>
                    <TextField
                      id="Note"
                      disabled={props.from === "seeker" ? false : true}
                      label="Add Note"
                      multiline
                      rows={4}
                      fullWidth
                      placeholder="Enter a note to the recruiter upto 120 characters."
                      variant="filled"
                      value={applicantStatusData.gigSeekerCoverNote}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .toString()
                          .slice(0, 120);
                      }}
                      onChange={(e) => {
                        setApplicantStatusData({
                          ...applicantStatusData,
                          gigSeekerCoverNote: e.target.value.toString(),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Grid item sm={1}>
                      <WhiteTextTypography variant="body1">
                        <br />
                        Resume
                        <br />
                      </WhiteTextTypography>
                    </Grid>
                    <Grid item sm={2} name="resume">
                      <input
                        type="file"
                        disabled={props.from === "seeker" ? false : true}
                        accept=".pdf,.docx"
                        name="resume"
                        onChange={async (e) => {
                          setApplicantStatusData({
                            ...applicantStatusData,
                            resume: e.target.files[0],
                            resumeId: e.target.files[0].name,
                          });
                        }}
                      />
                      {/* <div  hidden={
                              ((applicantStatusData.gigSeeker != undefined &&
                                applicantStatusData.gigSeeker.resumeId !=
                                  undefined &&
                                applicantStatusData.gigSeeker.resumeId.length) > 0)
                            }><br/>
                      * CV/Resume uploaded in profile will be submitted .</div> */}
                    </Grid>
                    <br />
                    <Grid item sm={1}>
                      <Grid container name="resume dowload">
                        <Grid item xs={6}>

                          <Download  
                            hidden={!applicantStatusData.resumeId.length > 0}
                            disabled={props.from === "seeker" ? false : true}
                            onClick={() =>
                              downloadResumeBySeekerAndJobId(
                                applicantStatusData != undefined &&
                                  applicantStatusData.gigSeeker != undefined &&
                                  applicantStatusData.gigSeeker._id != undefined
                                  ? applicantStatusData.gigSeeker._id
                                  : undefined,
                                props.gigJobId,
                                applicantStatusData.gigSeeker.resumeId,
                                "application"
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <WhiteTextTypography variant="body1">
                            {applicantStatusData != undefined &&
                            applicantStatusData.gigSeeker != undefined &&
                            applicantStatusData.gigSeeker.resumeId === undefined
                              ? ""
                              : applicantStatusData != undefined &&
                                applicantStatusData.resumeId}
                          </WhiteTextTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <br />
                    <Grid item sm={6}></Grid>
                    <Grid item sm={2}>
                      <br />
                    </Grid>

                    <Grid item sm={2}>
                      <br />
                      <div>
                        <Controls.Button
                          type="submit"
                          text={
                            applicantStatus === "" ||
                            applicantStatus === undefined
                              ? "Submit"
                              : trackApplicationStatus()
                          }
                          //className={classes.submitBtn}
                          disabled={handleDisable()}
                          onClick={handleClickOpen}
                        />
                      </div>
                    </Grid>
                    <Grid item sm={2}>
                      <br />
                      <div>
                        <Controls.Button
                          type="submit"
                          text="Time Sheet"
                          //className={classes.submitBtn}
                          onClick={handleTimeSheetOpen}
                          disabled={applicantStatus === "Hired" ? false : true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* payment dialog */}
                  <Dialog
                    onClose={handleSubmitClose}
                    open={SubmitOpen}
                    style={{ border: "1px solid", borderColor: "black" }}
                  >
                    <DialogTitle
                      id="simple-dialog-title"
                      style={{ background: "white" }}
                    >
                      <Grid item xs={12}>
                        <Grid container name="Submit">
                          <Grid item xs={12}>
                            <Typography variant="h4" align="center">
                              <CreditScoreIcon></CreditScoreIcon>
                              <br />
                              Alert!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <Paper style={{ background: "white" }}>
                      <Grid container name="Submit" className="GridMarginStyle">
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <Typography align="center">
                            You are going to send payment details to send payout
                            link. Click on Confirm to continue.
                            <br />
                            <br />
                            <br />
                          </Typography>
                          <Grid item xs={12}>
                            <Controls.Button
                              text="Confirm "
                              onClick={() => {
                                setSubmitOpen(false);
                                handlePayout();
                              }}
                            />
                            <div className={classes.submitBtn}>
                              <Controls.Button
                                text="Cancel"
                                onClick={handleSubmitClose}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} />
                        <br />
                      </Grid>
                      <br />
                    </Paper>
                  </Dialog>

                  <Dialog
                    onClose={handlepaymentClose}
                    open={paymentOpen}
                    style={{ border: "1px solid", borderColor: "black" }}
                  >
                    <DialogTitle
                      id="simple-dialog-title"
                      style={{ background: "white" }}
                    >
                      <Grid item xs={12}>
                        <Grid container name="Submit">
                          <Grid item xs={12}>
                            <Typography variant="h4" align="center">
                              <div hidden={CircularProgressHidden}>
                                <CircularProgress
                                  hidden={CircularProgressHidden}
                                />
                              </div>
                              <EventNoteIcon></EventNoteIcon>
                              <br /> Payment
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <Paper style={{ background: "white" }}>
                      <Grid container name="Submit" className="GridMarginStyle">
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <Typography align="center">
                            You are going to send payoutlink to seeker @ "
                            {paymentDataToShow}".
                          </Typography>
                          <Autocomplete
                            id="paymentTypeComboBox"
                            options={paymentList}
                            style={{ marginRight: 18 }}
                            fullWidth
                            getOptionLabel={(option) => option}
                            defaultValue={paymentList[0]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="PaymentType"
                                variant="outlined"
                                //required
                                margin="normal"
                              />
                            )}
                          />
                          <TextField
                            label="Amont to pay"
                            name="ammountToPay"
                            variant="outlined"
                            type="number"
                            fullWidth
                            placeholder={
                              "Ammount: " +
                              hourlyRate +
                              "Rs/hr * " +
                              totalhrs +
                              "hr = " +
                              totalPay +
                              " Rs"
                            }
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setAmmountToPay(e.target.value)}
                          />
                          <Grid item xs={12}>
                            <Controls.Button
                              text="Send Payout Link"
                              onClick={() => {
                                setSubmitOpen(true);
                              }}
                            />
                            <div className={classes.submitBtn}>
                              <Controls.Button
                                text="Cancel"
                                onClick={handlepaymentClose}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} />
                        <br />
                      </Grid>
                      <br />
                    </Paper>
                  </Dialog>

                  <div hidden={TimeSheetOpen}>
                    <Grid item xs={12}>
                      <Grid container name="Time Sheet">
                        <Grid item xs={10}>
                          <WhiteTextTypography variant="h5">
                            <EventNoteIcon></EventNoteIcon> &nbsp; Time Sheet
                            &nbsp; &nbsp;
                            <IconButton>
                              <CachedIcon
                                onClick={() => {
                                  handleUpdateTimeLogPaymentStatus();
                                }}
                              />
                            </IconButton>
                          </WhiteTextTypography>
                        </Grid>
                        <Grid item xs={1}>
                          {/* <Controls.Button
                            type="submit"
                            text="Download"
                            //className={classes.submitBtn}
                          /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <br />
                  <Paper hidden={TimeSheetOpen}>
                    <Grid
                      container
                      name="Time Sheet"
                      className="GridMarginStyle"
                    >
                      {/* <TimeSheet rowData={rows}/> */}
                      <Grid item xs={12}>
                        <br />
                        <div style={{ height: 500, width: "100%" }}>
                          <DataGrid
                            checkboxSelection
                            rows={rows}
                            columns={columns}
                            onSelectionModelChange={(newSelection) => {
                              setSelection(newSelection);
                            }}
                          />
                        </div>
                        <div style={{ margin: 10 }}>
                          <Grid container>
                            <Grid item sm={8}>
                              <WhiteTextTypography variant="body1">
                                <br />
                                Gig Provider Comments
                                <br />
                              </WhiteTextTypography>
                            </Grid>
                            <Grid item sm={2}>
                              <div
                                hidden={
                                  localStorage.getItem("profile") === "seeker"
                                }
                              >
                                <Controls.Button
                                  type="submit"
                                  text="Approve"
                                  //className={classes.submitBtn}
                                  // disabled={
                                  //   localStorage.getItem("profile") ===
                                  //   "provider"
                                  //     ? false
                                  //     : true
                                  // }
                                  onClick={() => {
                                    selectedRows.forEach((element) => {
                                      applicantStatusData.timeLog[
                                        element
                                      ].paymentStatus = "Approved";
                                    });
                                    setApplicantStatusData({
                                      ...applicantStatusData,
                                      timeLog: applicantStatusData.timeLog,
                                    });
                                    applicantStatusData.timeLog =
                                      applicantStatusData.timeLog;
                                    setCircularProgressHidden(true);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item sm={2}>
                              <div
                                hidden={
                                  localStorage.getItem("profile") === "seeker"
                                }
                              >
                                <Controls.Button
                                  type="submit"
                                  text="Reject"
                                  //className={classes.submitBtn}
                                  style={{ background: "red" }}
                                  // disabled={
                                  //   localStorage.getItem("profile") ===
                                  //   "provider"
                                  //     ? false
                                  //     : true
                                  // }
                                  onClick={() => {
                                    selectedRows.forEach((element) => {
                                      applicantStatusData.timeLog[
                                        element
                                      ].paymentStatus = "Rejected";
                                    });
                                    setApplicantStatusData({
                                      ...applicantStatusData,
                                      timeLog: applicantStatusData.timeLog,
                                    });
                                    applicantStatusData.timeLog =
                                      applicantStatusData.timeLog;
                                    setCircularProgressHidden(true);
                                    localStorage.setItem("reject_msg", "yes");
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <>
                            <br />
                            <TextField
                              id="Note"
                              disabled={
                                props.from === "provider" ? false : true
                              }
                              label="Add Comments"
                              multiline
                              rows={4}
                              fullWidth
                              placeholder="Comments upto 120 characters."
                              variant="filled"
                              value={applicantStatusData.gigProviderComments}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, 360);
                              }}
                              onChange={(e) => {
                                setApplicantStatusData({
                                  ...applicantStatusData,
                                  gigProviderComments:
                                    e.target.value.toString(),
                                });
                              }}
                            />
                          </>
                        </div>
                        <br />
                      </Grid>
                      <>
                        <Grid item xs={3} style={{ marginRight: 10 }} hidden={
                                  localStorage.getItem("profile") === "provider" || 
                                  localStorage.getItem("profile") === "admin"
                                }>
                          <TextField
                            label="From Date"
                            name="fromDate"
                            variant="outlined"
                            //required
                            fullWidth
                            type="date"
                            format={"DD/MM/YYYY"}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              setTimeLog({
                                ...timeLogItem,
                                date: e.target.value,
                              })
                            }
                          />
                        </Grid>

                        <Grid item xs={3} style={{ marginRight: 10 }} hidden={
                                  localStorage.getItem("profile") === "provider" || 
                                  localStorage.getItem("profile") === "admin"
                                }>
                          <TextField
                            label="To Date"
                            name="toDate"
                            variant="outlined"
                            //required
                            fullWidth
                            type="date"
                            format={"DD/MM/YYYY"}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              let startDateValue = timeLogItem.date;
                              let value = e.target.value;
                              if (e.target.value <= startDateValue) {
                                value = "";
                                e.target.value = "";
                                alert(
                                  "Error: End date should be greater than Start date, please try again."
                                );
                              }
                              if (value != "")
                                setTimeLog({
                                  ...timeLogItem,
                                  date:
                                    timeLogItem.date + " - " + e.target.value,
                                });
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ marginRight: 10 }} hidden={
                                  localStorage.getItem("profile") === "provider" || 
                                  localStorage.getItem("profile") === "admin"
                                }>
                          <TextField
                            label="Hours Worked"
                            name="hoursWorked"
                            variant="outlined"
                            //required
                            fullWidth
                            placeholder="0"
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              setTimeLog({
                                ...timeLogItem,
                                time: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} hidden={
                                  localStorage.getItem("profile") === "provider" || 
                                  localStorage.getItem("profile") === "admin"
                                }> 
                          <br />
                          <div
                            hidden={
                              localStorage.getItem("profile") === "admin"
                                ? true
                                : false
                            }
                          >
                            <Controls.Button
                              type="submit"
                              text="Add"
                              //className={classes.submitBtn}
                              disabled={
                                localStorage.getItem("profile") === "seeker"
                                  ? false
                                  : true
                              }
                              onClick={updateTimeLogData}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} hidden={
                                  localStorage.getItem("profile") === "provider"
                                }>
                          <br />
                          <div hidden={
                                  localStorage.getItem("profile") === "seeker"
                                }>
                            <Controls.Button
                              type="submit"
                              text="Pay"
                              //className={classes.submitBtn}
                              disabled={handlePayDisablity(selectedRows)}
                              onClick={() => {
                                let timeLogDatesToPay = "";
                                let seekerBeingPaid = "";
                                let totalhrs = 0;
                                selectedRows.forEach((element) => {
                                  timeLogDatesToPay += "" + rows[element].date;
                                  totalhrs += parseInt(rows[element].time);
                                });
                                seekerBeingPaid +=
                                  applicantStatusData.gigSeeker.firstName +
                                  " with emailId-" +
                                  applicantStatusData.gigSeeker.email;
                                setTotalhrs(totalhrs);
                                setTotalPay(parseInt(hourlyRate) * totalhrs);

                                setCircularProgressHidden(true);
                                handlepaymentOpen(
                                  seekerBeingPaid +
                                    " for the time log " +
                                    timeLogDatesToPay
                                );
                              }}
                            />
                          </div>
                        </Grid>
                      </>
                    </Grid>
                  </Paper>
                  {/* </Dialog> */}
                </Grid>
              </form>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <br />
    </div>
  );
};

export default ExtraSeekerDetails;
