import React from "react";
import { makeStyles } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
const useStyles = makeStyles((theme) => ({
  youtube : {
    color: '#eb3223',
    marginRight: '1em',
  },
  facebook : {
    color: '#4968ad',
    marginRight: '1em',
  },
  twitter: {
    color: '#49a1eb',
    marginRight: '1em',
  },
  instagram: {
    color: '#fff',
    marginRight: '1em',
  },
  
}));
export default function SocialFollow() {
  const classes = useStyles();
  return (
    <div className= {classes.socialContainer}>
      <a href="https://www.youtube.com/"
        className={classes.youtube}>
              <YouTubeIcon />
      </a>
      <a href="https://www.facebook.com/"
        className={classes.facebook}>
        <FacebookIcon />
      </a>
      <a href="https://www.twitter.com/" className={classes.twitter}>
        <TwitterIcon />
      </a>
      <a href="https://www.instagram.com/"
        className={classes.instagram}>
         <InstagramIcon />
      </a>
    </div>
  );
}