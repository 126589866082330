import React ,{ useState } from 'react';
import { makeStyles ,Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles({
    wrapper:{
        opacity: '0.7',
        border: 'solid 1px #dcdcdc',
        backgroundColor: '#fff',
        margin: '0 1rem'
    },
})
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

export default function FilterComponent() {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [checked, setChecked] = useState([0]);
  const [personName, setPersonName] = useState([]);
  const [values, setValues] = useState({
    amount: ''})

  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange =(prop)=> (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
        <Box className={classes.wrapper}>
            <List component="nav">
                <ListItemButton>
                <Typography variant="subtitle2" className={classes.jobHeading}>Filter By</Typography>
                <Typography variant="subtitle2" className={classes.jobHeading}>Reset</Typography>
                </ListItemButton>
            </List>
            <Divider />
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <Typography variant="subtitle1" className={classes.jobHeading}>Gig Type</Typography>
                {[0, 1, 2, 3].map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                <ListItem
                    key={value}
                    secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                    </IconButton>
                    }
                    disablePadding >
                    <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                    <ListItemIcon>
                        <Checkbox
                        edge="start"
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                    </ListItemButton>
                </ListItem>
                ); })}
                
          </List>
          <List>
            <Typography variant="subtitle1" className={classes.jobHeading}>Skills</Typography>
              <FormControl sx={{ m: 1, width: 250, mt: 3 }}>
                  <Select
                      multiple
                      displayEmpty
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                          if (selected.length === 0) {
                              return <em>Placeholder</em>;
                          }

                          return selected.join(', ');
                      }}
                      
                  >
                      <MenuItem disabled value="">
                          <em>Placeholder</em>
                      </MenuItem>
                      {names.map((name) => (
                          <MenuItem
                              key={name}
                              value={name}
                             
                          >
                              {name}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
          </List>
          <List>
          <Typography variant="subtitle1" className={classes.jobHeading}>Hourly Rate</Typography>
            <FormControl sx={{ m: 1, width: 250, mt: 3 }} variant="filled">
                <FilledInput
                    id="filled-adornment-amount"
                    value={values.amount}
                    onChange={handleChange('amount')}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>} />
            </FormControl>
          </List>
      </Box>
  );
}
