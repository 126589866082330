import React, { useState, useEffect } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  Paper,
  Button,
  ThemeProvider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import download from "downloadjs";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MuiPhoneNumber from "material-ui-phone-number";
import { backendUrl } from "../config";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Footer from "../resusable/footer";
import Controls from "../resusable/controls/Controls";
import MainHeader from "../resusable/mainHeader";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import PageHeader from "../resusable/pageHeader";
import { useHistory } from "react-router-dom";
import Download from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  textFeild: {
    margin: "12px !important",
  },
}));

function Gigseekerupload() {
  const location = useLocation();
  let email = localStorage.getItem("emailId")//location.pathname.substring(17, location.pathname.length);
  //let  {email}  = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    emailVerified:"",
    otpVerified:"",
  });

  const loadUser = () => {
    //let emailId ={email} ; //props.emailid;
    // if(!isCurrentURL("/gigSeekerLogin"))
    // emailId=localStorage.getItem('emailId');

    axios
      .get(
        `${backendUrl}/jobseekers/` + email
        //,{headers: {"AccessToken": localStorage.getItem("token")}}
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (
            response.data.user != undefined &&
            response.data.user[0] != undefined
          ) {
            setUserData(response.data.user[0]);
            //setgigSeekerId(response.data.user[0]._id);
            setLoginData({
              snackbarOpen: true,
              snackbarMsg: "Info: Profile load Successful",
            });
          } else {
          }
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };
  const downloadResume = (source, fileId) => {
    const linkSource = source;
    const downloadLink = document.createElement("a");
    const fileName = fileId;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadResumeBySeekerId = async (source, fileId,type) => {
    let ext = fileId.split('.').pop();
    try {

      let ext = fileId.split('.').pop();
      const result = await axios.get(
        `${backendUrl}/downloadBySeekerId/` + source+"/"+type+"/"+ext,
        {
          responseType: "blob",
        }
      );
      const filename = fileId;
      setCircularProgressHidden(true);
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Download Successful!",
      });
      return download(result.data, filename, "application/"+ext);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error Downloading!",
        });
      }
    }
  };

  const downloadImageBySeekerId = async (source, fileId,type) => {
    try {
      let ext = fileId.split('.').pop();
      const result = await axios.get(
        `${backendUrl}/downloadBySeekerId/` + source+"/"+type+"/"+ext,
        {
          responseType: "blob",
        }
      );
      const filename = fileId;
      setCircularProgressHidden(true);
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Download Successful!",
      });
      return download(result.data, filename, "image/"+ext);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error Downloading!",
        });
      }
    }
  };


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleOnUploadResume = async (event) => {
    event.preventDefault();
    try {
        let file = userData.resume;
        let filname = file.name;
        let ext = filname.split('.').pop();
        if (ext=="pdf" || ext=="docx" || ext=="doc" || ext=="xlsx" || ext=="xls") {
          const title = filname;
          const description = filname;
          if (title.trim() !== "" && description.trim() !== "") {
            if (file) {
              const formData = new FormData();
              formData.append(
                "gigSeekerId",
                localStorage.getItem("_id") + ""
              );
              formData.append("file", file);
              formData.append("title", title);
              formData.append("description", description);
              await axios
                .post(`${backendUrl}/upload`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(() => {
                  setLoginData({
                    snackbarOpen: true,
                    snackbarMsg: "File Uploaded Successfull.",
                  });
                  history.push("/awardsDetails/" );
                });
            } else {
              setLoginData({
                snackbarOpen: true,
                snackbarMsg: "Please select a file to add.",
              });
            }
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please enter all the field values.",
          });
        }
        } else {
          userData.resume = ""
          userData.resumeId = ""
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please upload only valid format",
          });
        }
    } catch (error) {
      error.response &&
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "" + error.response.data,
        });
    }
  };

  const handleOnUploadImage = async (event) => {
    event.preventDefault();
    try {
        let file = userData.photo;
        let filname = file.name;
        let ext = filname.split('.').pop();
        if (ext=="png" || ext=="jpg" || ext=="jpeg") {
          const title = filname;
          const description = filname;
          if (title.trim() !== "" && description.trim() !== "") {
            if (file) {
              const formData = new FormData();
              formData.append(
                "gigSeekerId",
                localStorage.getItem("_id") + ""
              );
              formData.append("file", file);
              formData.append("title", title);
              formData.append("description", description);
              await axios
                .post(`${backendUrl}/upload`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(() => {
                  setLoginData({
                    snackbarOpen: true,
                    snackbarMsg: "File Uploaded Successfull.",
                  });
                  history.push("/awardsDetails/" );
                });
            } else {
              setLoginData({
                snackbarOpen: true,
                snackbarMsg: "Please select a file to add.",
              });
            }
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please enter all the field values.",
          });
        }
        } else {
          userData.photo = ""
          userData.photoId = ""
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please upload only valid format",
          });
        }
    } catch (error) {
      error.response &&
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "" + error.response.data,
        });
    }
  };

  const showImageBySeekerId = async (source, fileId, type) => {
    try {
      let ext = fileId.split('.').pop();
      axios.get(`${backendUrl}/getSeekerIdImage/` + source+"/"+fileId+"/"+type+"/"+ext,).then(
        (response) => {
          localStorage.setItem("userImage", response.data);
        })
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("photo not found")
      }
    }
  };

  const handlesubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    if (userData.resume != "") handleOnUploadResume(e);
    if (userData.photo != "") handleOnUploadImage(e);
    // user image save in localstorage
    userData.photo = "";
    userData.resume = "";
    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        showImageBySeekerId(
          userData._id,
          userData.photoId,
          "image"
        );
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Submision Successful!",
        });
        history.push("/qualificationsPage/");
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };
  useEffect(() => {
    loadUser();
  }, []);
  const formRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <div>
        <form
          ref={formRef}
          id="seekerForm"
          onSubmit={handlesubmit}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <MainHeader></MainHeader>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid>
                    <Grid sm={12}>
                      <div hidden={CircularProgressHidden}>
                        <CircularProgress hidden={CircularProgressHidden} />
                      </div>
                      <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={loginData.snackbarOpen}
                        autoHideDuration={5000}
                        message={loginData.snackbarMsg}
                        action={[
                          <IconButton
                            key="close"
                            color="inherit"
                            onClick={snackBarCloseEvent}
                          >
                            x
                          </IconButton>,
                        ]}
                        onClose={() => setLoginData({ snackbarOpen: false })}
                      />
                      <br />
                      <PageHeader
                        title="Upload"
                        subTitle="Upload your updated CV"
                        progressBarValue={20}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Grid item sm={12} name="upload_attachments">
                      <Paper>
                        <Grid container>
                          <Grid item sm={8}>
                            <p className="Upload-CV">Upload CV</p>
                            <Grid container>
                              <Grid item sm={10}>
                                <TextField
                                  className={classes.textFeild}
                                  label="Attach CV"
                                  variant="outlined"
                                  type="file"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  onChange={async (e) => {
                                    setUserData({
                                      ...userData,
                                      resume: e.target.files[0],
                                      resumeId: e.target.files[0].name,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item sm={1}></Grid>
                              <Grid item sm={1}></Grid>
                            </Grid>
                            <p className="Only-PDF-Doc-PPT-a">
                              Only PDF, Doc, PPT allowed
                            </p>
                            <Grid container>
                              <p className="Only-PDF-Doc-PPT-a"></p>

                              <Grid
                                item
                                sm={1}
                                hidden={!userData.resumeId.length > 0}
                              >
                                <Download
                                  onClick={() => {
                                    // downloadResume(
                                    //   userData.resume,
                                    //   userData.resumeId
                                    // );
                                    downloadResumeBySeekerId(
                                      localStorage._id + "",
                                      userData.resumeId,
                                      "application"
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                sm={1}
                                hidden={!userData.resumeId.length > 0}
                              >
                                <CancelIcon
                                  onClick={() => {
                                    setUserData({
                                      ...userData,
                                      resume: "",
                                      resumeId: "",
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item sm={5}>
                                <p style={{ margin: "0" }}>
                                  {userData.resumeId}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <br />
                      <Paper>
                        <Grid container>
                          <Grid item sm={8}>
                            <p className="Upload-CV">Image</p>
                            <Grid container>
                              <Grid item sm={10}>
                                <TextField
                                  className={classes.textFeild}
                                  label="Image"
                                  variant="outlined"
                                  type="file"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  onChange={async (e) =>
                                    setUserData({
                                      ...userData,
                                      photo: e.target.files[0],
                                      photoId: e.target.files[0].name,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item sm={1} />
                            </Grid>

                            <p className="Only-PDF-Doc-PPT-a">
                              Only JPG, PNG allowed
                            </p>
                            <Grid container>
                              <Grid item sm={1} />
                            
                              <Grid
                                item
                                sm={1}
                                hidden={!userData.photoId.length > 0}
                              >
                                <Download
                                  onClick={() => {
                                    downloadImageBySeekerId(
                                      localStorage._id + "",
                                      userData.photoId,
                                      "image"
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                sm={1}
                                hidden={!userData.photoId.length > 0}
                              >
                                <CancelIcon
                                  onClick={() => {
                                    setUserData({
                                      ...userData,
                                      photo: "",
                                      photoId: "",
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item sm={5}>
                                <p style={{ margin: "0" }}>
                                  {userData.photoId}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          {/* <Grid item sm={2}>
                            <div class="Join-for-FreeRectangle">
                              <Button>
                                <p className="Join-for-Free">Skip</p>
                              </Button>
                            </div>
                            <br />
                          </Grid> */}
                          <Grid item sm={6}></Grid>

                          <Grid item sm={12}>
                            <div>
                              <Controls.Button
                                text="Skip"
                                onClick={() => {
                                  history.push(
                                    "/qualificationsPage/"
                                  );
                                }}
                              />
                              {/* <Link to="/qualificationsPage"> */}

                              <Controls.Button
                                type="submit"
                                text="Save and Continue"
                                className={classes.submitBtn}
                                onClick={() => formRef.current.reportValidity()}
                              />
                              {/* </Link> */}
                            </div>
                            {/* <div class="Join-for-FreeRectangle">
                              <Button
                                type="submit"
                                onClick={() => formRef.current.reportValidity()}
                              >
                                <p className="Join-for-Free">
                                  Save and Continue
                                </p>
                              </Button>
                              <Button>
                                <Link
                                  to={"/gigseekerotherdetails"}
                                  style={{ textDecoration: "none" }}
                                  className="Join-for-Free"
                                >
                                  <p className="Join-for-Free">{"=>"}</p>
                                </Link>
                              </Button>
                            </div> */}
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </form>
      </div>
    </>
  );
}

export default Gigseekerupload;
