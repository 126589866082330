import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
  Typography,
  Input,
  Slider,
  FormControl,
} from "@material-ui/core";
import PageHeader from "../resusable/pageHeader";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import SeekerCard from "../job/seekerCard";
import JobModal from "../job/jobModal";
import FilterComponent from "../job/filterComponent";
import { backendUrl } from "../config";
import { useParams } from "react-router";
import axios from "axios";
import ExtraSeekerDetails from "../job/extraSeekerDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Controls from "../resusable/controls/Controls";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import JobCard from "../job/jobCard";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles({
  wrapper: {
    //opacity: "0.7",
    border: "solid 1px #dcdcdc",
    backgroundColor: "#fff",
    margin: "0 1rem",
  },
});

const gigtypeList = ["Part time", "Full time", "Seasonal", "Hourly"];

const skillsData = [ "ReactJs",
  "Python",
  "Java",
  "JavaScript",
  "C#",
  "C",
  "C++",
  "PHP",
  "R",
  "Objective-C",
  "Swift",
  "TypeScript",
  "MATLAB",
  "Kotlin",
  "Go(Golang)",
  "VBA",
  "Ruby",
  "Scala",
  "Visual Basic",
  "Rust",
  "Dart",
  "Ada",
  "Lua",
  "Abap",
  "Groovy",
  "Perl",
  "Cobol",
  "Julia	Haskell	Delphi",
  "Elm",
  "PowerShell",
  "SQL",
  "Clojure",
  "Elixir",
  "Pascal",
  "LISP",
  "Ballerina",
  "FORTRAN",
  "BASIC",
  "Alice",
  "COBOL",
  "Speakeasy",
  "Simula",
  "Smalltalk",
  "Prolog",
  "Erlang",
  "Ada",
  "Eiffel",
"Rebol",
  "Scratch",
  "Shell Scripting",
    "MySQL",
    "Magento",
    "CodeIgniter",
    "AngularJS",
    "Linux",
    "json",
    "RESTful WebServices",
    "Moodle",
    "WordPress",
    "Angular Material",
    "PostgreSQL",
    "Django",
    "mysql",
    "Jenkins",
    "docker",
    "Git",
    "MongoDB",
    "Zend Framework",
    "Gitlab",
    "API Development",
    "Web Services API",
    "Google Maps API",
    "Google API",
    "DevOps",
    "Zend",
    "X-Cart",
    "Web Services",
    "jQuery",
    "Pycharm",
    "Ionic Framework",
    "Cordova",
    "Apache Cordova",
    "Google Cloud Platform (GCP)",
    "Express.js",
    "React.js",
    "Flask",
    "Django",
    "Pandas",
    "Anaconda",
    "PySpark",
    "Jenkins",
    "Sonarqube",
    "ASP.NET",
    "HTML5",
    "HTML Scripting",
    "HTML",
    "XHTML",
    "Cascading Style Sheet (CSS)",
    "CSS Sprites",
    "CSS Flexbox",
    "Bootstrap",
];
const GigProviderSearchApplicantsByJobId = (props) => {
  let { jobId } = useParams();
  const [jobData, setJobData] = useState({ jobsArray: [] });
  const [usersData, setUsersData] = useState({ usersArray: [] });
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [gigType, setGigType] = useState("");
  const [education, setEducation] = useState([]);
  const [degree, setEducationDegree] = useState([]);
  const [skills, setSkills] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [experience, setExperience] = useState([0, 0]);

  const GotUsers = (response) => {
    setUsersData({ usersArray: response.data.users });
  };

  const GotJobDetails = (response) => {
    setJobData({ jobsArray: response.data.jobs });
  };

  const handleGigTypeChange = (e, value) => {
    setGigType(value);
  };

  const handleSliderChange = (event, newValue) => {
    setExperience(newValue);
  };

  const handleSliderInputChange = (event) => {
    setExperience(event.target.value);
  };

  const handleSliderBlur = () => {
    if (experience < 0) {
      setExperience(0);
    } else if (experience > 100) {
      setExperience(100);
    }
  };

  const getSliderValueText = (value) => {
    return `${value} years`;
  };

  const handleSubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    let data = {
      query: {
        education,
        degree,
        skills,
        companies,
        experience: {
          lesser: experience[1],
          greater: experience[0],
        },
        gigType,
      },
    };
    if (
      education.length === 0 &&
      degree.length === 0 &&
      skills.length === 0 &&
      companies.length === 0 &&
      //experience[0] === 0 &&
      //experience[1] === 0 &&
      (gigType === "" || gigType == null)
    )
      data = {};
    else if (experience[0] === 0 && experience[1] === 0)
      delete data.query.experience;
    axios.post(`${backendUrl}/jobseekers/findseekers`, data).then(
      (response) => {
        setCircularProgressHidden(true);
        if (response.data != undefined) GotUsers(response);
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log(error);
      }
    );
  };

  const getStringArray = (arr, elements) => {
    return arr.map(
      (ele) =>
        `${ele[elements[0]]} ( ${elements
          .slice(1)
          .map((i) => ele[i])
          .join(", ")} )`
    );
  };
  const getJob=(job)=>{

    if(job.gigSeeker!=undefined)
    {
         return job.gigSeeker;
    }
    else
    {
        return job;
    }
  }

  const getDisplayString = (elementArray, label) => {
    let res;
    if (elementArray.length > 2)
      res = `${label} : ${elementArray.slice(0, 2).join(", ")} +${
        elementArray.count - 2
      }....`;
    else if (elementArray.length > 0)
      res = `${label} : ${elementArray.join(", ")}`;
    else res = `${label} : -`;
    return res;
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const history = useHistory();
  const classes = useStyles();
  const loadUsers = () => {
    //alert(localStorage.getItem("jobID"))
    axios.get(`${backendUrl}/applicantstatusByJobId/`+localStorage.getItem("jobId")).then(
      (response) => {
        setCircularProgressHidden(true);

        if (response.data != undefined) GotUsers(response);
      },
      (error) => {
        console.log("error" + error);
      }
    );

axios.get(`${backendUrl}/jobs/`+localStorage.getItem("emailId")+"/"+localStorage.getItem("jobId")).then(
  (response) => {
    setCircularProgressHidden(true);

    if (response.data != undefined) GotJobDetails(response);
  },
  (error) => {
    console.log("error" + error);
  }
);
  };
  return (
    <>
<Grid>
  <Grid item xs={ 12} hidden={localStorage.getItem("profile")===null}>
      <MainHeader ></MainHeader>
</Grid>
<Grid item xs={ 12}>
<Grid item xs={ 12} hidden={!localStorage.getItem("profile")===null}><br/></Grid>
      <PaperDetails>
        <div style={{ marginLeft: 25 }}>
          <br />
          <Grid container xs={12}>
                <Grid item sm={8}>
                <PageHeader
            title="Gig Seeker Search by Job"
            subTitle="Search for the right Gig Seekers that fit to your project. Use filters for a quick search."
          />
                </Grid>
                <Grid item sm={3} />
                <Grid item sm={1}>
                  <Controls.Button
                    type="submit"
                    text="My jobs"
                    onClick={()=>{ history.push("/gigproviderjobposts")}}
                    className={classes.submitBtn}
                  />
                </Grid>
              </Grid>
        </div>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <Box className={classes.wrapper}>
              <List component="nav">
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    className={classes.jobHeading}
                  >
                    {"Filter By"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.jobHeading}
                  >
                    {/* Reset */}
                  </Typography>
                </ListItemButton>
              </List>
              <Divider />
              <form onSubmit={handleSubmit}>
                <Grid xs={11} style={{ marginLeft: 5 }}>
                  <br />
                  <br />
                  <Typography variant="body1">Gig Type</Typography> <br />
                  <Autocomplete
                    id="gigTypeComboBox"
                    options={gigtypeList}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Gig Type"
                        variant="outlined"
                        //required
                      />
                    )}
                    value={gigType}
                    onChange={handleGigTypeChange}
                  />
                  <br />
                  <Typography variant="body1">Primary Skills</Typography>{" "}
                  <br />
                  <Autocomplete
                    multiple
                    limitTags={1}
                    id="secondarySkills"
                    options={skillsData}
                    onChange={(e, values) => setSkills(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        //label="Primary Skills"
                        placeholder="Add Primary Skills"
                      />
                    )}
                  />
                  <Typography variant="body1">
                    <br />
                    Institute
                  </Typography>
                  <br />
                  <Autocomplete
                    multiple
                    freeSolo
                    id="Education"
                    options={[]}
                    onChange={(e, values) => setEducation(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        //label="School/College Name"
                        placeholder="Add Institute Name"
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13 && e.target.value) {
                        //     setEducation(education.concat(e.target.value));
                        //   }
                        // }}
                      />
                    )}
                  />
                  <br />
                  <Typography variant="body1">Degree</Typography> <br />
                  <Autocomplete
                    multiple
                    freeSolo
                    id="Education_degree"
                    options={[]}
                    onChange={(e, values) => setEducationDegree(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        //label="Degree"
                        placeholder="Add Degree"
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13 && e.target.value) {
                        //     setEducationDegree(degree.concat(e.target.value));
                        //   }
                        // }}
                      />
                    )}
                  />
                  <br />
                  <Typography variant="body1">Companies</Typography> <br />
                  <Autocomplete
                    multiple
                    freeSolo
                    id="Companies"
                    options={[]}
                    onChange={(e, values) => setCompanies(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        //label="Companies"
                        placeholder="Add Companies"
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13 && e.target.value) {
                        //     setCompanies(companies.concat(e.target.value));
                        //   }
                        // }}
                      />
                    )}
                  />
                  <div style={{ textAlign: "center" }}>
                    <FormControl className={classes.button}>
                      <Grid item style={{ textAlign: "center" }}>
                        <Controls.Button
                          className={classes.submitBtn}
                          type="submit"
                          text="Filter"
                        />
                      </Grid>
                    </FormControl>
                  </div>
                </Grid>
              </form>
            </Box>

            {/* <FilterComponent></FilterComponent> */}
          </Grid>
          <Grid item xs={9}>
            <div>
              <div hidden={CircularProgressHidden}>
                <CircularProgress hidden={CircularProgressHidden} />
              </div>
              <><Typography variant="body1">Job Details</Typography></>   <br/>
              {jobData.jobsArray.length > 0 ?jobData.jobsArray.map((job) => (
                <JobCard {...job}/> )):(<><Typography variant="body1">No Job Available.</Typography></>)}
                <br/>
              <><Typography variant="body1">Applicants</Typography></>   <br/>
              {usersData.usersArray.length > 0 ?usersData.usersArray.map((job) => (
                <>
                  <SeekerCard {...getJob(job)} />
                  <ExtraSeekerDetails
                    gigSeekerId={getJob(job)._id}
                    gigProviderId={localStorage.getItem("_id")}
                    gigJobId={localStorage.getItem("jobId")}//jobid
                    from="provider"
                  />
                </>
              )):( <>   <Typography variant="body1">No Seekers Available.</Typography></>)}

            </div>
          </Grid>
        </Grid>
      </PaperDetails>
      </Grid>
      <Grid item xs={12} hidden={localStorage.getItem("profile")===null}>
      <Footer></Footer>
      </Grid>
      </Grid>
    </>
  );
};

export default GigProviderSearchApplicantsByJobId;
