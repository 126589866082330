import React, { useState, useEffect } from "react";
import { Grid,Button ,Paper ,makeStyles, TextField , IconButton} from '@material-ui/core';
import PageHeader from "../resusable/pageHeader";
import { styled } from '@mui/material/styles';
import MuiPaper from '@mui/material/Paper';
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import JobCard from "../job/jobCard";
import JobModal from "../job/jobModal";
import FilterComponent from "../job/filterComponent";
import { backendUrl } from "../config";
import { useParams } from "react-router";
import axios from "axios";
import MenuItems from "../resusable/MenuItems";


import ListIcon from '@mui/icons-material/List';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TelegramIcon from '@mui/icons-material/Telegram';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


const PaperDetails = styled(MuiPaper)(({ theme }) => ({
    width: '90%',
    margin: '0 auto 2em auto',
    paddingBottom: '1em',
    backgroundColor: '#f6f6f6',
    boxShadow : 'none'
}));
const JobListing=(props)=>{

    let { gigSeekerId } = useParams();
    const GotUsers = (response) => {
        setUsersData({ usersArray: response.data.users });
      }; 
    const [usersData, setUsersData] = useState({ usersArray: [] });
      useEffect(() => {
        loadUsers();
      }, []);
    const loadUsers = () => {
        axios.get(`${backendUrl}/jobproviders`).then(
          (response) => {

            //setCircularProgressHidden(true);
            if(gigSeekerId===undefined||gigSeekerId==="")
            gigSeekerId=props.gigSeekerId;
            if (response.data != undefined) GotUsers(response);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      };
    return(
        <>
            <MainHeader></MainHeader>
            <PaperDetails>
                <PageHeader title="Gigs"
                 subTitle=""/>
                <Grid container xs={12}>
                    <Grid item xs={3}>
                        <FilterComponent></FilterComponent>
                    </Grid>
                    <Grid item xs={9}>
                      <div>
                        {usersData.usersArray.map(job => <JobCard {...job}/>)}
                       {/* <JobCard></JobCard>  */}
                    </div>
                    </Grid>
                </Grid>
            </PaperDetails>
            <ListIcon/><MenuItems name = "Applied"></MenuItems>
            <PlaylistAddCheckIcon/><MenuItems name = "My Hires"></MenuItems>
            <HighlightOffIcon/><MenuItems name = "End Contract"></MenuItems>
            <TelegramIcon/><MenuItems name = "Send a Message"></MenuItems>
            <ReportGmailerrorredIcon/><MenuItems name = "Report to Admin"></MenuItems>
            <Footer></Footer>
        </>
    )
}

export default JobListing