import React , { useState } from "react";
import {Box , Grid, FilledInput ,makeStyles, Select , Dialog , MenuItem , DialogTitle , 
    DialogContent ,TextField ,Button, Typography, DialogActions} from '@material-ui/core';
import Controls from "../resusable/controls/Controls";
const useStyles = makeStyles(theme => ({
    submitBtn: {
        float: 'right'
    },
    uploadInput:{ 
        border: '1px solid rgba(0, 0, 0, 0.23)',
        marginTop: '15px !important',
        borderRadius: '4px',
        '&.input': {
            padding:'18.5px 14px'
        },

    },
}))

export default function JobModal(props){
    const classes = useStyles();
    const { title ,caption } = props;
    return(
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant='h4'>{title}</Typography>
                <Typography variant="caption">{caption}</Typography>
            </DialogTitle>
            <DialogContent hideContent={false}>
                <Grid container>
                    
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Your Summary</Typography>
                        <FilledInput placeholder="Describe details here" disableUnderline fullWidth multiline rows={4}/>
                    </Grid>
                    
                    <Grid item xs={6} >
                        <Typography variant="subtitle1">Upload CV</Typography>
                        <TextField className={classes.uploadInput} label="Attach Certificate" InputProps={{
                            endAdornment: (
                                <Button variant="contained" component="label">
                                    Upload
                                    <input type="file" hidden />
                                </Button>)
                        }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Controls.Button type="submit"  text="Apply" className={classes.submitBtn} />
                <Controls.Button text="Skip" />
            </DialogActions>
        </Dialog>
    )
}