import React, { useState, useEffect } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  Paper,
  Button,
  ThemeProvider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MuiPhoneNumber from "material-ui-phone-number";
import { backendUrl } from "../config";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
import Footer from "../resusable/footer";
import Controls from "../resusable/controls/Controls";
import MainHeader from "../resusable/mainHeader";
import { useHistory } from "react-router-dom";
import PageHeader from "../resusable/pageHeader";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
//import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  textFeild: {
    margin: "10px !important",
  },
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));
function Gigseekerotherdetails() {
  const history = useHistory();
  const location = useLocation();
  let email = localStorage.getItem("emailId");//location.pathname.substring(23, location.pathname.length);
  //let  {email}  = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };

  const skills = [
    "ReactJs ",
    "Python",
    "Java",
    "JavaScript",
    "C#",
    "C",
    "C++",
    "PHP",
    "R",
    "Objective-C",
    "Swift",
    "TypeScript",
    "MATLAB",
    "Kotlin",
    "Go(Golang)",
    "VBA",
    "Ruby",
    "Scala",
    "Visual Basic",
    "Rust",
    "Dart",
    "Ada",
    "Lua",
    "Abap",
    "Groovy",
    "Perl",
    "Cobol",
    "Julia	Haskell	Delphi",
    "Elm",
    "PowerShell",
    "SQL",
    "Clojure",
    "Elixir",
    "Pascal",
    "LISP",
    "Ballerina",
    "FORTRAN",
    "BASIC",
    "Alice",
    "COBOL",
    "Speakeasy",
    "Simula",
    "Smalltalk",
    "Prolog",
    "Erlang",
    "Ada",
    "Eiffel",
    "Rebol",
    "Scratch",
    "Shell Scripting",
    "MySQL",
    "Magento",
    "CodeIgniter",
    "AngularJS",
    "Linux",
    "json",
    "RESTful WebServices",
    "Moodle",
    "WordPress",
    "Angular Material",
    "PostgreSQL",
    "Django",
    "mysql",
    "Jenkins",
    "docker",
    "Git",
    "MongoDB",
    "Zend Framework",
    "Gitlab",
    "API Development",
    "Web Services API",
    "Google Maps API",
    "Google API",
    "DevOps",
    "Zend",
    "X-Cart",
    "Web Services",
    "jQuery",
    "Pycharm",
    "Ionic Framework",
    "Cordova",
    "Apache Cordova",
    "Google Cloud Platform (GCP)",
    "Express.js",
    "React.js",
    "Flask",
    "Django",
    "Pandas",
    "Anaconda",
    "PySpark",
    "Jenkins",
    "Sonarqube",
    "ASP.NET",
    "HTML5",
    "HTML Scripting",
    "HTML",
    "XHTML",
    "Cascading Style Sheet (CSS)",
    "CSS Sprites",
    "CSS Flexbox",
    "Bootstrap",
  ];
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
emailVerified:"",
otpVerified:"",
  });

  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitOpen = () => {
    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const loadUser = () => {
    //let emailId ={email} ; //props.emailid;
    // if(!isCurrentURL("/gigSeekerLogin"))
    // emailId=localStorage.getItem('emailId');
    axios
      .get(
        `${backendUrl}/jobseekers/` + email
        //,{headers: {"AccessToken": localStorage.getItem("token")}}
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (
            response.data.user != undefined &&
            response.data.user[0] != undefined
          ) {
            setUserData(response.data.user[0]);
            //setgigSeekerId(response.data.user[0]._id);
            setLoginData({
              snackbarOpen: true,
              snackbarMsg: "Info: Profile load Successful",
            });
          } else {
          }
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setCircularProgressHidden(false);
    if (true) {
      axios.post(`${backendUrl}/jobseekers`, userData).then(
        (response) => {
          setCircularProgressHidden(true);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Submision Successful!",
          });

          axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
            (response) => {
              if (response.data.user[0] != undefined)
                setUserData(response.data.user[0]);
            },
            (error) => {
              console.log("error" + error);
            }
          );
          setSubmitOpen(true);
          //history.push('/seekersearchjobListing');
        },
        (error) => {
          setCircularProgressHidden(true);
          console.log("error" + error);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Error submitting data please try again.",
          });
        }
      );
    }
  };
  useEffect(() => {
    loadUser();
  }, []);
  const formRef = React.useRef();
  const classes = useStyles();
  return (
    <>
      <div>
        <form
          ref={formRef}
          id="seekerForm"
          onSubmit={handlesubmit}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <MainHeader></MainHeader>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid>
                    <Grid sm={12}>
                      <div hidden={CircularProgressHidden}>
                        <CircularProgress hidden={CircularProgressHidden} />
                      </div>
                      <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={loginData.snackbarOpen}
                        autoHideDuration={5000}
                        message={loginData.snackbarMsg}
                        action={[
                          <IconButton
                            key="close"
                            color="inherit"
                            onClick={snackBarCloseEvent}
                          >
                            x
                          </IconButton>,
                        ]}
                        onClose={() => setLoginData({ snackbarOpen: false })}
                      />
                      <br />
                      <PageHeader
                        title="Other Details"
                        subTitle="Help recruiters know more about you and work timings"
                        progressBarValue={90}
                      />
                    </Grid>
                  </Grid>
                  <Dialog
                    onClose={handleSubmitClose}
                    open={SubmitOpen}
                    style={{ border: "1px solid", borderColor: "black" }}
                  >
                    <DialogTitle
                      id="simple-dialog-title"
                      style={{ background: "white" }}
                    >
                      <Grid item xs={12}>
                        <Grid container name="Submit">
                          <Grid item xs={12}>
                            <Typography variant="h4" className={classes.pageTitle} align="center">
                              <ThumbUpAltIcon></ThumbUpAltIcon>
                              <br />
                              Awesome!
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={1}>
                            <IconButton
                              aria-label="close"
                              onClick={handleSubmitClose}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <Paper style={{ background: "white" }}>
                      <Grid container name="Submit" className="GridMarginStyle">
                        <Grid item xs={2} />
                        <Grid item xs={8} >
                          <Typography align="center">
                          
                            You have successfully saved your details. Happy Job
                            Search.
                            <br />
                            <br />
                            <br />
                          </Typography>
                          <Grid item xs={12}>
                          <Controls.Button text="Find Roles" onClick={()=>{history.push('/seekersearchjobListing')}} />
                          <Controls.Button
                            type="submit"
                            text="Cancel"
                            onClick={handleSubmitClose}
                            //style={{ marginLeft: 50}}
                            className={classes.submitBtn}
                          />
                        </Grid>
                        </Grid>
                        
                        <Grid item xs={2} />
                        <br />
                      </Grid>
                      <br />
                    </Paper>
                  </Dialog>

                  <Grid item sm={12}>
                    <Grid item sm={12} name="upload_attachments">
                      <Paper>
                        <Grid container>
                          <Grid item sm={11}>
                            <p className="Upload-CV">
                              Add Primary Skills
                            </p>
                            <Autocomplete
                              multiple
                              id="secondarySkills"
                              options={skills}
                              value={userData.secondarySkills}
                              onChange={(e, values) =>
                                setUserData({
                                  ...userData,
                                  secondarySkills: values,
                                })
                              }
                              renderInput={(params) => (
                                <TextField
                                  className={classes.textFeild}
                                  fullWidth
                                  {...params}
                                  variant="outlined"
                                  placeholder="Primary Skills"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <br />
                      <Paper>
                        <Grid container>
                          <Grid item sm={8}>
                            <p className="Upload-CV">Gig Type Preffered</p>
                            <div style={{ marginLeft: 20 }}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  defaultValue="Hourly"
                                  value={userData.gigType}
                                  name="otherdetailsradiogroup"
                                  onChange={(e) => {
                                    setUserData({
                                      ...userData,
                                      gigType: "" + e.target.value,
                                    });
                                  }}
                                >
                                  <FormControlLabel
                                    value="Part time"
                                    control={<Radio />}
                                    label="Part time"
                                  ></FormControlLabel>
                                  <FormControlLabel
                                    value="Full time"
                                    control={<Radio />}
                                    label="Full time"
                                  />
                                  <FormControlLabel
                                    value="Hourly"
                                    control={<Radio />}
                                    label="Hourly"
                                  />
                                  <FormControlLabel
                                    value="Seasonal"
                                    control={<Radio />}
                                    label="Seasonal"
                                  />
                                </RadioGroup>
                                <br />
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>

                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          {/* <Grid item sm={2}>
                            <div class="Join-for-FreeRectangle">
                              <Button>
                                <p className="Join-for-Free">Skip</p>
                              </Button>
                            </div>
                            <br />
                          </Grid> */}
                          <Grid item sm={6}></Grid>

                          <Grid item sm={12}>
                            <div>
                              <Controls.Button disabled="true" text="Skip" />

                              <Controls.Button
                                type="submit"
                                text="Save"
                                className={classes.submitBtn}
                                onClick={() => formRef.current.reportValidity()}
                              />
                            </div>
                            {/* <div class="Join-for-FreeRectangle">
                            <Link to = "/qualificationsPage">
                              
                              <Button
                                type="submit"
                                onClick={() => formRef.current.reportValidity()}
                              >
                                <p className="Join-for-Free">
                                  Save and Continue
                                </p>
                              </Button>
                          
                            </Link>
                            </div> */}
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </form>
      </div>
    </>
  );
}

export default Gigseekerotherdetails;
