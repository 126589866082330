import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect} from 'react';
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
  pageTitleLeft: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "left",
  },
}));
function PasswordRule() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="PASSWORD POLICY"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    Password Policy :
                  </p>
                  <br />
                  1. The password string must be <b> 8 characters </b> or longer.
                  <br /> 2. The string must contain atleast<b> 1 lowercase </b>
                  alphabetical character.
                  <br /> 3. The string must contain atleast <b> 1 uppercase </b>
                  alphabetical character. <br />
                  4. The string must contain atleast <b>1 numeric character </b>.
                  <br />
                  5. The string must contain atleast <b>1 special character </b>.
                  <br />
                  6. Exmaple <b>Gig@1234</b>
                  <br />
                </p>
              </Typography>
              <br />
            </Paper>
            <br />
          </Grid>
          <Grid container>
            <Grid item sm={5} />

            <div>
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Controls.Button text="Join Us" />
              </Link>
            </div>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default PasswordRule;
