import React, { useState } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  TextField,
  Paper,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";
import Snackbar from "@material-ui/core/Snackbar";
import { backendUrl } from "../config";
import Footer from "../resusable/footer";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signInWithEmailPswd, resetPswdLink ,signInWithGoogle,signInWithFacebook} from "../firebaseConfig";

import { FacebookLoginButton } from "react-social-login-buttons";
import {GoogleLoginButton} from "react-social-login-buttons"
import SendIcon from "@mui/icons-material/ErrorOutline";
import { RecaptchaVerifier } from "firebase/auth";
import firebase from "firebase/compat/app";

const useStyles = makeStyles((theme) => ({
  textFeild: {
    margin: "12px !important",
  },
}));

function CommonLoginWithPhoneNumer() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "+91 ",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    emailVerified:"",
    otpVerified:"",
    otp: "",
  });
  const [postOfferData, setPostOfferData] = useState({
    _id: "",
    bankName: "",
    gigTitle: "",
    email: "",
    password: "",
    company: "",
    gigType: "",
    description: "",
    skillsRequired: [],
    startDate: "",
    endDate: "",
    hourlyRate: "",
    rateOnSkills: "",
    assesment: "",
  });
  const [loginData, setLoginData] = useState({
    emailId: "",
    password: "",
    newPassword: "",
    otp: "",
    isvalid: "",
    snackbarOpen: false,
    snackbarMsg: "",
    id: "",
  });

  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const [userType, setUserType] = useState("seeker");
  const [showOtp, setShowOtp] = useState(true);
  const handleClickShowOtp = () => setShowOtp(!showOtp);
  const [CircularProgressHidden, setCircularProgressHidden] = useState(true);

  const signUpWithGoogleClicked=(e)=>{

    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Info: You are signing up as "+userType+" please recheck and proceed.",
    });
    signInWithGoogle().then((response)=>{
      if(response.success)
      {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: response.msg,
        });
        loginData.emailId=response.userEmail;
    if (
      loginData.emailId === "admin@gmail.com" 
    ) {
      localStorage.setItem("profile", "admin");
      history.push("/admin");
    } else {
      if (userType === "seeker") {
        axios
          .post(`${backendUrl}/jobseekers/findseekerById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              //parseResponse(response);
              if (response.data.user.length != 0) {
                localStorage.setItem("token", response.data.JWTtoken);
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "seeker");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  id: response.data.user[0]._id,
                  password: "",
                  isvalid: "true",
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/seekersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password or role",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      } else {
        postOfferData.email = userData.email;
        postOfferData.password = userData.password;
        axios
          .post(`${backendUrl}/jobproviders/findproviderById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              localStorage.setItem("emailId", loginData.emailId);
              //alert(localStorage.getItem("emailId"))
              if (response.data.user.length != 0) {
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "provider");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  password: "",
                  isvalid: "true",
                  id: response.data.user[0]._id,
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/providersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                newPassword: "",
                otp: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      }
    }} else{
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Failed, please try again!"+response.msg,
      });
    }
   }).catch((err)=>{
    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Failed, please try again!"+err.msg,
    });
   })

  }
  const onSignInSubmit = (e) => {
    e.preventDefault();
    setUpRecaptcha();
    let phoneNumber = userData.phoneNo;
    let appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // console.log(confirmationResult);
      
      })
      .catch(function (error) {
        console.log(error);
       
        setLoginData({
          snackbarOpen:true,
          snackbarMsg: error+" please refreresh your page and try again.",
        });
      });
  };
  const loginWithEmail=(_email)=>{
    setLoginData({...loginData,emailId:_email})
    if (userType === "seeker") {
      axios
        .post(`${backendUrl}/jobseekers/findseekerById`, loginData)
        .then(
          (response) => {
            setCircularProgressHidden(true);
            //parseResponse(response);
            if (response.data.user.length != 0) {
              localStorage.setItem("token", response.data.JWTtoken);
              localStorage.setItem("emailId", loginData.emailId);
              localStorage.setItem("_id", response.data.user[0]._id);
              localStorage.setItem("profile", "seeker");
              localStorage.setItem(
                "name",
                response.data.user[0].firstName
              );
              //alert( localStorage.getItem("_id"))
              setLoginData({
                emailId: loginData.emailId,
                id: response.data.user[0]._id,
                password: "",
                isvalid: "true",
                snackbarOpen: true,
                snackbarMsg: "Login Successful!",
              });
              if(response.data.user[0].phoneNo!="")
              history.push("/seekersearchjobListing");
              else
              history.push("/gigseekersignup/")
            } else {
              setLoginData({
                emailId: "",
                password: "",
                snackbarOpen: true,
                snackbarMsg: "Error : Invalid User Id or password",
              });
            }
          },
          (error) => {
            setCircularProgressHidden(true);
            console.log("error" + error);
            setLoginData({
              emailId: "",
              password: "",
              isvalid: "false",
              snackbarOpen: true,
              snackbarMsg:
                "Error : Failed at server, pls check internet and try again",
            });
          }
        );
    } else {
      setLoginData({...loginData,emailId:_email})
      postOfferData.email = _email;
      postOfferData.password = userData.password;
      axios
        .post(`${backendUrl}/jobproviders/findproviderById`, loginData)
        .then(
          (response) => {
            setCircularProgressHidden(true);
            localStorage.setItem("emailId", loginData.emailId);
            //alert(localStorage.getItem("emailId"))
            if (response.data.user.length != 0) {
              localStorage.setItem("emailId", loginData.emailId);
              localStorage.setItem("_id", response.data.user[0]._id);
              localStorage.setItem("profile", "provider");
              localStorage.setItem(
                "name",
                response.data.user[0].firstName
              );
              //alert( localStorage.getItem("_id"))
              setLoginData({
                emailId: loginData.emailId,
                password: "",
                isvalid: "true",
                id: response.data.user[0]._id,
                snackbarOpen: true,
                snackbarMsg: "Login Successful!",
              });     
              history.push("/gigproviderjobposts/")
            } else {
              setLoginData({
                emailId: "",
                password: "",
                snackbarOpen: true,
                snackbarMsg: "Error : Invalid User Id or password",
              });
            }
          },
          (error) => {
            setCircularProgressHidden(true);
            console.log("error" + error);
            setLoginData({
              emailId: "",
              password: "",
              newPassword: "",
              otp: "",
              isvalid: "false",
              snackbarOpen: true,
              snackbarMsg:
                "Error : Failed at server, pls check internet and try again",
            });
          }
        );
    }
  }
  const onSubmitOtp = (e) => {
    e.preventDefault();
    let otpInput = loginData.otp;
    let optConfirm = window.confirmationResult;
   console.log(otpInput);
    if (optConfirm != undefined)
      optConfirm
        .confirm(otpInput)
        .then(function (result) {
          // User signed in successfully.
          // console.log("Result" + result.verificationID);
          let user = result.user;
          loginData.emailId=user.email;
          userData.otpVerified = "true";
          setLoginData({
            snackbarOpen:true,
            snackbarMsg: "Valid Otp Entered. Please wait while we gather your details.",
          });
          setLoginData({...loginData,emailId:user.email})

          loginWithEmail(user.email);



        })
        .catch(function (error) {
          //console.log(error);
          setLoginData({
            snackbarOpen:true,
            snackbarMsg: error+" please refreresh your page and try again.",
          });
         
          userData.otpVerified = "false";
         
        });
    else alert("Invalid steps.Please refresh page and try again.");
  };
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          console.log("Captcha Resolved");
          onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  };

  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <div style={{ textAlign: "center" }}>
       
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item sm={2}>
                  <a href="/">
                    <img className="Logo" src={LogoImage} />
                    </a>
                  </Grid>
                  <Grid item sm={7}></Grid>
                </Grid>
              </Paper>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid item sm={12}>
                    <div hidden={CircularProgressHidden}>
                      <CircularProgress hidden={CircularProgressHidden} />
                    </div>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={10000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
                    <p className="Sign-up-faster-with">Sign In faster with</p>
                   

                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item sm={4} />
                        <Grid item sm={4}>
                          <GoogleLoginButton
                            onClick={(e) => {
                              signUpWithGoogleClicked(e);
                            }}
                          />
                        </Grid>
                        <Grid item sm={2} />
                      </Grid>
                    </Grid> 
                    <br />
                  </Grid>
                  <Grid item sm={12}>
                    <Paper style={{ border: "solid 1px #dcdcdc" }}>
                      <Grid item sm={12}>
                        <p className="Join-a-Global-networ">
                          Or Sign In by Mobile with OTP
                        </p>
                        <Grid item sm={12}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              defaultValue="seeker"
                              name="row-radio-buttons-group"
                              onChange={(e, value) => {
                                setUserType("" + value);
                              }}
                            >
                              <FormControlLabel
                                value="seeker"
                                control={<Radio />}
                                label="Iam a Seeker"
                              ></FormControlLabel>
                              <FormControlLabel
                                value="provider"
                                control={<Radio />}
                                label="Iam a Provider"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Grid>
                          <Grid item sm={12}>
                            <TextField
                              className={classes.textFeild}
                              placeholder="Phone Number"
                              label="Phone Number"
                              variant="outlined"
                              required
                              type="phone"
                              value={userData.phoneNo}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  phoneNo: e.target.value,
                                })
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                      <PhoneAndroidIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <div hidden={showOtp}>
                            <TextField
                              className={classes.textFeild}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="OTP"
                              required
                              label="OTP"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                        <SendIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              value={loginData.otp}
                              onChange={(e) =>
                              {
                                setLoginData({
                                  ...loginData,
                                  otp: e.target.value,
                                })
                              }
                              }
                            />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <div id="recaptcha-container"></div>
                      <Grid item sm={12}></Grid>
                      <br />
                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} />
                          <Grid item sm={4}>
                            <div class="Join-for-FreeRectangle"   hidden={!showOtp}>
                              <Button
                                type="submit"
                                onClick={(e) => {
                                    onSignInSubmit(e);
                                    handleClickShowOtp()
                                    setLoginData({
                                      snackbarOpen: true,
                                      snackbarMsg:
                                        "Phone Number Otp is sent. Please enter otp and submit.",
                                    });
                                }}
                              >
                                <p className="Join-for-Free">{"Send otp"}</p>
                                 {/* </Link> */}
                              </Button>
                              </div>
                              <div class="Join-for-FreeRectangle" hidden={showOtp}>
                              <Button
                                type="submit"
                                hidden={showOtp}
                                onClick={(e) => {
                                  onSubmitOtp(e);
                                  setLoginData({
                                    snackbarOpen: true,
                                    snackbarMsg:
                                      "Phone Number Otp is being Verified. Please wait!",
                                  });
                                }}
                              >
                                <p  className="Join-for-Free">{"Submit & Sign In"}</p>
                                {/* </Link> */}
                              </Button>
                          
                            </div>
                            <br />
                            <>
                              <Link
                                to={"/signUp"}
                                style={{ textDecoration: "none",color:"brown" }}
                              >
                                <b class="text-style-1"> {"New here?"}</b>
                              </Link>

                              <Link
                                to={"/login"}
                                style={{ textDecoration: "none",color:"#f3670d" }}
                              >
                                <b class="text-style-1"> {"Login with e-mail?"}</b>
                              </Link>
                            
                            </>
                          </Grid>
                          <Grid item sm={4}></Grid>
                        
                        </Grid>
                      </Grid>
                      <br/>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item sm={3} />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
      </div>
    </>
  );
}

export default CommonLoginWithPhoneNumer;
