import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { styled } from '@mui/material/styles';
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
const createData = (id, date, time, paymentId, paymentStatus) => ({
  id,
  date,
  time,
  paymentId,
  paymentStatus,
 // protein,
  isEditMode: false
});

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

export default function TimeSheet(props) {

  let rowData=props.rowData;

  let minDate = new Date('1/15/2017');
  let maxDate = new Date('12/20/2017');
  const [dateval, onSelectionChange] = React.useState([new Date(), new Date()]);
    //   createData("Reques1ted",  <DateRangePicker
  //   onChange={onSelectionChange}
  //   value={dateval}
  // />, 6.0, 24, 4.0),
  //   createData("Reque3sted",  <DateRangePicker
  //   onChange={onSelectionChange}
  //   value={dateval}
  // />, 9.0, 37, 4.3),
  //   createData("Reque2sted",  <DateRangePicker
  //   onChange={onSelectionChange}
  //   value={dateval}
  // />, 16.0, 24, 6.0)
  const [rows, setRows] = React.useState([]); 
  const [previous, setPrevious] = React.useState({});
  const classes = useStyles();

  const onToggleEditMode = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        <caption>A barbone structure table example with a caption {"---1-"+rowData.length}</caption>
        <TableHead>
          <TableRow>
            <TableCell align="left">Date&nbsp;&nbsp; </TableCell>
            <TableCell align="left">Working Hours</TableCell>
            <TableCell align="left">Payment Id&nbsp;</TableCell>
            <TableCell align="left">Payment Status</TableCell>
            <TableCell align="left"> Actions </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map(row => (
            <StyledTableRow key={row.id}>
              <CustomTableCell {...{ row, name: "date", onChange }} />
              <CustomTableCell {...{ row, name: "time", onChange }} />
              <CustomTableCell {...{ row, name: "paymentId", onChange }} />
              <CustomTableCell {...{ row, name: "paymentStatus", onChange }} />
              <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => onToggleEditMode(row.id)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="delete"
                    onClick={() => onToggleEditMode(row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            {/* </TableRow> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}