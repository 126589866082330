/*use the localhost url wrking on dev changes "http://localhost:3001" ; */

//this is a backend test server site url
//"https://gigjobstest.herokuapp.com";

//this is a deploy test server site url
//"https://gig-jobs-server.herokuapp.com";

//needed env config
//REACT_APP_ENVIRONMENT_TEST on netlify and .env on root  in local


//backend url
export const _backendUrl = process.env.REACT_APP_ENVIRONMENT_TEST
  ? "https://gigjobstest.herokuapp.com"
  : "https://jobsearch-production-9104.up.railway.app"
//"https://gig-jobs-server.herokuapp.com";



//firebase auth
export const _firebaseConfig = process.env.REACT_APP_ENVIRONMENT_TEST
  ? {
      apiKey: "AIzaSyD97ZCFZLIedaXvjvWZHLdS13GDu8GSV6Q",
      authDomain: "gigjobs-99641.firebaseapp.com",
      projectId: "gigjobs-99641",
      storageBucket: "gigjobs-99641.appspot.com",
      messagingSenderId: "736160189688",
      appId: "1:736160189688:web:5aa7ed796b324bb4057f0d",
    }
  : {
      apiKey: "AIzaSyBTm0FxwNvB_6VRJPUS2F7zZNkIIGhfGvE",
      authDomain: "gig-jobs-87e5c.firebaseapp.com",
      projectId: "gig-jobs-87e5c",
      storageBucket: "gig-jobs-87e5c.appspot.com",
      messagingSenderId: "562432324567",
      appId: "1:562432324567:web:e2d1be0c90825da5da1192",
    };

//unused
//Razzorpay Auth
export const _auth = {
  username: "rzp_live_Zu4w81dHHyb2n9",
  password: "EuaR9hlaIPDuiXBwI6V0Q6TA",
};





//rajendra's firebase- for deploy site
// {
//   apiKey: "AIzaSyBTm0FxwNvB_6VRJPUS2F7zZNkIIGhfGvE",
//   authDomain: "gig-jobs-87e5c.firebaseapp.com",
//   projectId: "gig-jobs-87e5c",
//   storageBucket: "gig-jobs-87e5c.appspot.com",
//   messagingSenderId: "562432324567",
//   appId: "1:562432324567:web:e2d1be0c90825da5da1192"
// };

//ram's firebase- for test site
// const config = {
//   apiKey: "AIzaSyD97ZCFZLIedaXvjvWZHLdS13GDu8GSV6Q",
//   authDomain: "gigjobs-99641.firebaseapp.com",
//   projectId: "gigjobs-99641",
//   storageBucket: "gigjobs-99641.appspot.com",
//   messagingSenderId: "736160189688",
//   appId: "1:736160189688:web:5aa7ed796b324bb4057f0d",
// };
