import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PageHeader from "../resusable/pageHeader";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import JobCard from "../job/jobCard";
import Divider from "@mui/material/Divider";
import JobModal from "../job/jobModal";
import FilterComponent from "../job/filterComponent";
import { backendUrl } from "../config";
import { useParams } from "react-router";
import axios from "axios";
import ExtraSeekerDetails from "../job/extraSeekerDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Controls from "../resusable/controls/Controls";
import { useHistory } from "react-router-dom";
import { Accordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles({
  wrapper: {
    border: "solid 1px #dcdcdc",
    backgroundColor: "#fff",
    margin: "0 1rem",
  },
});
const gigtypeList = ["Part time", "Full time", "Seasonal", "Hourly"];

const skillsData = [ "ReactJs",
  "Python",
  "Java",
  "JavaScript",
  "C#",
  "C",
  "C++",
  "PHP",
  "R",
  "Objective-C",
  "Swift",
  "TypeScript",
  "MATLAB",
  "Kotlin",
  "Go(Golang)",
  "VBA",
  "Ruby",
  "Scala",
  "Visual Basic",
  "Rust",
  "Dart",
  "Ada",
  "Lua",
  "Abap",
  "Groovy",
  "Perl",
  "Cobol",
  "Julia	Haskell	Delphi",
  "Elm",
  "PowerShell",
  "SQL",
  "Clojure",
  "Elixir",
  "Pascal",
  "LISP",
  "Ballerina",
  "FORTRAN",
  "BASIC",
  "Alice",
  "COBOL",
  "Speakeasy",
  "Simula",
  "Smalltalk",
  "Prolog",
  "Erlang",
  "Ada",
  "Eiffel",
"Rebol",
  "Scratch",
  "Shell Scripting",
    "MySQL",
    "Magento",
    "CodeIgniter",
    "AngularJS",
    "Linux",
    "json",
    "RESTful WebServices",
    "Moodle",
    "WordPress",
    "Angular Material",
    "PostgreSQL",
    "Django",
    "mysql",
    "Jenkins",
    "docker",
    "Git",
    "MongoDB",
    "Zend Framework",
    "Gitlab",
    "API Development",
    "Web Services API",
    "Google Maps API",
    "Google API",
    "DevOps",
    "Zend",
    "X-Cart",
    "Web Services",
    "jQuery",
    "Pycharm",
    "Ionic Framework",
    "Cordova",
    "Apache Cordova",
    "Google Cloud Platform (GCP)",
    "Express.js",
    "React.js",
    "Flask",
    "Django",
    "Pandas",
    "Anaconda",
    "PySpark",
    "Jenkins",
    "Sonarqube",
    "ASP.NET",
    "HTML5",
    "HTML Scripting",
    "HTML",
    "XHTML",
    "Cascading Style Sheet (CSS)",
    "CSS Sprites",
    "CSS Flexbox",
    "Bootstrap",
];
const GigSeekerSearchApplicationsListing = (props) => {
  let { gigSeekerId } = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const [expanded0, setExpanded0] = React.useState(true); 
   const [expanded1, setExpanded1] = React.useState(false);
  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const GotUsers = (response) => {
    let jobsBySeekerId = response.data.x;
    let jobsApplied = [];
    let jobsHired = [];
    jobsBySeekerId.forEach((element) => {
      if (element.status == "Applied") jobsApplied.push(element.gigJob);
      else jobsHired.push(element.gigJob);
    });

    setUsersData({ usersArray: jobsApplied, usersArrayHired: jobsHired });
    setCircularProgressHidden(true);
  };
  const [usersData, setUsersData] = useState({
    usersArray: [],
    usersArrayHired: [],
  });

  const [gigType, setGigType] = useState("");
  const [skills, setSkills] = useState([]);
  const [hourlyRate, setHourlyRate] = useState("");
  const classes = useStyles();

  const history = useHistory();
  useEffect(() => {
    loadUsers();
  }, []);
  const handleGigTypeChange = (e, value) => {
    setGigType(value);
  };

  const loadUsers = () => {
    axios
      .get(`${backendUrl}/applicantstatus/` + localStorage.getItem("_id"))
      .then(
        (response) => {
          if (gigSeekerId === undefined || gigSeekerId === "")
            gigSeekerId = props.gigSeekerId;
          if (response.data != undefined) GotUsers(response);
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };
  return (
    <>
      <Grid>
        <Grid item xs={12}>
          <MainHeader></MainHeader>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <PaperDetails>
            <Grid container xs={12}>
              <Grid item xs={8}>
                <div style={{ marginLeft: 25 }}>
                  <br />
                  <PageHeader
                    title="Gig Provider Search"
                    subTitle="Search for the right Gig providers that fit to your skills. Use filters for a quick search."
                  />
                </div>
              </Grid>
              <Grid item sm={3} />
              <Grid item sm={1}>
                <div hidden={localStorage.getItem("profile") === "admin"}>
                  <Controls.Button
                    type="submit"
                    text="All Jobs"
                    onClick={() => {
                      history.push("/seekersearchjobListing");
                    }}
                    className={classes.submitBtn}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={3}>
                <Box className={classes.wrapper}>
                  <List component="nav">
                    <ListItemButton>
                      <Typography
                        variant="subtitle1"
                        className={classes.jobHeading}
                      >
                        {"Jobs"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.jobHeading}
                      >
                        {/* Reset */}
                      </Typography>
                    </ListItemButton>
                  </List>
                  <Divider />
                  <form>
                    <Grid xs={11} style={{ marginLeft: 5 }}>
                      <br />
                     <IconButton onClick={()=>{expanded0?setExpanded0(false):setExpanded0(true)}} > <NavigateNextIcon /> <Typography style={{color:"#f3670d"}}>
                        Job Applications ({usersData.usersArray.length})
                      </Typography></IconButton>
                    
                      <br />
                      <IconButton onClick={()=>{expanded1?setExpanded1(false):setExpanded1(true)}}> <NavigateNextIcon onClick={()=>{setExpanded1(true)}}/>
                      <Typography  style={{color:"brown"}}>
                        Jobs Hired ({usersData.usersArrayHired.length})
                      </Typography>
                      </IconButton>
                      <br />
                      <br />
                    </Grid>
                  </form>
                </Box>
              </Grid>
              <Grid item xs={9}>
              <Grid >
                <Accordion
                  className="AccordionStyle"
                  expanded={expanded0||expanded === "panel0"}
                  onChange={handleExpandChange("panel0")}
                  style={{background:"#f6f6f6"}}
                  elevation={0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1">My Job Applications</Typography>
                  </AccordionSummary>
                  <br />
                  <AccordionDetails >
                      <Grid sm={12}>
                      <div hidden={CircularProgressHidden}>
                        <CircularProgress hidden={CircularProgressHidden} />
                      </div>
                      <div>
                      {usersData.usersArray.length > 0 ? (
                        usersData.usersArray.map((job) => (
                          <>
                            <JobCard {...job} />
                            <ExtraSeekerDetails
                              gigSeekerId={localStorage.getItem("_id")}
                              gigProviderId={job.providerId}
                              gigJobId={job._id}
                              from="seeker"
                            />
                          </>
                        ))
                      ) : (
                        <>
                          {" "}
                          <Typography variant="body4">
                            No Jobs Posted.
                          </Typography>
                        </>
                      )}
                      </div>
                      </Grid>
                      {/* <JobCard></JobCard>  */}
                  </AccordionDetails>
                </Accordion>
                </Grid>
                <Divider />
                <Accordion
                  className="AccordionStyle"
                  expanded={expanded1||expanded === "panel1"}
                  onChange={handleExpandChange("panel1")}
                  style={{background:"#f6f6f6"}}
                  elevation={0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1">My Hired Jobs</Typography>
                  </AccordionSummary>
                  <br />

                  <AccordionDetails className="AccordionDetailsStyle">
                  <Grid sm={12}>
                    <div>
                      <div hidden={CircularProgressHidden}>
                        <CircularProgress hidden={CircularProgressHidden} />
                      </div>
                      {usersData.usersArrayHired.length > 0 ? (
                        usersData.usersArrayHired.map((job) => (
                          <>
                            <JobCard {...job} />
                            <ExtraSeekerDetails
                              gigSeekerId={localStorage.getItem("_id")}
                              gigProviderId={job.providerId}
                              gigJobId={job._id}
                              from="seeker"
                            />
                          </>
                        ))
                      ) : (
                        <>
                          {" "}
                          <Typography variant="body4">
                            No Jobs Posted.
                          </Typography>
                        </>
                      )}
                      {/* <JobCard></JobCard>  */}
                    </div>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </PaperDetails>
        </Grid>
        <Grid item xs={12} hidden={localStorage.getItem("profile") === null}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default GigSeekerSearchApplicationsListing;
