import { React, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  Paper,
  ThemeProvider,
  createTheme,
  TextField,
  FormControl,
  IconButton
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Controls from "../resusable/controls/Controls";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import CeoImage from "../resources/GitIcon.JPG";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import ExtraSeekerDetails from "../job/extraSeekerDetails";
import MainHeader from "../resusable/mainHeader";
import { useParams } from "react-router";
import { backendUrl } from "../config";
import Footer from "../resusable/footer";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import PageHeader from "../resusable/pageHeader";

const WhiteTextTypography = withStyles({
  root: {
    color: "black",
  },
})(Typography);

const themestyle = createTheme({ palette: { type: "dark" } });

const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const Placements = () => {
  const { gigSeekerId } = useParams();
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);

  const [providerEmail, setProviderEmail] = useState("");
  const [seekerEmail, setSeekerEmail] = useState("");
  const [applicantStatusData, setApplicantStatusData] = useState({
    gigSeeker: "60b322bb4bc37e2d612eb0f3",
    gigProvider: "60b33d91f2ccf030bb1998a7",
    gigSeekerCoverNote: "none",
    comments: "none",
    status: "",
  });
  useEffect(() => {}, [applicantStatusData]);
  const [hiddenPannelVisibility, setHiddenPannelVisibility] = useState(true);
  const useStyles = makeStyles({
    root: {
      background: "black",
    },
    wrapper: {
      border: "solid 1px #dcdcdc",
      backgroundColor: "#fff",
      margin: "0 1rem",
    },
  });

  const classes = useStyles();

  const loadUsers = () => {
    axios.get(`${backendUrl}/applicantstatus`).then(
      (response) => {
        if (response.data != undefined) GotUsers(response);
        // localStorage.userImage
      },
      (error) => {
        console.log("error" + error);
      }
    );
  };
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  // const showImageBySeekerId = async (source, fileId, type) => {
  //   alert(source, fileId, type)

  //   try {
  //     let ext = fileId.split('.').pop();
  //     axios.get(`${backendUrl}/getSeekerIdImage/` + source+"/"+fileId+"/"+type+"/"+ext,).then(
  //       (response) => {
  //         localStorage.setItem("userImage", response.data);
  //       })
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       console.log("photo not found")
  //     }
  //   }
  // };
  const GotUsers = (response) => {
    setCircularProgressHidden(true);
    setUsersData({ usersArray: response.data.users });
    console.log("localStorage+++++",response.data.users)
    response.data.users.forEach(function(item){
      console.log("inside+++++",item)
      // showImageBySeekerId(
      //   item._id,
      //   item.photoId,
      //   "image"
      // );    
    });
    
  };
  
  const GotUsersData = (response) => {
     setUsersData({ usersArray: response.data });
     setCircularProgressHidden(true);
     //alert(usersData.usersArray.length)
  };
  const [usersData, setUsersData] = useState({ usersArray: [] });
  useEffect(() => {
    loadUsers();
  }, []);

  const getStringArray = (arr, elements) => {
    return arr.map(
      (ele) =>
        `${ele[elements[0]]} ( ${elements
          .slice(1)
          .map((i) => ele[i])
          .join(", ")} )`
    );
  };

  const getDisplayString = (elementArray, label) => {
    let res;
    if (elementArray.length > 2)
      res = `${label} : ${elementArray.slice(0, 2).join(", ")} +${
        elementArray.count - 2
      }....`;
    else if (elementArray.length > 0)
      res = `${label} : ${elementArray.join(", ")}`;
    else res = `${label} : -`;
    return res;
  };

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(seekerEmail.length==0)
    {
      setCircularProgressHidden(false);
      loadUsers();
    }
    else{
    let data = {
      email: seekerEmail+","
    };
    setCircularProgressHidden(false);
    await postData(`${backendUrl}/findJobsByEmail`,data)
    .then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen:true,
          snackbarMsg:"Filter data fetch successful."
        })
        if (response.data != undefined) GotUsersData(response);
        if(response.data == undefined)
        {
          setCircularProgressHidden(true);
          setUsersData({usersArray:[]})
          setLoginData({
            snackbarOpen:true,
            snackbarMsg:"Info : No data matching the filter provided."
          })
        }
        }
    )
    .catch((error)=>{
      setCircularProgressHidden(true);
      setLoginData({
        snackbarOpen:true,
        snackbarMsg:""+error
      })
      console.log(error);
    })}
  };

  return (
    <div>
      <Grid>
        <Grid item xs={12}>
          <MainHeader></MainHeader>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
          <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={5000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
            <PaperDetails>
              <div style={{ marginLeft: 25 }}>
                <br />
                <PageHeader
                  title="Gig Placements"
                  subTitle="List of placements on each providers job with respect to seeker . Use filters for a quick search."
                />
              </div>
              <Grid container xs={12}>
                <Grid item xs={3}>
                  <Box className={classes.wrapper}>
                    <List component="nav">
                      <ListItemButton>
                        <Typography
                          variant="subtitle2"
                          className={classes.jobHeading}
                        >
                          {"Filter By"}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.jobHeading}
                        >
                          {/* Reset */}
                        </Typography>
                      </ListItemButton>
                    </List>
                    <Divider />
                    <form onSubmit={handleSubmit}>
                      <Grid xs={11} style={{ marginLeft: 5 }}>
                     
                        <br />
                        <Typography variant="body1">{" Email"}</Typography>
                        <Autocomplete
                        multiple
                        freeSolo
                        id="Email"
                        options={[]}
                        onChange={(e, values) => setSeekerEmail(values)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            //label="School/College Name"
                            placeholder="Add Email and enter"
                            onKeyDown={(e) => {
                              if (e.keyCode === 13 && e.target.value) {
                                setSeekerEmail(seekerEmail.concat(e.target.value));
                              }
                            }}
                          />
                        )}
                      />
                        <br />
                        <div style={{ textAlign: "center" }}>
                          <FormControl>
                            <Grid item style={{ textAlign: "center" }}>
                              <Controls.Button
                                className={classes.submitBtn}
                                type="submit"
                                text="Filter"
                              />
                            </Grid>
                            <br />
                          </FormControl>
                        </div>
                      </Grid>
                    </form>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <div hidden={CircularProgressHidden}>
                <CircularProgress
                              align="center"
                            ></CircularProgress></div>
                  <div>
                    {usersData.usersArray.length > 0 ? (
                      <Grid item sm={12}>
                        <div style={{ color: "white" }}>
                          {usersData.usersArray.map((user) => (
                            <>
                              <Paper >
                                <Card>
                                  <CardActionArea>
                                    <CardContent style={{backgroundColor:"#f6f6f6"}}>
                                      <Grid container>
                                        <Grid item sm={12}>
                                          <WhiteTextTypography
                                            variant="h5"
                                            component="h2"
                                          >
                                            {user.gigJob.company + "  "}
                                            {user.gigJob.gigTitle}  : {user.status}
                                          </WhiteTextTypography>
                                          <br />
                                        </Grid>
                                        <Grid item container sm={11}>
                                          <Grid
                                            item
                                            sm={3}
                                            style={{
                                              width: "128px",
                                              height: "128px",
                                              border: "1px solid black",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <Typography>
                                                <img
                                                src={localStorage.userImage || CeoImage}
                                                className="placement-logo"
                                              />
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            sm={8}
                                            style={{
                                              marginLeft: "1em",
                                            }}
                                          >
                                            <Grid
                                              item
                                              container
                                              sm={12}
                                              spacing={4}
                                            >
                                              <Grid item sm={12}>
                                                <WhiteTextTypography variant="body1">
                                                  {`Gig Type: ${user.gigJob.gigType}`}
                                                </WhiteTextTypography>
                                              </Grid>
                                              <Grid item sm={12}>
                                                <WhiteTextTypography variant="body1">
                                                  {`Required Skills : ${user.gigJob.skillsRequired}`}
                                                </WhiteTextTypography>
                                              </Grid>
                                              <Grid item sm={12}>
                                                <WhiteTextTypography variant="body1">
                                                  {`Hourly Rate : ${user.gigJob.hourlyRate+" Rs/hr"}`}
                                                </WhiteTextTypography>
                                              </Grid>
                                              <Grid item sm={12}>
                                                <WhiteTextTypography variant="body1">
                                                  {`Description : ${user.gigJob.description}`}
                                                </WhiteTextTypography>
                                              </Grid>
                                              <Grid item sm={12}>
                                                <WhiteTextTypography variant="body1">
                                                  {`Gig Provider Email : ${user.gigJob.providerEmail}`}
                                                </WhiteTextTypography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </CardActionArea>
                                  <CardActions style={{backgroundColor:"#f6f6f6"}}>
                                    <Grid item sm={12}>
                                      {/* <ExtraSeekerDetails
                                  gigSeekerId={gigSeekerId}
                                  gigProviderId={user._id}
                                  from="seeker"
                                /> */}

                                      <Grid
                                        item
                                        sm={12}
                                        className="GridMarginStyle"
                                      >
                                        <div style={{ color: "white" }}>
                                          <Paper className="MarginStyle">
                                            <Card>
                                              <CardActionArea>
                                                <CardContent >
                                                  <Grid container spacing={0}>
                                                    <Grid item sm={12}>
                                                      <WhiteTextTypography
                                                        variant="h5"
                                                        component="h2"
                                                      >
                                                        {
                                                          
                                                          user.gigSeeker.firstName                                                            .firstName
                                                        }
                                                        {" " +
                                                          user.gigSeeker.lastName}
                                                      </WhiteTextTypography>
                                                    </Grid>
                                                    <br />
                                                    <Grid
                                                      item
                                                      container
                                                      sm={11}
                                                    >
                                                      <Grid
                                                        item
                                                        sm={3}
                                                        style={{
                                                          width: "128px",
                                                          height: "128px",
                                                          //border: "0.5px solid white",
                                                          marginTop: "15px",
                                                          display: "flex",
                                                          border: "1px solid black",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <div>
                                                          <Typography>
                                                            <img
                                                              src={CeoImage}
                                                              className="placement-logo"
                                                            />
                                                          </Typography>
                                                               {/* <img
                                    src={CeoImage}
                                    className="MarginStyleWithoutBorder"
                                  />  */}
                                                        </div>
                                                      </Grid>
                                                      <Grid item sm={1} />
                                                      <Grid
                                                        item
                                                        sm={7}
                                                        style={{
                                                          marginLeft: "1em",
                                                        }}
                                                      >
                                                        <Grid
                                                          item
                                                          container
                                                          sm={12}
                                                          spacing={3}
                                                        >
                                                          <Grid item sm={6}>
                                                            <WhiteTextTypography variant="body1">
                                                              {`Gig Seeker Email: ${user.gigSeeker.email}`}
                                                            </WhiteTextTypography>
                                                          </Grid>
                                                          <Grid item sm={6}>
                                                            <WhiteTextTypography variant="body1">
                                                              {`Phone No : ${user.gigSeeker.phoneNo}`}
                                                            </WhiteTextTypography>
                                                          </Grid>
                                                          {/* <Grid item sm = {4} variant="body1">
                                      {`Alternate Phone No: ${user.alternatePhoneNo}`}
                                    </Grid> */}
                                                          <Grid item sm={12}>
                                                            <WhiteTextTypography>
                                                              {`Skills : ${user.gigSeeker.secondarySkills}`}
                                                            </WhiteTextTypography>
                                                          </Grid>
                                                          <Grid item sm={12}>
                                                            <WhiteTextTypography>
                                                              {getDisplayString(
                                                                getStringArray(
                                                                  user.gigSeeker.experiences.slice(
                                                                    0,
                                                                    1
                                                                  ),
                                                                  [
                                                                    "company",
                                                                    "title",
                                                                  ]
                                                                ),
                                                                "Current Company"
                                                              )}
                                                            </WhiteTextTypography>
                                                          </Grid>
                                                          <Grid item sm={12}>
                                                            <WhiteTextTypography>
                                                              {getDisplayString(
                                                                getStringArray(
                                                                  user.gigSeeker
                                                                    .qualifications,
                                                                  [
                                                                    "schoolName",
                                                                    "degree",
                                                                  ]
                                                                ),
                                                                "Education"
                                                              )}
                                                            </WhiteTextTypography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </CardActionArea>
                                              <CardActions >
                                                <Grid item sm={12}>
                                                  <ExtraSeekerDetails
                                                    gigSeekerId={
                                                      user.gigSeeker._id
                                                    }
                                                    gigProviderId={
                                                      user.gigProvider._id
                                                    }
                                                    gigJobId={user.gigJob._id}
                                                    name={
                                                      user.gigSeeker.firstName
                                                    }
                                                    email={user.gigSeeker.email}
                                                    phoneNo={
                                                      user.gigSeeker.phoneNo
                                                    }
                                                    hourlyPay={
                                                      user.gigJob.hourlyRate
                                                    }
                                                    from="admin"
                                                  />
                                                </Grid>
                                              </CardActions>
                                            </Card>
                                          </Paper>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </CardActions>
                                </Card>
                              </Paper>
                              <br />
                            </>
                          ))}
                        </div>
                      </Grid>
                    ) : (
                      <Grid xs="12">
                        <Paper className="MarginStyle">
                          <WhiteTextTypography align="center" variant="h5">
                           
                            <br/>
                            No Data!
                          </WhiteTextTypography>
                        </Paper>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </PaperDetails>
          </Grid>
        </Grid>
        <Grid item xs={12} hidden={localStorage.getItem("profile") === null}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </div>
  );
};
export default Placements;