import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid, TextField, makeStyles, Paper } from "@material-ui/core";
import LogoImage from "../resources/logo@3x.png";
import GridImage1 from "../resources/image@3x.png";
import GridImage2 from "../resources/group-3@3x.png";
import GridImage3 from "../resources/group-9@3x.png";
import GridImage4 from "../resources/bitmap@3x.png";
import GridImage5 from "../resources/bitmap@4x.png";
import GridImage6 from "../resources/bitmap@5x.png";
import Arrow from "../resources/arrow@3x.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import JobListing from "./jobListing";
import Footer from "../resusable/footer";
import DiscoverUsMenu from "../resusable/DisoverUsMenu";
import AccountMenu from "../resusable/AccountMenu";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    margin: "0",
    padding: "0px 0px 0px 45px",
  },
  mainHeader: {
    padding: theme.spacing(4),
    display: "flex",
    marginBottom: theme.spacing(2),
    color: "#34495e",
  },
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));
const Navbar = () => {
  const classes = useStyles();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        {" "}
        <Paper>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <div hidden={localStorage.getItem("name") != null}>
                <Grid container spacing={5}>
                  <Grid item sm={2}>
                    <a href="/">
                      <img className="Logo" src={LogoImage} />
                    </a>
                  </Grid>
                  <Grid item sm={4}></Grid>

                  <Grid item sm={6}>
                    <Grid container spacing={1} justify="flex-end">
                      <Grid item sm={1} />
                      <Grid item sm={4}>
                        <Grid container justify="flex-end">
                          <Grid item>
                            <DiscoverUsMenu />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={3}>
                        <div class="Rectangle">
                          <Button>
                            <Link
                              to={"/signup"}
                              style={{ textDecoration: "none" }}
                              className="Join y"
                            >
                              <p
                                className="Join"
                                style={{ textAlign: "center" }}
                              >
                                Join
                              </p>
                            </Link>
                          </Button>
                        </div>
                      </Grid>
                      <Grid item sm={3}>
                        <div class="Rectangle-Copy">
                          <Button>
                            <Link
                              to={"/login"}
                              style={{ textDecoration: "none" }}
                              className="Join"
                            >
                              <p className="Sign-in">Sign In</p>
                            </Link>
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div hidden={localStorage.getItem("name") === null}>
                <Paper className={classes.root} elevation={0}>
                  <Grid container>
                    <Grid item style={{ marginTop: 10 }} sm={1}>
                      <img className="Logo" src={LogoImage} />
                    </Grid>
                    <Grid item sm={9} />
                    <Grid item sm={1}>
                      <div
                        className={classes.pageTitle}
                        style={{ marginTop: 30 }}
                      >
                        <Typography variant="subtitle2">
                          Hi {localStorage.getItem("name")}!
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item sm={1}>
                      <AccountMenu />
                    </Grid>
                    <br />
                  </Grid>
                </Paper>
              </div>
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={4} name="firstpaper">
              <Grid container spacing={3}>
                <Grid item sm={1} />
                <Grid item sm={12}>
                  <p className="Best-Online-Gigs-Net"></p>
                  <p className="Hire-the-best-Gigs">
                    {" "}
                    Best online Gig Jobs network
                  </p>
                  <br/>
                  <p className="for-any-job-online"> </p>
                  <p className="for-any-job-online2">
                    Hire the best Gigs, Select the deserving candidate.
                  </p>
                  <p className="for-any-job-online2">
                    {" "}
                    Choose your job wisely only on GigJobs online.
                  </p>
                  <br/>
                  <p className="for-any-job-online2">
                    {" "}
                    GigJobs helps thousands of individuals realise their dreams.
                    Fulfill your aim,  your choice, and ultimately your
                    goal with GigJobs.
                  </p>
                  <br/>
                  <Grid item sm={5}>
                    <div class="Join-for-FreeRectangle">
                      <Button>
                        <Link style={{ textDecoration: "none" }} to={"/signup"}>
                          {" "}
                          <p
                            className="Join-for-Free"
                            style={{ textAlign: "center" }}
                          >
                            Join Us
                          </p>{" "}
                        </Link>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={7}>
              <img className="Gridimage1" src={GridImage1} />
            </Grid>

            {/* <Grid container sm={12} spacing={2}>
              <Grid item sm={6}>
                <Paper className="PaperStyle1">
                  <Grid container sm={12} spacing={2}>
                    <Grid item sm={12}>
                      <img className="Gridimage2" src={GridImage2} />
                      <p>For Seekers</p>
                      <p className="Lakh-Online-Jobs">
                        1 Lakh+ Online Jobs for Gigs
                      </p>
                      <p className="Tired-of-going-aroun">
                        Tired of going around and looking for a better
                        oppurtunity for employment then you are now at a good
                        place just fill in your details and we take care of
                        matching your profile with best jobs as per your
                        profile.
                      </p>
                      <p className="How-it-works">How it works?</p>
                    </Grid>
                    <Grid item sm={4} />
                    <Grid item sm={4}>
                      <div class="JoinfreeButtonRectangle">
                        <Button>
                          <Link
                            to={"/signup"}
                            style={{ textDecoration: "none" }}
                            className="Join-FreeButtonText"
                          >
                            {" "}
                            <p
                              className="Join-FreeButtonText"
                              style={{ textAlign: "center" }}
                            >
                              Join Us
                            </p>{" "}
                          </Link>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={6}>
                <Paper elevation={1}>
                  <Grid container sm={12} spacing={2}>
                    <Grid item sm={12}>
                      <img className="Gridimage3" src={GridImage3} />
                      <p>For Providers</p>
                      <p className="Company-budget-Get-Copy">
                        Company budget? Get more done for less
                      </p>
                      <p className="Tired-of-going-aroun">
                        Looking for an outstanding employee who is qualified as
                        per your needs and works with commitment. Here is a
                        platform that connects you with the wide variety of
                        choices with remarkable talents.
                      </p>
                      <p className="How-it-works">
                        <br />
                        How it works?
                      </p>
                    </Grid>
                    <Grid item sm={4} />
                    <Grid item sm={4}>
                      <div class="SignupButtonRectangle">
                        <Button>
                          <Link
                            to={"/signup"}
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            <p
                              className="Sign-upButtonText"
                              style={{ textAlign: "center" }}
                            >
                              Sign Up
                            </p>{" "}
                          </Link>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <p className="Find-the-right-job-o">
                Find the right job or internship for you
              </p>
            </Grid>
            <Grid item sm={6}>
              <img className="Gridimage4" src={GridImage4} />
            </Grid>

            <Grid item sm={6}>
              <h1 className="SuggestionsTitle">
                <br />
                <br />
                Suggestions
              </h1>

              <Grid container>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Engineering</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Finance</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Marketing</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">UX/UI</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Business Development</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Retail Associate</p>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div class="SuggestionRectangle">
                    <Button>
                      <p className="SuggestionText">Customer Service</p>
                    </Button>
                  </div>
                </Grid>
                <p className="Show-All-Jobs">Show all jobs</p>
              </Grid>
            </Grid>

            <Grid item sm={7}>
              <Paper className="PaperStyle2">
                <Grid container spacing={3}>
                  <Grid item sm={2} />
                  <Grid item sm={6}>
                    <p className="Check-out-the-Gigs-s">
                      Check out the Gigs story of finding a new job on GigJobs
                    </p>
                    <img className="Gridimage5" src={GridImage5} />
                    <p className="Tired-of-going-aroun">
                      I have enjoyed working with Afterwork. I can proudly say
                      this is my first and best move. Getting work from GigJobs
                      was very easy.
                    </p>
                  </Grid>
                  <Grid item sm={2} />
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper>
                <p className="Global-network-of-ov">
                  Global network of over 500+ Companies
                </p>
                <img className="Gridimage5" src={GridImage5} />
                <p className="GigJobs-help-us-to-f">
                  GigJobs help us to find the correct match for our app building
                  requirement
                </p>
                <p className="CEO-Vroom"> CEO, Vroom</p>
              </Paper>
            </Grid> */}
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid>
                    <Grid item>
                      {" "}
                      <p className="Join-with-your-Profe">
                        {" "}
                        Join with your Professional Network and friends on
                        GigJobs.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <img className="Gridimage6" src={GridImage6} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={5} />
                <Grid item sm={2}>
                  <div class="Join-for-FreeRectangle">
                    <Button>
                      <Link
                        to={"/signup"}
                        style={{ textDecoration: "none" }}
                        className="Join-for-Free"
                      >
                        {" "}
                        <p
                          className="Join-for-Free"
                          style={{ textAlign: "center" }}
                        >
                          Join Us
                        </p>{" "}
                      </Link>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer></Footer>
        </Paper>
      </div>
    </>
  );
};

export default Navbar;
