import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#f6f6f6",
      boxShadow: "none",
    },
    imageStyle: {
     height : "100px",
     width : "100px"
    },
    headerStyle: {
      fontFamily: "Poppins-Medium",
      fontSize : "14px",
      color : "#f3670d"
    },
  }));


export default function MenuItems(props) {
    const classes = useStyles();
    const { name } = props;

    return (
        <span className={classes.root}>
            <p className={classes.headerStyle}> {name} </p>
        </span>
    )
}