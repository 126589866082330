import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PageHeader from "../resusable/pageHeader";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharp from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid, Button, Paper, makeStyles, IconButton } from "@material-ui/core";
import Controls from "../resusable/controls/Controls";
import { useForm, Form } from "../resusable/useForm";
import MuiPaper from "@mui/material/Paper";
import MainHeader from "../resusable/mainHeader";
import { Link } from "react-router-dom";
import Footer from "../resusable/footer";
import { useHistory } from "react-router-dom";
import { backendUrl } from "../config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuidv4 } from "uuid";
const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "#34495e",
  },
  ".MuiOutlinedInput-root": {
    margin: "0.4rem",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const INewExperience = {
  title: "",
  company: "",
  location: "",
  startDate: "",
  endDate: "",
};
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  addMoreBtn: {
    backgroundColor: "black",
    borderRadius: 0,
    padding: "8px",
    fontSize: "0.8em",
  },
  removeBtn: {
    backgroundColor: "black",
    borderRadius: 0,
    padding: "8px",
    fontSize: "0.8em",
  },
}));
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

function ExperienceDetails() {
  //const [expanded, setExpanded] = React.useState("panel1");
  const location = useLocation();
  let email = localStorage.getItem("emailId")
  //location.pathname.substring(19, location.pathname.length);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const classes = useStyles();
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const history = useHistory();
  useEffect(() => {
    loadUser();
  }, []);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        //startDate: "",
        //endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        //startDate: "",
        //endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
  });
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  // const handleSubmit = e => {
  //     e.preventDefault()
  //     if (validate()){
  //       //  employeeService.insertEmployee(values)
  //         resetForm()
  //     }
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    setCircularProgressHidden(false);
    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Submision Successful!",
        });

        history.push("/certificationsDetails/");
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };

  const handleChangeInput = (event, index) => {
    const newExperience = userData.experiences.map((i, elindex) => {
      if (index === elindex) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setUserData((userData) => ({
      ...userData,
      experiences: newExperience,
    }));
  };
  const handleAddIcon = () => {
    // let newExperiences = [...userData.experiences];
    // newExperiences.push({ id: uuidv4(), ...INewExperience });
    // setUserData({ ...userData, experiences: [...newExperiences] });

    userData.experiences.push({ id: uuidv4(), ...INewExperience });

    //alert(userData.experiences.length);

    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Add Successful!",
        });
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };
  const handleRemoveIcon = () => {
    userData.experiences.splice(userData.experiences.length -1)
    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Remove Successful!",
        });
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };
  const loadUser = () => {
    //let emailId ={email} ; //props.emailid;
    // if(!isCurrentURL("/gigSeekerLogin"))
    // emailId=localStorage.getItem('emailId');

    axios
      .get(
        `${backendUrl}/jobseekers/` + localStorage.getItem("emailId")
        //,{headers: {"AccessToken": localStorage.getItem("token")}}
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (
            response.data.user != undefined &&
            response.data.user[0] != undefined
          ) {
            setUserData(response.data.user[0]);
            //setgigSeekerId(response.data.user[0]._id);
            setLoginData({
              snackbarOpen: true,
              snackbarMsg: "Info: Profile load Successful",
            });
          } else {
          }
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };
  const [noOfDetails, setAddMoreDetails] = useState(1);

  const handleAccExpand=(idx)=>{
    if(idx===0)
    return true;
   };
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <PaperDetails>
        <div hidden={CircularProgressHidden}>
          <CircularProgress hidden={CircularProgressHidden} />
        </div>
        <PageHeader
          title="Experience"
          subTitle="Adding maximum qualification is an important factor"
          progressBarValue={40}
        />
        {/* {userData.experiences.map((element,index) => {
                  return (<EachExperienceGrid idx={index}>{element}</EachExperienceGrid>);
                })} */}

          {userData.experiences.map((experiences, index) => (
            <>
            <br/>
            <Paper elevation={5}>
            <Accordion
              expanded={handleAccExpand(index)}
              //onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
              <Typography>Experience {index + 1}</Typography></AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Form>
                    <Grid container>
                      <Grid item xs={12}>
                        <Controls.Input
                          name="title"
                          label="Job Title"
                          //value={values.jobTitle}
                          error={errors.jobTitle}
                          value={experiences.title}
                          onChange={(e) => handleChangeInput(e, index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 0 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 0 }}
                    >
                      <Grid item xs={6}>
                        <Controls.Input
                          label="Company"
                          name="company"
                          //value={values.company}
                          error={errors.company}
                          value={experiences.company}
                          onChange={(e) => handleChangeInput(e, index)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controls.Input
                          label="Location"
                          name="location"
                          //value={values.location}
                          error={errors.location}
                          value={experiences.location}
                          onChange={(e) => handleChangeInput(e, index)}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                            <Controls.DatePicker
                                name="fromDate"
                                label="From Date"
                                value={values.fromDate}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.DatePicker
                                name="toDate"
                                label="To Date"
                                value={values.toDate}
                            />
                        </Grid> */}
                    </Grid>
                  </Form>
                </Typography>
              </AccordionDetails>
            </Accordion>
            </Paper>
           </>
          ))}
       
        <Grid container>
          <Grid item style={{ margin: "0 auto" }}>
            <Controls.Button
              text="+ Add More"
              className={classes.addMoreBtn}
              onClick={() => {
                handleAddIcon();
                //setAddMoreDetails(noOfDetails + 1);
              }}
            />
            <Controls.Button
              text="x Remove"
              className={classes.removeBtn}
              onClick={() => 
                {
                  handleRemoveIcon();
                  //setAddMoreDetails(noOfDetails - 1)
                }}
            />
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginData.snackbarOpen}
          autoHideDuration={5000}
          message={loginData.snackbarMsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={snackBarCloseEvent}
            >
              x
            </IconButton>,
          ]}
          onClose={() => setLoginData({ snackbarOpen: false })}
        />

        <br />
        <Grid container>
          <Grid item xs={12}>
            <div>
              <Link
                style={{ textDecoration: "none" }}
                to={"/certificationsDetails/"}
              >
                <Controls.Button text="Skip" onClick={resetForm} />
              </Link>
              <Controls.Button
                type="submit"
                text="Save and Continue"
                className={classes.submitBtn}
                onClick={handleSubmit}
              />
            </div>
          </Grid>
        </Grid>
      </PaperDetails>
      <Footer></Footer>
    </>
  );
}

export default ExperienceDetails;
