import React , { useState } from "react";
import { styled } from '@mui/material/styles';
import MuiPaper from '@mui/material/Paper';
import {Box , Grid, Typography , Button , makeStyles , Link} from '@material-ui/core';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Controls from "../resusable/controls/Controls";
import JobModal from "./jobModal";

const skills = ["Javascript", "React Js " , "Node Js" ,"Angular Js"];
const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  

const useStyles = makeStyles({
    wrapper:{
       // boxShadow: '2px 2px 2px 2px #dcdcdc',
        opacity: '0.7',
        border: 'solid 1px #dcdcdc',
        backgroundColor: '#fff'
    },
    submitBtn: {
        fontFamily: 'Poppins' 
    },
    jobtitle:{
        color: '#f3670d',
        fontSize: '1.1rem'
    },
    jobHeading: {
        margin: '0.6rem 0rem'
    },
    description: {
        fontSize: '0.5rem'
    }
})
export default function JobCard (props){
    const classes = useStyles();
 
    const LongText = ({ content,limit}) => {
        const [showAll, setShowAll] = useState(false);
      
        const showMore = () => setShowAll(true);
        const showLess = () => setShowAll(false);
      
        if (content.length <= limit) {
          // there is nothing more to show
          return <div>{content}</div>
        }
        if (showAll) {
          // We show the extended text and a link to reduce it
          return <div> 
            {content} 
            <button onClick={showLess}>Show less</button> 
          </div>
        }
        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "...";
        return <div> 
          {toShow} 
          <button onClick={showMore}>Learn more</button>
        </div>
    }
    const [value, setValue] = React.useState(2);
    const [hover, setHover] = React.useState(-1);
    return(
        <>
        <Box p={2} className={classes.wrapper}>
            <Grid container>
                <Grid item md>
                    <Typography className={classes.jobtitle}> 
                     {props.gigTitle}  
                    {/* Senior Level Developer Needed */}
                    </Typography>
                </Grid>
                <Grid item container direction="column" alignItems="flex-end" md>
                    <Grid item>
                        
                    </Grid>
                    <Grid item align="left">
                        <Box>
                            <Typography variant="subtitle2" m={2}>Posted 1 day ago
                                
                                {/* <Controls.Button className={classes.submitBtn} type="submit"
                                  text="Apply"/>
                                 */}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
            <Typography className={classes.jobtitle}> 
                     {props.company}  
                    {/* Senior Level Developer Needed */}
                    </Typography>
            </Grid>
            <Grid container>
                <LongText 
                 content={props.description}
                // content = 'I need a senior level developer to work on a long term project max $10/hours' 
                limit={70}/>
            </Grid>
            <Grid container xs={12}>
                <Grid xs={6}>
                <Typography variant="subtitle2" className={classes.jobHeading}>Email: 
                        <span>{" "+props.providerEmail}</span>
                    </Typography>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Gig Type: 
                        <span>{" "+props.gigType}</span>
                    </Typography>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Skills: <span>{" "+props.skillsRequired.join(",")}</span></Typography>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Hourly Rate: <span>{" "+props.hourlyRate==" "?"NA":props.hourlyRate +" Rs/hr"}</span></Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Assesment Link: <span>{" "+props.assesment==" "?"NA":props.assesment +""}</span></Typography>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Deliverables: <span>{props.startDate+" - "+props.endDate}</span></Typography>
                    <Typography variant="subtitle2" className={classes.jobHeading}>Rating:{"Na"}
                        {/* <span className={classes.description}>
                            <Rating  name="hover-feedback" value={value} precision={0.5} 
                                onChange={(event, newValue) => {setValue(newValue);}}
                                onChangeActive={(event, newHover) => {setHover(newHover);}}
                                emptyIcon={<StarIcon />} />
                            {/* {value !== null && (
                                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                            )}                         </span> */}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}