import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PageHeader from "../resusable/pageHeader";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import JobCard from "../job/jobCard";
import Divider from "@mui/material/Divider";
import JobModal from "../job/jobModal";
import FilterComponent from "../job/filterComponent";
import { backendUrl } from "../config";
import { useParams } from "react-router";
import axios from "axios";
import ExtraSeekerDetails from "../job/extraSeekerDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Controls from "../resusable/controls/Controls";
import { useHistory } from "react-router-dom";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles({
  wrapper: {
    opacity: "0.7",
    border: "solid 1px #dcdcdc",
    backgroundColor: "#fff",
    margin: "0 1rem",
  },
});
const gigtypeList = ["Part time", "Full time", "Seasonal", "Hourly"];

const skillsData = [ "ReactJs",
  "Python",
  "Java",
  "JavaScript",
  "C#",
  "C",
  "C++",
  "PHP",
  "R",
  "Objective-C",
  "Swift",
  "TypeScript",
  "MATLAB",
  "Kotlin",
  "Go(Golang)",
  "VBA",
  "Ruby",
  "Scala",
  "Visual Basic",
  "Rust",
  "Dart",
  "Ada",
  "Lua",
  "Abap",
  "Groovy",
  "Perl",
  "Cobol",
  "Julia	Haskell	Delphi",
  "Elm",
  "PowerShell",
  "SQL",
  "Clojure",
  "Elixir",
  "Pascal",
  "LISP",
  "Ballerina",
  "FORTRAN",
  "BASIC",
  "Alice",
  "COBOL",
  "Speakeasy",
  "Simula",
  "Smalltalk",
  "Prolog",
  "Erlang",
  "Ada",
  "Eiffel",
"Rebol",
  "Scratch",
  "Shell Scripting",
    "MySQL",
    "Magento",
    "CodeIgniter",
    "AngularJS",
    "Linux",
    "json",
    "RESTful WebServices",
    "Moodle",
    "WordPress",
    "Angular Material",
    "PostgreSQL",
    "Django",
    "mysql",
    "Jenkins",
    "docker",
    "Git",
    "MongoDB",
    "Zend Framework",
    "Gitlab",
    "API Development",
    "Web Services API",
    "Google Maps API",
    "Google API",
    "DevOps",
    "Zend",
    "X-Cart",
    "Web Services",
    "jQuery",
    "Pycharm",
    "Ionic Framework",
    "Cordova",
    "Apache Cordova",
    "Google Cloud Platform (GCP)",
    "Express.js",
    "React.js",
    "Flask",
    "Django",
    "Pandas",
    "Anaconda",
    "PySpark",
    "Jenkins",
    "Sonarqube",
    "ASP.NET",
    "HTML5",
    "HTML Scripting",
    "HTML",
    "XHTML",
    "Cascading Style Sheet (CSS)",
    "CSS Sprites",
    "CSS Flexbox",
    "Bootstrap",
];
const GigSeekerSearchJobListing = (props) => {
  let { gigSeekerId } = useParams();
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const GotUsers = (response) => {
    setUsersData({ usersArray: response.data.jobs });
    setCircularProgressHidden(true);
  };
  const [usersData, setUsersData] = useState({ usersArray: [] });

  const [gigType, setGigType] = useState("");
  const [skills, setSkills] = useState([]);
  const [hourlyRate, setHourlyRate] = useState("");
  const classes = useStyles();
  
  const history = useHistory();
  useEffect(() => {
    loadUsers();
  }, []);
  const handleGigTypeChange = (e, value) => {
    setGigType(value);
  };

  const handleSubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    let data = {
      query: {
        skills,
        gigType,
        hourlyRate,
      },
    };
    if (
      skills.length === 0 &&
      (gigType === "" || gigType == null) &&
      hourlyRate.length === 0
    )
      data = {};
    axios.post(`${backendUrl}/jobs/findJobs`, data).then(
      (response) => {
        setCircularProgressHidden(true);
        if (response.data != undefined) GotUsers(response);
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log(error);
      }
    );
  };

  const loadUsers = () => {
    axios.get(`${backendUrl}/getjobs`).then(
      (response) => {
        if (gigSeekerId === undefined || gigSeekerId === "")
          gigSeekerId = props.gigSeekerId;
        if (response.data != undefined) GotUsers(response);
      },
      (error) => {
        console.log("error" + error);
      }
    );
  };
  return (
    <>
      <Grid>
        <Grid item xs={12}>
          <MainHeader></MainHeader>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <PaperDetails>
            <Grid container xs={12}>
              <Grid item xs={8}>
                <div style={{ marginLeft: 25 }}>
                  <br />
                  <PageHeader
                    title="Gig Provider Search"
                    subTitle="Search for the right Gig providers that fit to your skills. Use filters for a quick search."
                  />
                </div>
              </Grid>
              <Grid item sm={3} />
              <Grid item sm={1}>
                <div hidden={localStorage.getItem("profile") === "admin"}>
                  <Controls.Button
                    type="submit"
                    text="My Jobs"
                    onClick={() => {
                      history.push("/seekersearchApplicationsListing");
                    }}
                    className={classes.submitBtn}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={3}>
                <Box className={classes.wrapper}>
                  <List component="nav">
                    <ListItemButton>
                      <Typography
                        variant="subtitle2"
                        className={classes.jobHeading}
                      >
                        {"Filter By"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.jobHeading}
                      >
                        {/* Reset */}
                      </Typography>
                    </ListItemButton>
                  </List>
                  <Divider />
                  <form onSubmit={handleSubmit}>
                    <Grid xs={11} style={{ marginLeft: 5 }}>
                      <br />
                      <Typography variant="body1">{"Gig Type"}</Typography>
                      <br />
                      <Autocomplete
                        id="gigTypeComboBox"
                        options={gigtypeList}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Gig Type"
                            variant="outlined"
                            //required
                          />
                        )}
                        value={gigType}
                        onChange={handleGigTypeChange}
                      />
                      <br />

                      <Typography variant="body1">Skills</Typography>
                      <br />
                      <Autocomplete
                        multiple
                        limitTags={1}
                        id="secondarySkills"
                        options={skillsData}
                        onChange={(e, values) => setSkills(values)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            //label="Primary Skills"
                            placeholder="Add Primary Skills"
                          />
                        )}
                      />
                      <br />
                      <Typography variant="body1">Hourly Rate</Typography>
                      <br />
                      <Autocomplete
                        multiple
                        freeSolo
                        id="HourlyRate"
                        options={[]}
                        onChange={(e, values) => setHourlyRate(values)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            //label="School/College Name"
                            placeholder="Add Haourly Rate"
                            // onKeyDown={(e) => {
                            //   if (e.keyCode === 13 && e.target.value) {
                            //     setHourlyRate(hourly Rate.concat(e.target.value));
                            //   }
                            // }}
                          />
                        )}
                      />
                      <br />
                      <div style={{ textAlign: "center" }}>
                        <FormControl>
                          <Grid item style={{ textAlign: "center" }}>
                            <Controls.Button
                              className={classes.submitBtn}
                              type="submit"
                              text="Filter"
                            />
                          </Grid>
                          <br />
                        </FormControl>
                      </div>
                    </Grid>
                  </form>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <div>
                  <div hidden={CircularProgressHidden}>
                    <CircularProgress hidden={CircularProgressHidden} />
                  </div>
                  {usersData.usersArray.length > 0 ? (
                    usersData.usersArray.map((job) => (
                      <>
                        <JobCard {...job} />
                        <div hidden={props.from=="admin"}>
                        <ExtraSeekerDetails
                          gigSeekerId={localStorage.getItem("_id")}
                          gigProviderId={job.providerId}
                          gigJobId={job._id}
                          from="seeker"
                        />
                        </div>
                        <div hidden={!props.from=="admin"}>
                      <br/>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      {" "}
                      <Typography variant="body1">No Jobs Posted.</Typography>
                    </>
                  )}
                  {/* <JobCard></JobCard>  */}
                </div>
              </Grid>
            </Grid>
          </PaperDetails>
        </Grid>
        <Grid item xs={12} hidden={localStorage.getItem("profile") === null}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default GigSeekerSearchJobListing;
