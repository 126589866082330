import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PageHeader from "../resusable/pageHeader";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import JobCard from "../job/jobCard";
import Divider from "@mui/material/Divider";
import { backendUrl } from "../config";
import { useParams } from "react-router";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import ExtraSeekerDetails from "../job/extraSeekerDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Controls from "../resusable/controls/Controls";
import { useHistory } from "react-router-dom";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import VerifiedIcon from '@mui/icons-material/Verified';
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import firebase from "firebase/compat/app";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SendIcon from "@mui/icons-material/Send";
import MuiPhoneNumber from "material-ui-phone-number";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles({
  jobtitle: {
    color: "#f3670d",
    fontSize: "1.1rem",
  },
  submitBtn: {
    float: "right",
  },
  pageHeader: {
    color: "#34495e",
  },
  wrapper: {
    //opacity: "0.8",
    border: "solid 1px #dcdcdc",
    backgroundColor: "white",
    margin: "0 1rem",
  },
});

const GigProviderJobPosts = (props) => {
  let { gigSeekerId } = useParams();
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const GotUsers = (response) => {
    setUsersData({ usersArray: response.data.jobs });
    setCircularProgressHidden(true);
  };

  const [usersData, setUsersData] = useState({ usersArray: [] });
  const [providerData, setProviderData] = useState({
    _id: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone:"",
    otp:"",
    emailVerified:"",
    otpVerified:""

  });

  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    loadUsers();
  }, []);

  const handleSubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
  };
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const loadUsers = () => {
    axios
      .get(`${backendUrl}/jobs` + "/" + localStorage.getItem("emailId"))
      .then(
        (response) => {
          if (response.data != undefined) GotUsers(response);
        },
        (error) => {
          console.log("error" + error);
        }
      );

    axios
      .get(`${backendUrl}/jobproviders/` + localStorage.getItem("emailId"))
      .then((response) => {
        setProviderData(response.data.user[0]);
      });
  };
  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleJobDelete=()=>{
    axios
    .delete(`${backendUrl}/jobs` + "/" + localStorage.getItem("jobId"))
    .then(
      
      (response) => {
       axios
       .get(`${backendUrl}/jobs` + "/" + localStorage.getItem("emailId"))
       .then(
         (response) => {
           if (response.data != undefined) GotUsers(response);
         },
         (error) => {
           console.log("error" + error);
         }
       );
      },
      (error) => {
        console.log("error" + error);
      }
    );
  }

  const handleprofileedit = () => {
    providerData._id = localStorage.getItem("_id");
    setCircularProgressHidden(false);
    //alert(providerData.phone)
    postData(`${backendUrl}/jobproviders`, { ...providerData })
      .then((response) => {
        setCircularProgressHidden(true);
        localStorage.setItem("_id", response.data._id);
        history.push("/gigproviderjobposts/");
      })
      .then(() => {
        localStorage.setItem("emailId", providerData.email);
        localStorage.setItem("profile", "provider");
        localStorage.setItem("name", providerData.firstName);
        alert("HI2")
        axios.get(`${backendUrl}/jobproviders/` + providerData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setProviderData(response.data.user[0]);
              history.push("/gigproviderjobposts/");
          },
          (error) => {
            console.log("error" + error);
          }
        );
      })
      .catch((error) => {
        console.log("error" + error);
        setCircularProgressHidden(true);
      });
  };
  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };


  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          console.log("Captcha Resolved");
          onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    setUpRecaptcha();
    let phoneNumber = providerData.phone;
    let appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        firebase
        .auth()
        .currentUser.linkWithPhoneNumber(providerData.phone, window.recaptchaVerifier)
        .then(function (confirmationResult) {
            window.confirmationResult = confirmationResult;
          }).catch(function (error) {
        })
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // console.log(confirmationResult);
      
      })
      .catch(function (error) {
        console.log(error);
        localStorage.setItem("phoneVerified", "notnow");
        alert(" " + error);
      });
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    let otpInput = providerData.otp;
    let optConfirm = window.confirmationResult;
   // localStorage.setItem("phoneVerified","")
    // console.log(codee);
    if (optConfirm != undefined)
      optConfirm
        .confirm(otpInput)
        .then(function (result) {
          // User signed in successfully.
          // console.log("Result" + result.verificationID);

          let user = result.user;
          localStorage.setItem("phoneVerified", "yes");
          providerData.otpVerified = "true";
          setLoginData({
            snackbarMsg: "Valid Otp Entered.",
          });
        })
        .catch(function (error) {
          //console.log(error);
          localStorage.setItem("otpsent", "No");
          providerData.otpVerified = "false";
          setLoginData({
            snackbarOpen:true,
            snackbarMsg: error+"please try again.",
          });
        });
    else alert("Invalid steps.");
  };

  return (
    <>
      <Grid>
        <Grid item xs={12} hidden={localStorage.getItem("profile") === null}>
          <MainHeader></MainHeader>
        </Grid>
        <Grid item xs={12} hidden={!localStorage.getItem("profile") === null}>
          <br />
        </Grid>
        <Grid item xs={12}>
        <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={5000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
          <PaperDetails>
            <div style={{ marginLeft: 25 }}>
              <br />
              <Grid container xs={12}>
                <Grid item sm={8}>
                  <PageHeader
                    title="Jobs Posted"
                    subTitle="Search for the right Gig providers that fit to your skills. Post new jobs to fit the role."
                  />
                </Grid>
                <Grid item sm={3} />
                <Grid item sm={1}>
                  <Controls.Button
                    type="submit"
                    text="Post new"
                    onClick={() => {
                      localStorage.setItem("jobId", undefined);
                      history.push("/gigprovidersignup/" + "newPost");
                    }}
                    className={classes.submitBtn}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid container xs={12}>
            
              <Grid item xs={3}>
               <div hidden={CircularProgressHidden} style={{textAlign:"center"}}>
                    <CircularProgress hidden={CircularProgressHidden} />
                  </div>
                <Box className={classes.wrapper}>
                  <List component="nav">
                    <ListItemButton>
                      <Typography variant="h6" className={classes.pageHeader}>
                        {"Profile Details"}
                        <br />
                      </Typography>
                    </ListItemButton>
                  </List>
                  <div>
                      <Grid container>
                        <Grid item xs={11}>
                          <TextField
                            className={classes.textFeild}
                            placeholder="First Name"
                            label="First Name"
                            variant="outlined"
                            required
                            fullWidth
                            type="text"
                            value={providerData.firstName}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^\w\s]/gi, "");
                              setProviderData({
                                ...providerData,
                                firstName: value,
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={11}>
                          <br />
                          <TextField
                            className={classes.textFeild}
                            placeholder="Last Name"
                            label="Last Name"
                            variant="outlined"
                            required
                            fullWidth
                            type="text"
                            value={providerData.lastName}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^\w\s]/gi, "");
                              setProviderData({
                                ...providerData,
                                lastName: value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <br />
                          <TextField
                            className={classes.textFeild}
                            label="Email"
                            placeholder="Enter Email"
                            variant="outlined"
                            type="email"
                            disabled={true}
                            required
                            fullWidth
                            value={providerData.email}
                            onChange={(e) =>
                              setProviderData({
                                ...providerData,
                                email: e.target.value,
                              })
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{width:"5"}}>
                                  <IconButton aria-label="toggle password visibility" >
                                    {localStorage.getItem("emailVerified")==="yes"?
                                    <VerifiedIcon
                                   
                                     onClick={()=>{
                                      setLoginData({
                                        snackbarOpen:true,
                                        snackbarMsg:"Email Verified."
                                      })
                                    }} style={{color:"green"}}/>: <VerifiedIcon 
                                    onClick={()=>{
                                      setLoginData({
                                        snackbarOpen:true,
                                        snackbarMsg:"Email Not Verified. Please verify and sign in again to update!"
                                      })}}
                                    style={{color:"red"}}/>}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <br />
                                <MuiPhoneNumber
                                className={classes.textFeild}
                                label="Phone number"
                                placeholder="Enter phone number"
                                variant="outlined"
                                required
                                fullWidth
                                value={providerData.phone}
                                onChange={(value) => {
                                  setProviderData({
                                    ...providerData,
                                    phone: value,
                                  });
                                }}
                                onKeyDown={() => {
                                  if (
                                    providerData.phone.length < 10
                                  )
                                    setLoginData({
                                      snackbarOpen: true,
                                      snackbarMsg:
                                        "Error:please enter 10 digit phone number.",
                                    });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton aria-label="toggle password visibility">
                                        {localStorage.getItem(
                                          "phoneVerified"
                                        ) === "yes" || providerData.otpVerified=="true"? (
                                          <VerifiedIcon
                                            onClick={() => {
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg:
                                                  "Phone Number Verified.",
                                              });
                                            }}
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <VerifiedIcon
                                            onClick={(e) => {
                                              setSubmitOpen(true);
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg:
                                                  "Phone Number Not Verified. Please verify and sign in again to update!",
                                              });
                                            }}
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <div id="recaptcha-container"></div>
                              <Dialog
                                onClose={handleSubmitClose}
                                open={SubmitOpen}
                                style={{
                                  border: "1px solid",
                                  borderColor: "black",
                                }}
                              >
                                <DialogTitle
                                  id="simple-dialog-title"
                                  style={{ background: "white" }}
                                >
                                  <Grid item xs={12}>
                                    <Grid container name="Submit">
                                      <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                          <CreditScoreIcon></CreditScoreIcon>
                                          <br />
                                          Phone Validation!
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </DialogTitle>
                                <Paper style={{ background: "white" }}>
                                  <Grid
                                    container
                                    name="Submit"
                                    className="GridMarginStyle"
                                  >
                                    <Grid item xs={2} />
                                    <Grid item xs={8}>
                                      <Typography align="center">
                                        You are going to validate phone number.
                                        Click on Confirm to continue.
                                        <br />
                                        <br />
                                        <br />
                                      </Typography>
                                      <Grid item xs={12}>
                                        <Controls.Button
                                          text="Confirm "
                                          onClick={(e) => {
                                            setSubmitOpen(false);
                                            localStorage.setItem(
                                              "phoneVerified",
                                              "now"
                                            );
                                            onSignInSubmit(e);
                                          }}
                                        />
                                        <div className={classes.submitBtn}>
                                          <Controls.Button
                                            text="Cancel"
                                            onClick={handleSubmitClose}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <br />
                                  </Grid>
                                  <br />
                                </Paper>
                              </Dialog>

                              <div
                                hidden={
                                  localStorage.getItem("phoneVerified") !==
                                  "now"
                                }
                              >
                                <TextField
                                  className={classes.textFeild}
                                  placeholder="Otp"
                                  label="Otp"
                                  variant="outlined"
                                  fullWidth
                                  type="text"
                                  value={providerData.otp}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setProviderData({
                                      ...providerData,
                                      otp: value,
                                    });
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility">
                                          {localStorage.getItem("otpsent") ===
                                          "yes" ? (
                                            <SendIcon
                                              onClick={() => {
                                                setLoginData({
                                                  snackbarOpen: true,
                                                  snackbarMsg:
                                                    "Phone Number Otp.",
                                                });
                                              }}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <SendIcon
                                              onClick={(e) => {
                                                onSubmitOtp(e);
                                                localStorage.setItem(
                                                  "otpsent",
                                                  "yes"
                                                );
                                                setLoginData({
                                                  snackbarOpen: true,
                                                  snackbarMsg:
                                                    "Phone Number Otp is being Verified. Please sign in again to update!",
                                                });
                                              }}
                                              style={{ color: "Blue" }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={2} />
                        <Grid item>
                          <Controls.Button
                            type="submit"
                            text="Apply Changes"
                            //style={{ marginLeft: 70 }}
                            className={classes.submitBtn}
                            onClick={handleprofileedit}
                          />
                        </Grid>
                      </Grid>
                  </div>
                  <Divider />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <div>
                  <div hidden={CircularProgressHidden}  style={{textAlign:"center"}}>
                    <CircularProgress hidden={CircularProgressHidden} />
                  </div>
                  {usersData.usersArray.length > 0 ? (
                    usersData.usersArray.map((job) => (
                      <>
                        <JobCard {...job}></JobCard>{" "}
                        <Paper>
                          {" "}
                          <Grid container xs={12}>
                            {" "}
                            <Grid item>
                              <Button
                                onClick={() => {
                                  localStorage.setItem("jobId", job._id);
                                  history.push(
                                    "/gigprovidersignup/" +"editPost"
                                  );
                                }}
                              >
                                &nbsp;&nbsp;&nbsp;{"Edit Post"} &nbsp;
                                <EditIcon />
                              </Button>
                              <Button
                                onClick={() => {
                                  localStorage.setItem("jobId", job._id);
                                  handleJobDelete()
                                }}
                              >
                                &nbsp;&nbsp;&nbsp;{"Remove"} &nbsp;
                                <DeleteIcon />
                              </Button>
                            </Grid>
                            <Grid item xs={7} />
                            <Grid item>
                              <Button
                                onClick={() => {
                                  localStorage.setItem("jobId", job._id);
                                  //alert(localStorage.getItem("jobId"))
                                  history.push(
                                    "/providersearchjobListing/" + job._id
                                  );
                                }}
                              >
                                {"See Applicants"} &nbsp; &nbsp;{" "}
                                <PersonSearchIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                        <br />
                      </>
                    ))
                  ) : (
                    <>No Jobs Posted</>
                  )}

                  {/* <JobCard></JobCard>  */}
                </div>
              </Grid>
            </Grid>
          </PaperDetails>
        </Grid>
        <Grid item xs={12} hidden={localStorage.getItem("profile") === null}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default GigProviderJobPosts;
