import "./App.css";
import Gigseekersignup from "./components/gigseeker/gigseekersignup";
import CommonSignUp from "./components/home/commonSignUp";
import Navbar from "./components/home/navbar";
import Gigseekerupload from "./components/gigseeker/gigseekeruplod";
import Gigseekerotherdetails from "./components/gigseeker/gigseekerotherdetails"
import QualificationsPage from "./components/home/qualificationsPage"
import ExperienceDetails from "./components/home/experienceDetails"
import CertificationsDetails from "./components/home/certificationsDetails"
import AwardsDetails from "./components/home/awardsDetails"
import BankDetailsPage from "./components/home/bankDetailsPage"
import JobListing from "./components/home/jobListing"
import TimeSheet from "./components/home/timeSheet";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CommonLogin from "./components/home/commonLogin";
import GigProviderSignUp from "./components/gigprovider/gigprovidersignup"
import GigProviderOtherDetails from "./components/gigprovider/gigproviderotherdetails";
import GigSeekerSearchJobListing from "./components/gigseeker/gigseekerserachjoblisting";
import GigProviderSearchJobListing from "./components/gigprovider/gigproviderserachjoblisting";
import Admin from "../src/components/admin/admin"
import AboutUs from "./components/otherInformation/aboutUs";
import TermsAndCondtions from "./components/otherInformation/termsAndConditions";
import RefundAndCancellations from "./components/otherInformation/refundsAndCancellations";
import PrivacyPolicy from "./components/otherInformation/privacyPolicy";
import ServiceRequests from "./components/otherInformation/serviceRequests";
import ContactUs from "./components/otherInformation/contactUs";
import GigProviderJobPosts from "./components/gigprovider/gigproviderjobposts";
import GigProviderSearchApplicantsByJobId from "./components/gigprovider/gigprovidersearchapplicantsbyjobid";
import SeekerList from "./components/admin/seekersList";
import ProviderList from "./components/admin/providersList";
import Placements from "./components/admin/placements";
import PasswordRule from "./components/otherInformation/passwordRule"
import GigSeekerSearchApplicationsJobListing from "./components/gigseeker/gigseekersearchapplicationslisting"
import CommonLoginWithPhoneNumer from "./components/home/commonLoginWithPhoneNumber";
function App() {
  return (
    <div>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Navbar} />
            <Route path="/signup" component={CommonSignUp} />
            <Route path="/login" component={CommonLogin} />
            <Route path="/loginWithPhone" component={CommonLoginWithPhoneNumer}/>
            <Route path="/gigseekersignup" component={Gigseekersignup} />
            <Route path="/gigseekersignup/" exact component={Gigseekersignup }/>
            <Route path="/gigseekerupload/" exact component={Gigseekerupload }/>
            <Route path="/gigseekerotherdetails/" exact component={Gigseekerotherdetails }/>
            <Route path="/qualificationsPage/" exact component={QualificationsPage }/>
            <Route path="/experienceDetails/" exact component={ExperienceDetails }/>
            <Route path="/certificationsDetails/" exact component={CertificationsDetails }/>
            <Route path="/awardsDetails/" exact component={AwardsDetails }/>
            <Route path = "/jobListing" exact component = {AwardsDetails} />
            <Route path="/seekersearchjobListing" exact component={GigSeekerSearchJobListing }/>
            <Route path="/seekersearchApplicationsListing" exact component={GigSeekerSearchApplicationsJobListing }/>
            <Route path="/providersearchjobListing" exact component={GigProviderSearchJobListing }/>
            <Route path="/providersearchjobListing/:jobId" exact component={GigProviderSearchApplicantsByJobId }/>
            <Route path="/gigproviderjobposts" exact component={GigProviderJobPosts }/>
            <Route path="/bankDetailsPage" exact component={BankDetailsPage }/>
            <Route path="/gigprovidersignUp/:type" exact component={GigProviderSignUp}/>
            <Route path="/gigproviderotherdetails/:type" exact component={GigProviderOtherDetails }/>
            <Route path="/admin" exact component={Admin }/>
            <Route path="/admin/SeekerList" exact component={SeekerList }/>
            <Route path="/admin/ProviderList" exact component={ProviderList }/>
            <Route path="/admin/Placements" exact component={Placements }/>
            <Route path="/aboutUs" exact component={AboutUs }/>
            <Route path="/termsAndConditions" exact component={TermsAndCondtions }/>
            <Route path="/refundAndCancellations" exact component={RefundAndCancellations }/>
            <Route path="/privacyPolicy" exact component={PrivacyPolicy }/>
            <Route path="/services" exact component={ServiceRequests }/>
            <Route path="/passwordPolicy" exact component={PasswordRule }/>
            <Route path="/contactUs" exact component={ContactUs }/>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
