import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect } from "react";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
}));
function AboutUs() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="About Us"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    About Gig Jobs
                  </p>
                  <br />
                  We are a group of IT and human resources industry professionals that have got
together to establish a comprehensive platform for gig job seekers and employers
worldwide. This platform enables both applicants and businesses to locate verified
and pre-screened individuals and opportunities with a short turnaround time and
hassle-free compliance and administration.  <br /><br />
This platform will contribute to developing an ecosystem of flexible job searchers
from varied locations and cultures, enabling them to interact and create creative
solutions for gig providers. Nowadays, companies are looking for people who can
work quickly while still delivering high-quality solutions. Global corporations and
start-ups alike are conducting talent searches for short or long-term contracts to find
these people.  <br /><br />
GigJobs is an incredible community of knowledgeable, motivated, and happy
individuals who all share a desire for work flexibility—not only for ourselves but also
for the millions of others who want and need employment that suits their life better!
As we assist companies with recruitment efforts and job seekers with their job
searches and careers, we need to maintain our fundamental value of honesty
throughout our work.  <br /><br />
The GigJobs platform is accessible as a mobile application and a website with
comparable capabilities; it will be tightly connected with various payment gateways.
These will be automated using advanced AI, analytics, and RPA abilities.
We open doors for anybody on the planet to establish their business, brand, or
dream.  <br />
                </p>
              </Typography>
            </Paper>
          </Grid>
          <Grid container>
            <Grid item xs={5} style={{marginLeft:30}}>
              <Paper elevation={5}>
                <Typography style={{ margin: 10 }}>
                  <p className="Join-a-Global-networ">
                    <p className={classes.pageTitle}>
                      <br /> For Gig Seekers{" "}
                    </p>
                    <br />Are you tired of searching for a better employment opportunity? Then you&#39;ve come to
the right spot. Fill in your data, and we&#39;ll take care of matching your profile with the
most acceptable options that fit your qualifications. You are only a single step away.
Allow us to act as a bridge between your present and future.
                  </p>
                </Typography>
                <Grid container>
                  <Grid item sm={4} />
                  <div>
                    <Link style={{ textDecoration: "none" }} to={"/signup"}>
                      <Controls.Button text="Join Us" />
                    </Link>
                  </div>

                </Grid>
                <br/>
              </Paper>
            </Grid>
            
            <Grid item sm={1}/>
            <Grid item sm={5}>
              <Paper elevation={5}>
                <Typography style={{ margin: 10 }}>
                  <p className="Join-a-Global-networ">
                    <p className={classes.pageTitle}>
                      <br />
                      For Gig Providers
                    </p>{" "}
                    <br />
                    We will be looking for an exceptional individual that is competent to meet your
requirements and works with dedication. GigJobs is a platform that links you to a
diverse range of extraordinary talent. You are on the verge of taking the next step.
Let us be a link between your present and future.
                  </p>
                </Typography>
                <Grid container>
                  <Grid item sm={4} />
                  <div>
                    <Link style={{ textDecoration: "none" }} to={"/signup"}>
                      <Controls.Button text="Join Us" />
                    </Link>
                  </div>
                </Grid>
                <br/>   
              </Paper>
            </Grid>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default AboutUs;
