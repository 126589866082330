import React, { useState, useEffect } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  Paper,
  Button,
  ThemeProvider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MuiPhoneNumber from "material-ui-phone-number";
import { backendUrl } from "../config";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Controls from "../resusable/controls/Controls";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import PageHeader from "../resusable/pageHeader";
import { useHistory } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import SendIcon from "@mui/icons-material/Send";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import InputAdornment from "@material-ui/core/InputAdornment";
import firebase from "firebase/compat/app";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Typography from "@material-ui/core/Typography";
//import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  textFeild: {
    margin: "12px !important",
  },
}));
const genderList = ["Male", "Female", "Other", ""];
const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

function Gigseekersignup() {
  const location = useLocation();
  let email = localStorage.getItem("emailId"); //location.pathname.substring(17, location.pathname.length);
  //let  {email}  = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };

  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    otp: "",
    emailVerified: "",
    otpVerified: "",
  });

  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const loadUser = () => {
    //let emailId ={email} ; //props.emailid;
    // if(!isCurrentURL("/gigSeekerLogin"))
    // emailId=localStorage.getItem('emailId');

    axios
      .get(
        `${backendUrl}/jobseekers/` + email
        //,{headers: {"AccessToken": localStorage.getItem("token")}}
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (
            response.data.user != undefined &&
            response.data.user[0] != undefined
          ) {
            setUserData(response.data.user[0]);
            localStorage.setItem("emailId", response.data.user[0].email);
            localStorage.setItem("_id", response.data.user[0]._id);
            localStorage.setItem("profile", "seeker");
            localStorage.setItem("name", response.data.user[0].firstName);
            //setgigSeekerId(response.data.user[0]._id);
            setLoginData({
              snackbarOpen: true,
              snackbarMsg: "Info: Profile load Successful",
            });
          } else {
          }
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };

  const handlesubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    if (localStorage.getItem("emailVerified") === "yes") {
      userData.emailVerified = "true";
    }
    if (true) {
      axios.post(`${backendUrl}/jobseekers`, userData).then(
        (response) => {
          setCircularProgressHidden(false);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Submision Successful!",
          });
          history.push("/gigseekerupload/");
          axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
            (response) => {
              if (response.data.user[0] != undefined)
                setUserData(response.data.user[0]);
            },
            (error) => {
              console.log("error" + error);
            }
          );
        },
        (error) => {
          setCircularProgressHidden(false);
          console.log("error" + error);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Error submitting data please try again.",
          });
        }
      );
    }
  };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          console.log("Captcha Resolved");
          onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    setUpRecaptcha();
    let phoneNumber = userData.phoneNo;
    let appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        firebase
        .auth()
        .currentUser.linkWithPhoneNumber(userData.phoneNo, window.recaptchaVerifier)
        .then(function (confirmationResult) {
            window.confirmationResult = confirmationResult;
          }).catch(function (error) {
        })
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // console.log(confirmationResult);
      
      })
      .catch(function (error) {
        console.log(error);
        localStorage.setItem("phoneVerified", "notnow");
        alert(" " + error);
      });
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    let otpInput = userData.otp;
    let optConfirm = window.confirmationResult;
    // console.log(codee);
    if (optConfirm != undefined)
      optConfirm
        .confirm(otpInput)
        .then(function (result) {
          // User signed in successfully.
          // console.log("Result" + result.verificationID);
          let user = result.user;
          localStorage.setItem("phoneVerified", "yes");
          userData.otpVerified = "true";
          setLoginData({
            snackbarMsg: "Valid Otp Entered.",
          });
        })
        .catch(function (error) {
          //console.log(error);
          //alert("Incorrect OTP");
          localStorage.setItem("otpsent", "No");
          userData.otpVerified = "false";
          setLoginData({
            snackbarMsg: error+" please try again.",
          });
        });
    else alert("Invalid steps.");
  };

  useEffect(() => {
    loadUser();
  }, []);
  const formRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <div>
        <form
          ref={formRef}
          id="seekerForm"
          onSubmit={handlesubmit}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <MainHeader></MainHeader>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid sm={12}>
                    <div hidden={CircularProgressHidden}>
                      <CircularProgress hidden={CircularProgressHidden} />
                    </div>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={5000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
                    <br />
                    <PageHeader
                      title="Personal Details"
                      subTitle="Create a full profile to increase job oppurtunities"
                      progressBarValue={10}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Grid item sm={12} name="personal_details">
                      <div style={{ textAlign: "center" }}>
                        <Paper>
                          <Grid container>
                            <Grid item sm={12}>
                              <br/>
                              {/* <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  defaultValue="seeker"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="seeker"
                                    control={<Radio />}
                                    label="Iam a Seeker"
                                  ></FormControlLabel>
                                  <FormControlLabel
                                    value="provider"
                                    control={<Radio />}
                                    label="Iam a Provider"
                                  />
                                </RadioGroup>
                                <br />
                              </FormControl> */}
                            </Grid>
                            <Grid item sm={5}>
                              <TextField
                                className={classes.textFeild}
                                placeholder="First Name"
                                label="First Name"
                                variant="outlined"
                                required
                                fullWidth
                                type="text"
                                disabled="true"
                                value={userData.firstName}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  value = value.replace(/[^\w\s]/gi, "");
                                  setUserData({
                                    ...userData,
                                    firstName: value,
                                  });
                                }}
                              />
                              <TextField
                                className={classes.textFeild}
                                label="Email"
                                placeholder="Enter Email"
                                variant="outlined"
                                type="email"
                                required
                                disabled="true"
                                fullWidth
                                value={userData.email}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    email: e.target.value,
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton aria-label="toggle password visibility">
                                        {localStorage.getItem(
                                          "emailVerified"
                                        ) === "yes" ? (
                                          <VerifiedIcon
                                            onClick={() => {
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg: "Email Verified.",
                                              });
                                            }}
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <VerifiedIcon
                                            onClick={() => {
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg:
                                                  "Email Not Verified. Please verify and sign in again to update!",
                                              });
                                            }}
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <MuiPhoneNumber
                                className={classes.textFeild}
                                label="Phone number"
                                placeholder="Enter phone number"
                                variant="outlined"
                                required
                                fullWidth
                                defaultCountry={"in"}
                                value={userData.phoneNo}
                                onChange={(value) => {
                                  setUserData({
                                    ...userData,
                                    phoneNo: value,
                                  });
                                }}
                                onKeyDown={() => {
                                  if (
                                    userData.phoneNo[1] === "9" &&
                                    userData.phoneNo[2] === "1" &&
                                    userData.phoneNo.length < 10
                                  )
                                    setLoginData({
                                      snackbarOpen: true,
                                      snackbarMsg:
                                        "Error:please enter 10 digit phone number.",
                                    });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton aria-label="toggle password visibility">
                                        {localStorage.getItem(
                                          "phoneVerified"
                                        ) === "yes" || userData.otpVerified=="true"? (
                                          <VerifiedIcon
                                            onClick={() => {
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg:
                                                  "Phone Number Verified.",
                                              });
                                            }}
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <VerifiedIcon
                                            onClick={(e) => {
                                              setSubmitOpen(true);
                                              setLoginData({
                                                snackbarOpen: true,
                                                snackbarMsg:
                                                  "Phone Number Not Verified. Please verify and sign in again to update!",
                                              });
                                            }}
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <div id="recaptcha-container"></div>
                              <Dialog
                                onClose={handleSubmitClose}
                                open={SubmitOpen}
                                style={{
                                  border: "1px solid",
                                  borderColor: "black",
                                }}
                              >
                                <DialogTitle
                                  id="simple-dialog-title"
                                  style={{ background: "white" }}
                                >
                                  <Grid item xs={12}>
                                    <Grid container name="Submit">
                                      <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                          <CreditScoreIcon></CreditScoreIcon>
                                          <br />
                                          Phone Validation!
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </DialogTitle>
                                <Paper style={{ background: "white" }}>
                                  <Grid
                                    container
                                    name="Submit"
                                    className="GridMarginStyle"
                                  >
                                    <Grid item xs={2} />
                                    <Grid item xs={8}>
                                      <Typography align="center">
                                        You are going to validate phone number.
                                        Click on Confirm to continue.
                                        <br />
                                        <br />
                                        <br />
                                      </Typography>
                                      <Grid item xs={12}>
                                        <Controls.Button
                                          text="Confirm "
                                          onClick={(e) => {
                                            setSubmitOpen(false);
                                            localStorage.setItem(
                                              "phoneVerified",
                                              "now"
                                            );
                                            onSignInSubmit(e);
                                          }}
                                        />
                                        <div className={classes.submitBtn}>
                                          <Controls.Button
                                            text="Cancel"
                                            onClick={handleSubmitClose}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <br />
                                  </Grid>
                                  <br />
                                </Paper>
                              </Dialog>

                              <div
                                hidden={
                                  localStorage.getItem("phoneVerified") !==
                                  "now"
                                }
                              >
                                <TextField
                                  className={classes.textFeild}
                                  placeholder="Otp"
                                  label="Otp"
                                  variant="outlined"
                                  fullWidth
                                  type="text"
                                  value={userData.otp}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setUserData({
                                      ...userData,
                                      otp: value,
                                    });
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility">
                                          {localStorage.getItem("otpsent") ===
                                          "yes" ? (
                                            <SendIcon
                                              onClick={() => {
                                                setLoginData({
                                                  snackbarOpen: true,
                                                  snackbarMsg:
                                                    "Phone Number Otp.",
                                                });
                                              }}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <SendIcon
                                              onClick={(e) => {
                                                onSubmitOtp(e);
                                                localStorage.setItem(
                                                  "otpsent",
                                                  "yes"
                                                );
                                                setLoginData({
                                                  snackbarOpen: true,
                                                  snackbarMsg:
                                                    "Phone Number Otp is being Verified. Please sign in again to update!",
                                                });
                                              }}
                                              style={{ color: "Blue" }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <TextField
                                className={classes.textFeild}
                                label="Date Of Birth"
                                variant="outlined"
                                required
                                fullWidth
                                type="date"
                                format={'DD/MM/YYYY'}
                                InputLabelProps={{ shrink: true }}
                                value={userData.dateOfBirth}
                                onChange={(e) => {
                                  let today = new Date();
                                  let month =
                                    today.getMonth() + 1 < 10
                                      ? "0" + (today.getMonth() + 1)
                                      : today.getMonth() + 1;
                                  let dayDate =
                                    today.getDate() < 10
                                      ? "0" + today.getDate()
                                      : today.getDate();
                                  let toDayDate =
                                    today.getFullYear() +
                                    "-" +
                                    month +
                                    "-" +
                                    dayDate;

                                  let birthDate = new Date(e.target.value);
                                  let age = today.getFullYear() - birthDate.getFullYear();
                                  let m = today.getMonth() - birthDate.getMonth();
                                  let da = today.getDate() - birthDate.getDate();
                                  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                                      age--;
                                  }
                                  if(m<0){
                                      m +=12;
                                  }
                                  if(da<0){
                                      da +=30;
                                  }
                                  if(age > 18 && age < 100){
                                    setUserData({
                                      ...userData,
                                      dateOfBirth: e.target.value,
                                    });
                                  } else {
                                    setLoginData({
                                      snackbarOpen: true,
                                      snackbarMsg:
                                        "Error: Age should be greater than 18 years and valid, future dates are not valid, please try again.",
                                    });
                                    setUserData({
                                      ...userData,
                                      dateOfBirth: "",
                                    });
                                  }
                                }}
                              />

                              {/* <TextField
                              className={classes.textFeild}
                              // label="Gender"
                              // placeholder="Gender"
                              // variant="outlined"
                              // value={userData.gender}
                              // required
                              // fullWidth
                            // /> */}
                              <Autocomplete
                                id="genderComboBox"
                                options={genderList}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    className={classes.textFeild}
                                    {...params}
                                    label="Gender"
                                    variant="outlined"
                                    required
                                  />
                                )}
                                value={userData.gender}
                                onChange={(e, value) =>
                                  setUserData({
                                    ...userData,
                                    gender: value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid Item sm={1}></Grid>
                            <Grid item sm={5}>
                              <TextField
                                className={classes.textFeild}
                                placeholder="Last name"
                                label="Last name"
                                variant="outlined"
                                required
                                disabled="true"
                                fullWidth
                                type="text"
                                value={userData.lastName}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  value = value.replace(/[^\w\s]/gi, "");
                                  setUserData({
                                    ...userData,
                                    lastName: value,
                                  });
                                }}
                              />

                              {/* <TextField
                                className={classes.textFeild}
                                label="Password"
                                placeholder="Enter Password"
                                variant="outlined"
                                required
                                fullWidth
                                disabled="true"
                                type="password"
                                type={showPassword ? "text" : "password"}
                                value={userData.password}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    password: e.target.value,
                                  })
                                }
                              /> */}
                              <Autocomplete
                                id="Nationality"
                                options={countryList}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    className={classes.textFeild}
                                    {...params}
                                    label="Nationality"
                                    variant="outlined"
                                    required
                                  />
                                )}
                                value={userData.Nationality}
                                onChange={(e, value) =>
                                  setUserData({
                                    ...userData,
                                    Nationality: value,
                                  })
                                }
                              />
                              <MuiPhoneNumber
                                className={classes.textFeild}
                                label="Alternate Phone number"
                                placeholder="Enter alternate phone number"
                                fullWidth
                                variant="outlined"
                                defaultCountry={"in"}
                                value={userData.alternatePhoneNo}
                                onChange={(value) =>
                                  setUserData({
                                    ...userData,
                                    alternatePhoneNo: value,
                                  })
                                }
                                onKeyDown={() => {
                                  if (
                                    userData.alternatePhoneNo[1] === "9" &&
                                    userData.alternatePhoneNo[2] === "1" &&
                                    userData.alternatePhoneNo.length < 10
                                  )
                                    setLoginData({
                                      snackbarOpen: true,
                                      snackbarMsg:
                                        "Error:please enter 10 digit phone number.",
                                    });
                                }}
                              />

                              <TextField
                                className={classes.textFeild}
                                label="Address"
                                placeholder="Adress"
                                required
                                fullWidth
                                variant="outlined"
                                value={userData.address}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    address: e.target.value,
                                  })
                                }
                              />
                              <TextField
                                className={classes.textFeild}
                                label="Zipcode"
                                placeholder="Enter zipcode"
                                variant="outlined"
                                required
                                fullWidth
                                value={userData.zip}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    zip: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          {/* <Grid item sm={2}>
                            <div class="Join-for-FreeRectangle">
                              <Button>
                                <p className="Join-for-Free">Skip</p>
                              </Button>
                            </div>
                            <br />
                          </Grid> */}
                          <Grid item sm={6}></Grid>
                          <Grid item sm={12}>
                            <div>
                              <Controls.Button
                                text="Skip"
                                onClick={() => {
                                  history.push("/gigseekerupload/");
                                }}
                              />

                              {/* <Link to="/gigseekerupload"> */}
                              <Controls.Button
                                type="submit"
                                text="Save and Continue"
                                className={classes.submitBtn}
                                onClick={() => formRef.current.reportValidity()}
                              />
                              {/* </Link> */}
                            </div>
                            {/* <div class="Join-for-FreeRectangle">
                              <Button
                                type="submit"
                                onClick={() => formRef.current.reportValidity()}
                              >
                                <p className="Join-for-Free">
                                  Save and Continue
                                </p>
                              </Button>
                              <Button>
                                <Link
                                  to={"/gigseekerupload"}
                                  style={{ textDecoration: "none" }}
                                  className="Join-for-Free"
                                >
                                  <p className="Join-for-Free">{"=>"}</p>
                                </Link>
                              </Button>
                            </div> */}
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </form>
      </div>
    </>
  );
}

export default Gigseekersignup;