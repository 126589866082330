import React from 'react'
import { Paper, Grid, makeStyles, Typography, } from '@material-ui/core'
import LogoImage from "../resources/logo@3x.png";
import AccountMenu from './AccountMenu';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ffffff',
        margin: '0',
        padding: '0px 0px 0px 45px'
    },
    mainHeader:{
        padding:theme.spacing(4),
        display:'flex',
        marginBottom:theme.spacing(2),
        color: '#34495e'
    },
    pageTitle: {
        //  paddingLeft:theme.spacing(4),
    
        fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
        margin: "0",
        "& .MuiTypography-subtitle2": {
          opacity: "0.6",
        },
      },
}))

export default function MainHeader(props) {

    const classes = useStyles();
    //const { title, subTitle, icon,name } = props;
    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Paper square className={classes.root}>
                <Grid container>
                    <Grid item style={{marginTop:10}} sm={3}>
                        <a href="/">
                            <img className="Logo" src={LogoImage} />
                        </a>
                    </Grid>
                    <Grid item sm={7}/>
                    <Grid item sm={1}><div  className={classes.pageTitle} style={{marginTop:30}}><Typography variant="subtitle2">Hi { localStorage.getItem("name")}!</Typography></div></Grid>
                    <Grid item sm={1}><AccountMenu/></Grid>
                    <br/>
                    </Grid>
                </Paper>              
            </Grid>
        </Grid>
    )
}
