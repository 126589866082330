import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {useEffect } from "react";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
  pageTitleLeft: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "left",
  },
}));
function ContactUs() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="Contact Us"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    Contact Us
                  </p>
                  <br />
                  Plot. No 494/A, Road No. 22, Jubilee Hills, Hyderabad - 500033
                  <br />
                  <br />
                  Mobile/Whatsapp : <Link to="/contactUs">+91 98492 56818  <WhatsAppIcon/></Link>
                </p>
              </Typography>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    Gig Jobs
                  </p>
                  <br />
                  NBOS Technologies, Road Number 22,
                  <br />
                  <br /> Jubilee Hills, Hyderabad Telangana, India
                </p>
              </Typography>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    Hours
                  </p>
                  <br />
                  Monday - Sunday: 9am - 9pm
                </p>
              </Typography>
              <br />
            </Paper>
            <br />
            <Grid container>
              <Grid item sm={5} />
              <div>
                <Link style={{ textDecoration: "none" }} to={"/signup"}>
                  <Controls.Button text="Join Us" />
                </Link>
              </div>
            </Grid>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default ContactUs;
