import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect} from 'react'
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
}));
function PrivacyPolicy() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="Privacy Policy"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    1. Introduction
                  </p>
                  <br />
We, GigJobs India Private Limited (&quot;GigJobs&quot; or &quot;GigJobs&quot;), are committed to
safeguarding your online privacy and ensuring that any personally identifiable
information you provide to us is protected and maintained correctly. GigJobs
provides this Privacy Policy to inform you of our policies and procedures regarding
collecting, using, and disclosing the information collected through this &quot;Platform&quot; and
any other websites, features, mobile applications, widgets, or online services owned
or controlled by GigJobs. The Private Policy further informs you of the inclusion of a
link to this Privacy policy (collectively, the &quot;Service&quot;), as well as any information
GigJobs that collects offline in connection with the Service. It also explains your
options for using, accessing, and updating your personal data.

The terms &quot;You&quot; or &quot;Your&quot; refer to the individual/visitor/subscriber accessing the
Platform. Please remember that we combine the information we gather about you via
the site, the Service in general, and offline.

Please keep in mind that not all of the features or services mentioned in this Privacy
Policy may be available on the Service at all times.

Additionally, please examine our Terms &amp; Conditions, which govern your use of the
Service and are accessible online.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    2. Types of Personal Information collected by the Gigjobs
                  </p>
                  <br />
You may give us information about yourself when you use the Service. This
information may include your name and contact information, financial information
necessary to make or receive payments for services provided through the GigJobs
Platform, and information necessary to assist us in filling out tax forms. We may also
collect information about your usage of the Service and combine it with information
about other users. This information enables us to enhance our Services for you
continuously.
Personal Data: During your usage of the Service (as an employer or Gigster), we
may need or collect information that uniquely identifies you as an individual, and
GigJobs may use that to contact or identify you (&quot;Personal Information&quot;).
<br/>
Personal Information is gathered in the following ways:
<br/>A. When you sign up for notifications on the Platform, we collect your name, email
address, password, country, city, contact number, and GigJobs / organization with
which you are involved;
<br/>B. When you register on the Platform, you will provide information such as your
name, contact information (including your email address and mobile phone number),
job experience, educational credentials, information about your current and previous
income or salary, and a copy of your CV.
<br/>C. Information about the services you use and how you use them, including log
information and location data, if you are a Platform user;
<br/>D. We may collect Personal Information about you, such as your name, age, contact
information, and preferences, via surveys and forms if you choose to participate in
these surveys and forms.
<br/>E. If you want to offer it, we may also collect information on your caste and your
eligibility for affirmative action programs or policies.
<br/>F. When you connect with GigJobs or use the Platform to communicate with other
Users (such as partners), we collect information about your conversation and any
additional information you give;
<br/>G. When you visit the Platform, we use cookies to collect, store, and use technical
information about your system and your interactions with the Platform.
<br/>H. When you access the Platform through a device, GigJobs may collect technical or
other device-related information, as well as the device&#39;s location;
To the extent as authorized by law, GigJobs may record and monitor your
conversations with us to ensure that we adhere to our legal and regulatory duties
and internal rules. It may involve the recording of telephone calls;
<br/>J. If you choose to sign in with your social media account to access the Platform or
otherwise connect your social media account to the Platform&#39;s services, you consent
to our collecting, storing and using the information you make available to us through
the social media interface in accordance with this Privacy Policy. Please consult your
social media provider&#39;s privacy statement and help center for further information on
how they share information when you join your account.
Payment Information: If you use the Service to make or receive payments, we will
additionally collect some payment information from you, such as your credit card or
other bank account number and billing address.
We may gather Personal Information about you, such as your date of birth or
taxpayer identification number, to verify your identity or, as needed by law, complete
tax filings. We may require documentation to verify this information, such as a copy
of your government-issued identity or photo identification, as well as a billing
statement.
<br/>Biometric Identifiers or Service Interaction Information: Through the use of facial
recognition and other technologies, we may collect Personal Information such as a
photograph of your face, a selfie, or data about your interactions with the Service to
verify your identity and detect fraud, identity theft, or other misuses of your account.
We may require documentation to substantiate this information, such as a copy of
your government-issued identity. GigJobs may occasionally request that you capture
and submit an additional or updated photograph of your face compared to your
government-issued identity. The biometric identifiers or information obtained are
used solely to verify identity and ensure the Platform&#39;s security and usage integrity.
Service Designed for a General Audience: The Service is intended for users aged
18 and older. We do not intentionally collect personally identifiable information from
children under 18. Suppose we become aware that a child under the age of 18 has
supplied us with Personal Information. In that case, we shall make commercially
reasonable measures to ensure that the information is deleted from our files. If you
are the parent or legal guardian of a child under the age of 18 and feel that GigJobs
has obtained Personal Information on your child, please get in touch with us at the
following address: [*]
<br/>Additionally, we may collect non-identifying information about you, such as zip
codes, demographic data, information about your usage of the Service, and general
project-related data (&quot;Non-Identifying Information&quot;). We may combine information
obtained from registered and non-registered users of GigJobs (&quot;Users&quot;).
 </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    3. How does GigJobs India Pvt. Ltd. intend to utilize your personal data?</p>
                  <br />
      
We will only collect, use, and disclose your personal information fairly and
reasonably and for a lawful purpose. Our usage of your personal information is
contingent upon the reason for your interaction with us. We may process your
personal data for the following reasons:

<br/>● To operate and enhance the Service, process your transactions, respond to
your inquiries, process your registration, verify the accuracy of the information
you submit, and for compliance and internal business purposes.

<br/>● To communicate with you on administrative matters and GigJobs newsletters,
marketing or promotional materials (on behalf of GigJobs or third parties), and
other information that may interest you.

<br/>● To manage and expand our commercial relationship with you and, if
appropriate, the company or other legal entity on whose behalf you act.

<br/>● We use cookies and other forms of technology to improve your user
experience and the general quality of our services. When you get customized
adverts, we do not associate a cookie or similar technology&#39;s identifier with
sensitive categories such as race, religion, sexual orientation, or health.

<br/>● To improve the Platform&#39;s and its content&#39;s user experience to expand its
features and services.

<br/>● To accomplish market research and conduct surveys to improve our services.

<br/>● To prevent, detect, investigate, and prosecute crimes (including but not limited
to fraud and other financial crimes), as well as any other unlawful acts,
alleged fraud, or breaches of GigJobs&#39;s Terms of Service in any jurisdiction.

<br/>● To the extent necessary for government sanctions&#39; identification, screening,
and due diligence. Establishment, exercise or defence of legal rights in
connection with legal proceedings and obtaining professional or legal
assistance in connection with legal procedures (including future proceedings).
             </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          {/* <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    4. Information Collected Automatically
                  </p>
                  <br />
                  Like other online companies, we receive technical information
                  when you use our Services. We use these technologies to
                  analyze how people use the Service, to improve how our Site
                  functions, to save your log-in information for future
                  sessions, and to serve you with advertisements that may
                  interest you. We and our third party service providers,
                  including analytics and third party content providers, may
                  automatically collect certain information from you whenever
                  you access or interact with the Service. This information may
                  include, among other information, the browser and operating
                  system you are using, the URL or advertisement that referred
                  you to the Service, the search terms you entered into a search
                  engine that led you to the Service, areas within the Service
                  that you visited, which links you clicked on, which pages or
                  content you viewed and for how long, other similar information
                  and statistics about your interactions, such as content
                  response times, download errors and length of visits to
                  certain pages and other information commonly shared when
                  browsers communicate with websites. We may combine this
                  automatically collected log information with other information
                  we collect about you. We do this to improve services we offer
                  you, and to improve marketing, analytics, and site
                  functionality. The information we collect also includes the
                  Internet Protocol (“IP”) address or other unique device
                  identifier (“Device Identifier”) for any device (computer,
                  mobile phone, tablet, etc.) used to access the Service. A
                  Device Identifier is a number that is automatically assigned
                  or connected to the device you use to access the Service, and
                  our servers identify your device by its Device Identifier.
                  Some mobile service providers may also provide us or our third
                  party service providers with information regarding the
                  physical location of the device used to access the Service.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid> */}

          {/* <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    5. Cookies and Other Tracking Technologies
                  </p>
                  <br />
                  The Gigjobs through platform use “cookies” and other
                  technology for tracking. A “cookie” is a tiny text file which
                  may be used to gather, for instance, website activity
                  information. Some cookies and other technologies may be used
                  to recall previously identified user information. Most
                  browsers enable you to manage cookies, whether you accept them
                  or not and how you may delete them. You may set most browsers
                  to notify you if you receive a cookie, or you may choose to
                  block cookies with your browser, but please note that if you
                  choose to erase or block your cookies, you will need to
                  re-enter your original user ID and password to gain access to
                  certain parts of the Platform. Information such as internet
                  domain and hosts may be stored by tracking technologies;
                  internet protocol (IP) addresses; browser and operating system
                  kinds; clickstream patterns; dates and times our site can be
                  visited. Our usage of cookies and other tracking technologies
                  enables us to enhance our Platform and our overall experience
                  with the website. We may also analyse information that does
                  not include personal data for trends and statistics.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    6. The Basis/Grounds which we rely on for collection and
                    processing of your Personal Information
                  </p>
                  <br />
                  Your Personal Information is collected and processed by the
                  Gigjobs based on the following legal grounds depending upon
                  the nature of Personal Information and the purposes for which
                  it is processed.  Consent: The Gigjobs relies on your consent
                  in order to process your Personal Information in certain
                  situations. If Gigjobs requires your consent to collect and
                  process certain Personal Information, as per the requirements
                  under the applicable data protection laws, your consent is
                  sought at the time of collection of your Personal Information
                  and such processing will only be performed where consent is
                  secured.  Compliance with a legal obligation: Your Personal
                  Information may be processed by the Gigjobs, to the extent
                  that such processing is necessary to allow the Gigjobs to
                  comply with a legal obligation. An example of this would be if
                  Gigjobs is required to disclose your Personal Information to
                  respond to a court order or if Gigjobs is required to retain
                  specific records for a fixed period to comply with
                  requirements under any applicable law.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    7. Information Sharing and Disclosure
                  </p>
                  <br />
                  We restrict access to your Personal Information to employees
                  who we believe reasonably need to know/or that information to
                  fulfil their jobs to provide, operate, develop, or improve our
                  products or services. We may share information about you to
                  provide the Services, for legal and investigative purposes, or
                  with Gigjobs does not disclose, transfer or share your
                  Personal Information with others except with: Potential
                  clients if we determine that your resume matches a particular
                  job description/vacancy available with such clients. By
                  registering on the Platform and consenting to the terms of
                  this Privacy Policy, you agree that the Gigjobs may contact
                  you or forward your resume to potential clients; Third parties
                  including enforcement, regulatory and judicial authorities, if
                  we determine that disclosure of your Personal Information is
                  required to a) respond to subpoenas, court orders, or legal
                  process, or to establish or exercise our legal rights or
                  defend against legal claims; or b) investigate, prevent, or
                  take action regarding illegal activities, suspected fraud,
                  situations involving potential threats to the physical safety
                  of any person, violations of Gigjobs’s Terms & Conditions or
                  as otherwise required by law; In the event of a merger,
                  acquisition, financing, or sale of assets or any other
                  situation involving the transfer of some or all of Gigjobs’s
                  business assets we may disclose Personal Information to those
                  involved in the negotiation or transfer. Third party service
                  providers and marketing partners that the Gigjobs engages to
                  a) provide services over the Platform on Gigjobs’s behalf; b)
                  maintain the Platform and mailing lists; or c) communicate
                  with you on Gigjobs’s behalf about offers relating to its
                  products and/or services. The Gigjobs will take reasonable
                  steps to ensure that these third-party service providers are
                  obligated to protect your Personal Information and are also
                  subject to appropriate confidentiality/non-disclosure
                  obligations. The Gigjobs does not provide any Personal
                  Information to the advertiser when you interact with or view a
                  targeted advertisement. However, if you interact with or view
                  an advertisement, the advertiser may make certain assumptions
                  and, in the process, learn certain Personal Information about
                  you. For instance, if you view and click an advertisement that
                  is targeted towards women in the age group 18- 24 from a
                  specific geographic area, the advertiser may assume that you
                  meet the relevant criteria. Gigjobs does not intend to
                  transfer Personal Information without your consent to third
                  parties who are not bound to act on Gigjobs's behalf unless
                  such transfer is legally required. If your Personal
                  Information is transferred outside India, we take the
                  necessary steps to protect your Personal Information in
                  accordance with applicable data protection laws.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    8. Retention of Personal Information
                  </p>
                  <br />
                  Your Personal Information will not be retained by the Gigjobs
                  any longer than it is necessary for the purposes for which the
                  Personal Information is processed and/or in accordance with
                  legal, regulatory, contractual or statutory obligations as
                  applicable. At the expiry of such periods, your Personal
                  Information will be deleted or archived in compliance with
                  applicable laws
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    9. Controlling your personal information
                  </p>
                  <br />
                  You have the right to invoke your rights which are available
                  to data principals or data subjects (as per applicable laws
                  and regulations) in relation to your Personal Information
                  which is being processed by the Gigjobs. Gigjobs provides you
                  the ability to keep your Personal Information accurate and
                  up-todate. If at any time you would like to a) rectify, update
                  or correct your Personal Information; b) obtain confirmation
                  on whether or not your Personal Information is processed by
                  it; c) access your Personal Information or exercise your right
                  to data portability; or d) exercise your right to restrict the
                  continuing disclosure of your Personal Information to any
                  third party by the Gigjobs in certain circumstances, you are
                  requested to contact us using the contact details mentions in
                  paragraph [*] below. We will require you to provide a valid
                  proof of your identity, in order to ensure that your rights
                  are respected. For the exercise of certain rights, you may be
                  required to approach the relevant authority/designated officer
                  as per the provisions of the applicable data protection
                  laws/Gigjobs may in accordance with the provisions of
                  applicable data protection laws, charge a fee for fulfilling
                  your request, in particular in case of excessive or manifestly
                  unfounded request. Further you acknowledge that the
                  abovementioned rights are not absolute and are subject to
                  limitations as per the applicable data protection laws.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    10. Confidentiality and Security
                  </p>
                  <br />
                  The security and confidentiality of your Personal Information
                  is important to us and the Gigjobs has invested significant
                  resources to protect the safekeeping and confidentiality of
                  your personal data. When using external service providers
                  acting as processors, we require that they adhere to the same
                  standards as Gigjobs does. Regardless of where your Personal
                  Information is transferred or stored, we take all steps
                  reasonably necessary to ensure that personal data is kept
                  secure. We seek to ensure compliance with the requirements of
                  the Information Technology Act, 2000 and Rules made there
                  under to ensure the protection and preservation of your
                  privacy. We have physical, electronic, and procedural
                  safeguards that comply with the laws prevalent in India to
                  protect your Personal Information. By accepting the terms of
                  this Privacy Policy, you agree that the standards and
                  practices being implemented by us, are reasonable and
                  sufficient for the protection of your Personal Information.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    11. Social media
                  </p>
                  <br />
                  Gigjobs operates channels, pages and accounts on some social
                  media sites to inform, assist and engage with customers.
                  Gigjobs monitors and records comments and posts made on these
                  channels about it in order to improve its products and
                  services. Please note that you must not communicate the
                  following information to the Gigjobs through such social media
                  sites: - sensitive personal data including (i) special
                  categories of personal data meaning any information revealing
                  racial or ethnic origin, political opinions, religious or
                  philosophical beliefs, or trade union membership, and the
                  processing of genetic data, biometric data for the purpose of
                  uniquely identifying a natural person, data concerning health
                  or data concerning a natural person's sex life or sexual
                  orientation and (ii) other sensitive personal data such as
                  criminal convictions and offences and national identification
                  number ; - Excessive, inappropriate, offensive or defamatory
                  content. Gigjobs is not responsible for any information posted
                  on those sites other than the information posted by its
                  employees on its behalf. Gigjobs is only responsible for its
                  own use of the Personal Information received through such
                  sites.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    12. Links to other sites
                  </p>
                  <br />
                  Our Service contains links to other websites. If you choose to
                  click on a third-party link, you will be directed to that
                  third party’s website. The fact that we link to a website is
                  not an endorsement, authorization, or representation of our
                  affiliation with that third party, nor is it an endorsement of
                  their privacy or information security policies or practices.
                  We do not exercise control over third party websites. These
                  other websites may place their own cookies or other files on
                  your computer, collect data or solicit Personal Information
                  from you. We encourage you to read the privacy policies or
                  statements of the other websites you visit.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    13. Testimonials
                  </p>
                  <br />
                  We display personal testimonials of satisfied customers on our
                  Service, in addition to other endorsements. With your consent
                  we may post your testimonial along with your name. If you wish
                  to update or delete your testimonial, you can contact us at
                  (*)
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    14. Public Profile
                  </p>
                  <br />
                  The profile you create on our Site will be publicly accessible
                  unless otherwise indicated. You may change the privacy
                  settings of your profile through your account portal.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    15. Changes to this Privacy Policy
                  </p>
                  <br />
                  Gigjobs reserves the right to update, change or modify this
                  Privacy Policy at any time. The Privacy Policy shall come to
                  effect from the date of publication of such update, change or
                  modification. If Gigjobs make significant changes to the
                  privacy policy, Gigjobs. Gigjobs shall post a prominent
                  message on our websites.
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    16. Disclaimer
                  </p>
                  <br />
                  Gigjobs will store any account related information or any
                  credit/debit card details for monthly subscription, making any
                  kind payments. Gigjobs shall not be liable for any loss or
                  damage sustained by Users as a result of any disclosure
                  (inadvertent or otherwise) of any information concerning the
                  User's account, credit cards or debit cards in the course of
                  any online transactions or payments made for any products
                  and/or services offered through the Platform. In case any
                  Personal Information is shared by you with the Gigjobs, which
                  is not requested by the Gigjobs during registration, (whether
                  mandatorily or optionally), Gigjobs will not be liable for any
                  information security breach or disclosure in relation to such
                  information. If you have any questions regarding this Privacy
                  Policy or the protection of your Personal Information, please
                  contact Gigjobs’s Data Protection Officer/ Grievance Officer
                  at the following:
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    17. Data Protection Officer/ Grievance Officer
                  </p>
                  <br />
                  In case you have any complaints and/or grievances in relation
                  to the processing of your Personal Information you can send
                  your complaints via e-mail to our grievance officer: Grievance
                  Officer Name: Gigjobs India Private Limited Email:
                </p>
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    Files coming soon...
                  </p>
                </p>
              </Typography>
              <br />
            </Paper>
            <br />
          </Grid> */}
          <Grid container>
            <Grid item sm={5} />
            <div>
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Controls.Button text="Agree & Join Us" />
              </Link>
            </div>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default PrivacyPolicy;
