import { Grid, Container, Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import SocialFollow from "./socialFollow";

const useStyles = makeStyles((theme) => ({
  root: {
    Link: {
      fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    },
  },
}));
export default function Footer(props) {
  return (
    <Box
      px={{ xs: 3, sm: 10 }}
      py={{ xs: 5, sm: 10 }}
      bgcolor="#34495e"
      color="#fff"
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={2}>
            <Box>General</Box>
            <br />
            <Box>
              <Link
                to={"/aboutUs"}
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                About us <br /> <br />
              </Link>
            </Box>
            {/* <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Service <br /> <br />
              </Link>
            </Box> */}
            <Box>
              {" "}
              <Link
                to="/contactUs"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Contact us <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/privacyPolicy"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Privacy Policy <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
               to={"/services"}
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Services <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to={"/termsAndConditions"}
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Terms and Conditions <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
              to={"/refundAndCancellations"}
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Refunds/Cancellations <br /> <br />
              </Link>
            </Box>
             
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box>Jobs by Skills</Box>
            <br />
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Dotnet Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Ajax Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Android Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Java Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Marketing <br /> <br />
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Sales Jobs <br /> <br />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box></Box>
            <br />
            <br />
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Finance Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                UX/UI JObs <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                HR Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                React Jobs <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Angular Jobs <br /> <br />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box></Box>
            <br />
            <br />

            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Talent <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Marketing <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Sales <br /> <br />
              </Link>
            </Box>
            <Box>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
                Learning <br /> <br />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box></Box>
            <Box>Follow us on</Box>
            <br />
            <SocialFollow/>
            {/* <Box>
              <FacebookIcon />
              &nbsp;&nbsp;&nbsp;
              <TwitterIcon />
              &nbsp;&nbsp;&nbsp;
              <InstagramIcon />
              &nbsp;&nbsp;&nbsp;
              <YouTubeIcon />
            </Box> */}
            <br />
            <br />

            <Box>Write to us</Box>

            <Box>
              {" "}
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "#ffffff",
                  opacity: "0.5",
                }}
              >
              support@gigjobs.info <br /> <br />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} >
              <div style ={{textAlign: "center" }}>
            © 2021 Gigjobs. All rights reserved
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
