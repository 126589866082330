import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import ArrowForwardIosSharp from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Controls from "../resusable/controls/Controls";
import { useForm, Form } from "../resusable/useForm";
import Footer from "../resusable/footer";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";
import { backendUrl } from "../config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridImage1 from "../resources/providerAdvt.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  //width: "50%",
  //margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  uploadInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    marginTop: "15px !important",
    borderRadius: "4px",
    "&.input": {
      padding: "18.5px 14px",
    },
  },

  "&.MuiButton-contained": {
    color: "#f3670d !important",
  },
  textFeild: {
    marginLeft: "15px !important",
    fontFamily: "calibri",
  },
}));

const skills = [
  "ReactJs ",
  "Python",
  "Java",
  "JavaScript",
  "C#",
  "C",
  "C++",
  "PHP",
  "R",
  "Objective-C",
  "Swift",
  "TypeScript",
  "MATLAB",
  "Kotlin",
  "Go(Golang)",
  "VBA",
  "Ruby",
  "Scala",
  "Visual Basic",
  "Rust",
  "Dart",
  "Ada",
  "Lua",
  "Abap",
  "Groovy",
  "Perl",
  "Cobol",
  "Julia	Haskell	Delphi",
  "Elm",
  "PowerShell",
  "SQL",
  "Clojure",
  "Elixir",
  "Pascal",
  "LISP",
  "Ballerina",
  "FORTRAN",
  "BASIC",
  "Alice",
  "COBOL",
  "Speakeasy",
  "Simula",
  "Smalltalk",
  "Prolog",
  "Erlang",
  "Ada",
  "Eiffel",
"Rebol",
  "Scratch",
  "Shell Scripting",
    "MySQL",
    "Magento",
    "CodeIgniter",
    "AngularJS",
    "Linux",
    "json",
    "RESTful WebServices",
    "Moodle",
    "WordPress",
    "Angular Material",
    "PostgreSQL",
    "Django",
    "mysql",
    "Jenkins",
    "docker",
    "Git",
    "MongoDB",
    "Zend Framework",
    "Gitlab",
    "API Development",
    "Web Services API",
    "Google Maps API",
    "Google API",
    "DevOps",
    "Zend",
    "X-Cart",
    "Web Services",
    "jQuery",
    "Pycharm",
    "Ionic Framework",
    "Cordova",
    "Apache Cordova",
    "Google Cloud Platform (GCP)",
    "Express.js",
    "React.js",
    "Flask",
    "Django",
    "Pandas",
    "Anaconda",
    "PySpark",
    "Jenkins",
    "Sonarqube",
    "ASP.NET",
    "HTML5",
    "HTML Scripting",
    "HTML",
    "XHTML",
    "Cascading Style Sheet (CSS)",
    "CSS Sprites",
    "CSS Flexbox",
    "Bootstrap",
];
const GigProviderOtherDetails = () => {
  let emailId = localStorage.getItem("emailId");
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const [postOfferData, setPostOfferData] = useState({
    _id: "",
    //bankName: "",
    gigTitle: "",
    //email: "",
    //password: "",
    //company: "",
    gigType: "",
    description: "",
    skillsRequired: [],
    startDate: "",
    endDate: "",
    hourlyRate: "",
    rateOnSkills: "",
    assesment: "",
  });

  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitOpen = () => {
    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };
  const loadUser = () => {
    // if(!isCurrentURL("/gigProviderLogin"))
    // emailId=localStorage.getItem('emailId');
    axios
      .get(
        `${backendUrl}/jobs/` + emailId + "/" + localStorage.getItem("jobId")
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (response.data.jobs[0] != undefined) {
            setPostOfferData(response.data.jobs[0]);
            //setGigProviderId(response.data.user[0]._id);
            //   setLoginData({
            //     snackbarOpen: true,
            //     snackbarMsg: props.isPswdReset?"Info : Reset Successful! Click on 'Search Gig Seekers' to find new seekers":"Info : Profile load Successful! Click on 'Search Gig Seekers' to find new seekers",
            //   });
          } else
            setPostOfferData({
              //bankName: "",
              gigTitle: "",
              //email: "",
              //password: "",
              //company: "",
              gigType: "",
              description: "",
              skillsRequired: [],
              startDate: "",
              endDate: "",
              hourlyRate: "",
              rateOnSkills: "",
              assesment: "",
            });
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };
  useEffect(() => {
    loadUser();
  }, []);
  const formRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const handleSubmit = (e) => {
    axios.post(`${backendUrl}/jobs`, postOfferData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Submision Successful!",
        });
        setSubmitOpen(true);
        axios.get(`${backendUrl}/jobs/` + postOfferData.providerEmail).then(
          (response) => {
            if (response.data.jobs[0] != undefined)
              setPostOfferData(response.data.jobs[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        console.log("error" + error);
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <PaperDetails>
        <div hidden={CircularProgressHidden}>
          <CircularProgress hidden={CircularProgressHidden} />
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginData.snackbarOpen}
          autoHideDuration={5000}
          message={loginData.snackbarMsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={snackBarCloseEvent}
            >
              x
            </IconButton>,
          ]}
          onClose={() => setLoginData({ snackbarOpen: false })}
        />
        <Dialog
          onClose={handleSubmitClose}
          open={SubmitOpen}
          style={{ border: "1px solid", borderColor: "black" }}
        >
          <DialogTitle id="simple-dialog-title" style={{ background: "white" }}>
            <Grid item xs={12}>
              <Grid container name="Submit">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    className={classes.pageTitle}
                    align="center"
                  >
                    <ThumbUpAltIcon></ThumbUpAltIcon>
                    <br />
                    Awesome!
                  </Typography>
                </Grid>
                {/* <Grid item xs={1}>
                            <IconButton
                              aria-label="close"
                              onClick={handleSubmitClose}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid> */}
              </Grid>
            </Grid>
          </DialogTitle>
          <Paper style={{ background: "white" }}>
            <Grid container name="Submit" className="GridMarginStyle">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Typography align="center">
                  Your Gig requirement is successfully posted. Happy Searching.
                  <br />
                  <br />
                  <br />
                </Typography>
                <Grid item xs={12}>
                  <Controls.Button
                    text="Find Gigs"
                    onClick={() => {
                      history.push("/providersearchjobListing");
                    }}
                  />
                  <Controls.Button
                    type="submit"
                    text="Cancel"
                    onClick={handleSubmitClose}
                    //style={{ marginLeft: 50}}
                    className={classes.submitBtn}
                  />
                </Grid>
              </Grid>

              <Grid item xs={2} />
              <br />
            </Grid>
            <br />
          </Paper>
        </Dialog>

        <Grid container>
          <Grid item sm={6}>
            <img className="Gridimage1" src={GridImage1} />
          </Grid>

          <Grid item sm={6}>
            <div style={{ marginLeft: 5 }}>
              <PageHeader
                title="Add Additional Details"
                progressBarValue={60}
              />
            </div>
            <Grid item sm={12} name="upload_attachments">
              <Paper style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid container>
                  <Grid item sm={10}>
                    <p className="TitleStyle">Required Skills</p>
                    <Autocomplete
                      multiple
                      id="secondarySkills"
                      options={skills}
                      value={postOfferData.skillsRequired}
                      onChange={(e, values) =>
                        setPostOfferData({
                          ...postOfferData,
                          skillsRequired: values,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          className={classes.textFeild}
                          fullWidth
                          {...params}
                          variant="outlined"
                          placeholder="Skills Required"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <p className="TitleStyle">Hourly Rate</p>
                    <Grid container>
                      <Grid item sm={10}>
                        <TextField
                          className={classes.textFeild}
                          variant="outlined"
                          placeholder="Eg. if 1000 Rs/hr enter 1000 here"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={postOfferData.hourlyRate}
                          onChange={(e) => {
                            let value = e.target.value;
                            const re = /^[0-9\b]+$/;
                            if(value==""||re.test(value))
                            setPostOfferData({
                              ...postOfferData,
                              hourlyRate: value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <p className="TitleStyle">Assesment Link</p>
                    <Grid container>
                      <Grid item sm={10}>
                        <TextField
                          className={classes.textFeild}
                          variant="outlined"
                          placeholder="Eg. https:/hackerrank.level1/assignment.in"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={postOfferData.assesment}
                          onChange={(e) => {
                            let value = e.target.value;
                            setPostOfferData({
                              ...postOfferData,
                              assesment: value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <p className="TitleStyle">
                        Estimated Project Deliverables
                      </p>
                      <Grid container>
                        <Grid item xs={5} style={{ marginLeft: "15px" }}>
                          <TextField
                            //className={classes.textFeild}
                            variant="outlined"
                            name="startDate"
                            label="Start Date"
                            value={
                              postOfferData.startDate != ""
                                ? postOfferData.startDate
                                : new Date()
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="date"
                            format={'DD/MM/YYYY'}
                            onChange={(e) => {
                              let value = e.target.value;
                              setPostOfferData({
                                ...postOfferData,
                                startDate: value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            className={classes.textFeild}
                            variant="outlined"
                            name="endDate"
                            label="End Date"
                            value={
                              postOfferData.endDate != ""
                                ? postOfferData.endDate
                                : new Date()
                            }
                            fullWidth
                            type="date"
                            format={'DD/MM/YYYY'}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              let startDateValue=postOfferData.startDate;
                              let value=e.target.value;
                              if(e.target.value <= startDateValue || startDateValue===''){
                                setLoginData({
                                  snackbarOpen: true,
                                  snackbarMsg: "Error: End date should be greater than Start date, please check and try again.",
                                });
                                value="";
                              }
                              if(value!="")
                              setPostOfferData({
                                ...postOfferData,
                                endDate: value,
                              });
                            }}
                            />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <br />
            <Grid container>
              <Grid item xs={12}>
                <div>
                  <Link style={{ textDecoration: "none" }} to={"/"}>
                    <Controls.Button disabled={true} text="Skip" />
                  </Link>
                  <Controls.Button
                    type="submit"
                    text="Save and Continue"
                    className={classes.submitBtn}
                    onClick={handleSubmit}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperDetails>
      <Footer></Footer>
    </>
  );
};

export default GigProviderOtherDetails;
