import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect} from 'react'

const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
  pageTitleLeft: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "left",
  },
}));
function TermsAndCondtions() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="T&C's"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    Terms and conditions
                  </p>
                  <br />
               
GigJobs India Private Limited (hereafter referred to as &quot;GigJobs&quot; or the &quot;Company&quot;) is
created as a platform, where freelancers and clients connect in order to conduct
business.

These Terms and Conditions for Website Use tells you about the terms on which you
may use our website [*] (“Site”), whether as registered user or guest.
By using the Site, you accept these Terms and Conditions and agree to obey them. If
you don't accept them, please don't use the site.
We may amend these Terms from time to time and you should check the Terms each
time you use the Site.
The Site may contain links to third party websites, these links are provided solely as
convenience to You and the presence of these links should not under any
circumstances be considered as an endorsement of the contents of the same, if You
chose to access these websites you do so at your own risk.
                </p>
              </Typography>

              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    Communications
                  </p>
                  <br />
                  By creating an Account on our Site, you agree to subscribe to newsletters,
marketing or promotional materials and other information we may send. However,
you may opt out of receiving any, or all, of these communications from us by
following the unsubscribe link or by emailing at support@gigjobs.info
                </p>
              </Typography>

              <br />
            </Paper>
          </Grid>

         <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    Accounts
                  </p>
                  <br />
                  When you create an account with us, you guarantee that you are above the age of
18, and that the information you provide us is accurate, complete, and current at
all times. Inaccurate, incomplete, or obsolete information may result in the
immediate termination of your account on Service.
You are responsible for maintaining the confidentiality of your account and
password, including but not limited to the restriction of access to your computer
and/or account. You agree to accept responsibility for any and all activities or
actions that occur under your account and/or password, whether your password
is with our Service or a third-party service. You must notify us immediately upon
becoming aware of any breach of security or unauthorized use of your account.
You may not use as a username the name of another person or entity or that is
not lawfully available for use, a name or trademark that is subject to any rights of
another person or entity other than you, without appropriate authorization. You
may not use as a username any name that is offensive, vulgar or obscene.
We reserve the right to refuse service, terminate accounts, remove or edit content,
or cancel orders in our sole discretion.
User means the person accessing/visiting/subscribing the Platform for the
purposes of securing job or hiring eligible candidate.
When using this platform, you are obliged to give only accurate and correct
information and to maintain information up to date at all times when establishing
a profile. Gigjobs India Pvt. Ltd will not be responsible because the information
included on this website is inaccurate. It is the visitor's duty to further investigate
the material on the site. Gigjobs India Pvt. Ltd. is not responsible for any violation
of privacy or information supplied by the consumer to be put on the site by means
of technological or other methods. Gigjobs India Pvt. Ltd. Gigjobs India Pvt. Ltd.
does not guarantee confidentiality of the information supplied by any individual
that acquires/uses all/info shown in or from the Gigjobs India Pvt. Ltd website or
any of Gigjobs India Pvt. Ltd's other websites / domains.
<br/> Without consent except as our clients, Gigjobs India Pvt. Ltd. does not disclose any
personal data of any individual or corporate with any businesses/entities. Gigjobs
India Pvt. Ltd. shares any information it possesses as a result of legal proceedings,
such as a court order or summons. The user shall not use Gigjobs services in any
way in order to damage the interests and operation of Gigjobs. Unless expressly
allowed Gigjobs in that respect, the user agrees not to reproduce, download, alter
and distribute content on Gigjobs.
<br/> For its own objectives, the user agrees to utilize Gigjobs. It is absolutely forbidden
to use material from Gigjobs for derivatives works for commercial reasons without
the prior written permission of Gigjobs.
<br/> Users agree that the services provided by shall not be used to upload, send,
transmit, or otherwise make any requested bulk or unsolicited commercial e-mail
accessible directly or indirectly. Reserves the right to filter, monitor and prohibit emails sent by the user using e-mail servers. All efforts must be taken by and the
user to follow International Best Practices for Spam Containment and Disposal.
<br/> Users may not spam on the platform managed by /Gigjobs or post jobs and send
mail indiscriminately and frequently, etc. Any behaviour of the User in breach of
this paragraph will entitle Gigjobs to immediately stop all services to the User and
to forfeit any monies paid by the User.
<br/> The user shall not upload, post, transmit, publish, or distribute any material or
information that is unlawful, or which may potentially be perceived as being
harmful, threatening, abusive, harassing, defamatory, libelous, vulgar, obscene, or
racially, ethnically, or otherwise objectionable.
<br/> The user explicitly declares that the user's resume, insert, or provide the
information/data in the network is accurate in all aspects and does not include
any incorrect, misrepresented, deformed, fraudulent, or misleading facts or
warnings. Gigjobs India Pvt. Ltd disclaims any responsibility for the
insertion/information/data of such resume into the user's Gigjobs India Pvt. Ltd
network. Furthermore, the User undertakes to indemnify Gigjobs India Pvt. Ltd. for
any losses of any wrong, distortioned, manipulated, libelous, vulgar, obscene,
fraudulent and misleading information or otherwise objectionable averments made
by the user on the Site.
3
<br/> User is solely responsible for keeping the User Password and User Identification
confidential and all activity and transmission carried out by the User using his or
her User ID and is solely responsible for any on-line or off-line transaction
involving credit card and Debit Cards or other forms of instrument or documents
for making such transactions and
<br/> The user to [Site link] and/or its affiliated websites agree hereby specifically that,
while they evaluate or feed any curriculum or information in the Site, they shall
comply at all times with the requirements of the Act of Information Technology
2000 as well as the rules, regulations, guidelines, bye law or notifications made
thereon The user to [website] and/or its affiliated websites further unambiguously
declares that he is liable for all actions, acts and acts and actions of the user and
that he is solely liable for civil and/or non-civil information technology in the event
he violates the provisions of the Information Technology Act 2000 and/or the rules
or rules, directives, by stander ships and notifications made on them. 
                </p>
              </Typography>

              <br />
            </Paper>
          </Grid>
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    The User Represents Warrants and Covenants that its use of
                    site:
                  </p>
                  <br />
                  You may use Service only for lawful purposes and in accordance with Terms. You
agree not to use Service:
a. In any way that violates any applicable national or international law or
regulation.
b. For the purpose of exploiting, harming, or attempting to exploit or harm
minors in any way by exposing them to inappropriate content or otherwise.
c. To transmit, or procure the sending of, any advertising or promotional
material, including any “junk mail”, “chain letter,” “spam,” or any other
similar solicitation.
d. To impersonate or attempt to impersonate Company, a Company employee,
another user, or any other person or entity.
e. In any way that infringes upon the rights of others, or in any way is illegal,
threatening, fraudulent, or harmful, or in connection with any unlawful,
illegal, fraudulent, or harmful purpose or activity.
f. To engage in any other conduct that restricts or inhibits anyone's use or
enjoyment of Service, or which, as determined by us, may harm or offend
Company or users of Service or expose them to liability.
g. Additionally, you agree not to:
i. Use Service in any manner that could disable, overburden, damage, or
impair Service or interfere with any other party's use of Service,
including their ability to engage in real time activities through Service.
ii. Use any robot, spider, or other automatic device, process, or means to
access Service for any purpose, including monitoring or copying any of
the material on Service.
iii. Use any manual process to monitor or copy any of the material on
Service or for any other unauthorized purpose without our prior written
consent.
iv. Use any device, software, or routine that interferes with the proper
working of Service.
v. Introduce any viruses, trojan horses, worms, logic bombs, or other
material which is malicious or technologically harmful.
vi. Attempt to gain unauthorized access to, interfere with, damage, or
disrupt any parts of Service, the server on which Service is stored, or
any server, computer, or database connected to Service.
vii. Attack Service via a denial-of-service attack or a distributed denial-ofservice attack.
viii. Take any action that may damage or falsify Company rating.
ix. Otherwise attempt to interfere with the proper working of Service
                </p>
              </Typography>

              <br />
            </Paper>
          </Grid>

          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitle}>
                    {" "}
                    <br />
                    No Use by Minors
                  </p>
                  <br />
                  Service is intended only for access and use by individuals at least eighteen (18)
years old. By accessing or using any of Company, you warrant and represent that
you are at least eighteen (18) years of age and with the full authority, right, and
capacity to enter into this agreement and abide by all the terms and conditions of
Terms. If you are not at least eighteen (18) years old, you are prohibited from both
the access and usage of Service.
<br/> The user shall not infringe on any intellectual property rights of any person / entity
or retain information/download any information from any computer system or
otherwise with an intention to do so.
<br/> Gigjobs India Pvt. Ltd. will make best efforts to do so but does not warrant that any
of the web sites or any affiliate site(s) or network system linked to it is free of any
operational errors nor does it warrant that it will be free of any virus, computer
contaminant, worm, or other harmful components. The subscription of a user shall
be subject to Quotas as applicable and as advised. E-Mails provided as part of
contact details are expected to be genuine and access to such email accounts is
available to authorised personnel only.
<br/> Gigjobs India Pvt. Ltd. shall not be liable for any loss or damage sustained by
reason of any disclosure (inadvertent or otherwise) of any information concerning
the user's account and/or information relating to or regarding online transactions
using credit cards/debit cards and/or their verification process and particulars nor
for any error, omission or inaccuracy with respect to any information so disclosed
and used whether or not in pursuance of a legal process or otherwise.
<br/> Payments for the services offered by Gigjobs shall be on a 100% advance basis.
Refund if any will be at the sole discretion of Gigjobs. Gigjobs offers no guarantees
whatsoever for the accuracy or timeliness of the refunds reaching the Customers
card/bank accounts. Gigjobs gives no guarantees of server uptime or applications
working properly. All is on a best effort basis and liability is limited to refund of
amount only. Gigjobs undertakes no liability for free services. Gigjobs reserves its
right to amend/alter or change all or any disclaimers or terms of agreements at
any time without any prior notice. All terms/disclaimers whether specifically
mentioned or not shall be deemed to be included if any reference is made to them.
<br/> Unless otherwise specified and notwithstanding anything contained in any other
agreement or arrangement, by whatever name called, the performance obligation of
Gigjobs (service provider) is to provide access of its online portal to the customer
for the duration of the subscription period & reference to any usage, by whatever
5
name called or any other performance obligation, if any, is to provide the upper
limit for consumption, which by itself, does not create any additional performance
obligation upon Gigjobs.
<br/> Freelancers and Clients acknowledges and agrees that Gigjobs India Pvt. Ltd. at its
sole discretion and without prejudice to other rights and remedies that it may have
under the applicable laws, shall be entitled to set off the amount paid or payable by
a user against any amount(s) payable by Subscriber/user to Gigjobs under any
other agreement or commercial relationship towards other products/services.
<br/> Gigjobs India Pvt. Ltd. further reserves its right to post the data on the website [*]
or on such other affiliated sites and publications as Gigjobs India Pvt. Ltd. may
deem fit and proper at no extra cost to the subscriber/user.
<br/> Any agreement for a usage entered into by Gigjobs does not confer exclusivity of
service on any user.
<br/> Gigjobs India Ltd will not be party to any legal proceedings between a user (e.g. a
subscriber) and a party contracted through the site. In case Gigjobs India Pvt. Ltd.
is implicated in any legal proceedings, costs will be recovered from the party that
names Gigjobs India Pvt. Ltd. Gigjobs however will abide with any court order
served on it through due process. Gigjobs controls and operates this Platform from
its headquarters in Noida and makes no representation that the materials on
[website] are appropriate or available for use in other locations. If you use this
Website from other locations, you are responsible for compliance with applicable
local laws including but not limited to the export and import regulations of other
countries.
<br/> In case a person using the world wide web/internet receives a spam or virus which
includes a link to [website] or to any other site maintained, operated or owned by
Gigjobs, it should not be held responsible for the same. Gigjobs assumes no
responsibility for such mails.
<br/> The services provided by the websites maintained, operated or owned by Gigjobs do
not extend to acting as an agent (express or implied) on behalf of any subscriber or
user.
<br/> Gigjobs has no agents and does not operate through any agents save for those
specifically mentioned on the home page of the website.
<br/> This Agreement shall be subject to all the laws, rules, regulations and such other
statutory enactment or amendment or modification or any approval of the
Government of India that is in force from time to time.
<br/> In no event shall the Company be individually liable to the users for any damages
for the breach of fiduciary duty by third parties, unless the Company’s act or
failure to act involves intentional misconduct, fraud, or a knowing violation of the
law.
6
<br/> Notwithstanding anything written herein to the contrary, the users and the
Company acknowledge and agree that the Company will not be liable for any losses
or damages, whether indirect, incidental, special, or consequential, in profits,
goods or services, irrespective of whether or not the users has been advised or
otherwise might have anticipated the possibility of such loss or damage.
<br/> Each party shall indemnify the other party against all liabilities, costs, expenses,
damages, and losses suffered or incurred by the other party arising out of or in
connection with any death, personal injury or damage to property arising out of, or
in connection with the acts or omissions of the first party, its employees, agents or
subcontractors.
<br/> This Agreement may be supplemented, amended, or modified only by the Company
at any time. Your continued use of the website or any services after the posting of
any amended Clause of this Agreement shall constitute your agreement to be
bound by any such changes. The Company shall not be liable to any user or any
third party should Gigjobs exercise its right to modify or discontinue service
<br/> In case of any dispute arising between the parties. The parties shall first attempt to
resolve any dispute arising out of or in connection with the Company by
negotiation. If the parties are unable to resolve such a dispute within thirty (30)
days of commencing negotiations, such a dispute may, upon the application of
either party, be referred to and finally resolved by arbitration under the Indian
Arbitration and Conciliation Act, 1996 The seat, or legal place, of the arbitration
shall be [*]. The language to be used in the arbitration shall be English.
<br/> The Terms and Conditions mentioned above regulate the usage of [website]. Any
person using [website] in violation of the stipulations contained in the Terms and
Conditions of [website] shall render himself/herself liable to appropriate action in a
court of law both civil and criminal.

These Terms and Conditions shall be governed by the laws of India. The exclusive
forum for any disputes arising out of or relating to these Terms & Conditions shall be
a court of law located in Hyderabad, India.
The user of [website] is subject to the Privacy Policy available through this link [*].
In case of non-compliance of these Terms and Conditions or Privacy Policy, Gigjobs
may terminate usage rights and take down any offending information that might have
been uploaded by such user
                </p>
              </Typography>

              <br />
            </Paper>
          </Grid>
                  <br />
          <Grid container>
            <Grid item sm={5} /> 

            <div>
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Controls.Button text="Agree & Join Us" />
              </Link>
            </div>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default TermsAndCondtions;
