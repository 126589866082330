import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import './fonts/Poppins-Bold.ttf'; 
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  typography : {
    fontFamily: ["Poppins-Bold",'Open Sans'].join(',')
  }
})

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      
      <App/>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);


