import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
    boxShadow: "none",
  },
  pageHeader: {
    padding: theme.spacing(0, 0, 4, 0),
    display: "flex",
    color: "#34495e",
  },
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

export default function PageHeader(props) {
  const classes = useStyles();
  const { title, subTitle, icon, progressBarValue } = props;
  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <div className={classes.pageHeader}>
            <div className={classes.pageTitle}>
              <Typography variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="subtitle2" component="div">
                {subTitle}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={1} sm={1} hidden={progressBarValue===undefined}>
           <CircularProgressbar  value={progressBarValue} text={`${progressBarValue}%`} />
        </Grid>
      </Grid>
    </Paper>
  );
}
