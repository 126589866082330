import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect} from 'react'
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
  pageTitleLeft: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "left",
  },
}));
function RefundAndCancellations() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="RETURN AND REFUND POLICY"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper elevation={5}>
              <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                  <p className={classes.pageTitleLeft}>
                    {" "}
                    <br />
                    Refund Policy for Employers:
                  </p>
                  <br />
                  1.Within 21 days of the date of any advance payment, if the
                  employer is dissatisfied with the demo module of one hour's
                  work.
                  <br />
                  <br />
                  2. Refunds will be made in whole or in part, based on the
                  approval of the work.
                  <br />
                  <br />
                  3.Only if the work quality is not as agreed and delivered as
                  per global industry standards.
                  <br />
                  <br />
                  4.A Refund will be initiated and paid to the same account used
                  to make the payment.
                  <br />
                  <br />
                  5. To get further information please contact us at{" "}
                  <Link to="/refundAndCancellations">
                    payments@gigjobs.info
                  </Link>
                </p>
              </Typography>
              <br />
            </Paper>
            <br />
          </Grid>
          <Grid container>
            <Grid item sm={5} />

            <div>
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Controls.Button text="Agree & Join Us" />
              </Link>
            </div>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default RefundAndCancellations;
