import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import ArrowForwardIosSharp from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Controls from "../resusable/controls/Controls";
import { useForm, Form } from "../resusable/useForm";
import Footer from "../resusable/footer";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";
import { backendUrl } from "../config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridImage1 from "../resources/providerAdvt.png";
const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  //width: "50%",
  //margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  "&.MuiButton-contained": {
    color: "#f3670d !important",
  },
  textFeild: {
    marginLeft: "15px !important",
  },
}));
const GigProviderSignUp = () => {
  let emailId = localStorage.getItem("emailId");
  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const [postOfferData, setPostOfferData] = useState({
    _id: "",
    // email: "",
    // password: "",
    company: "",
    // firstName:"",
    // lastName:"",
    gigTitle: "",
    gigType: "",
    description: "",
    skillsRequired: [],
    startDate: "",
    endDate: "",
    hourlyRate: "",
    rateOnSkills: "",
    assesment: "",
    providerId: "",
    providerEmail: "",
  });

  const loadUser = () => {
    // if(!isCurrentURL("/gigProviderLogin"))
    // emailId=localStorage.getItem('emailId');
    // if(!isNew)
    axios.get(`${backendUrl}/jobproviders/` + emailId).then(
      (response) => {
        setCircularProgressHidden(true);
        //console.log(props+"---", response);
        if (response.data.user[0] != undefined) {
          // setPostOfferData(response.data.user[0]);
          localStorage.setItem("emailId", response.data.user[0].email);
          localStorage.setItem("_id", response.data.user[0]._id);
          localStorage.setItem("profile", "provider");
          localStorage.setItem("name", response.data.user[0].firstName);

          axios
            .get(
              `${backendUrl}/jobs/` +
                emailId +
                "/" +
                localStorage.getItem("jobId")
            )
            .then((response) => {
              if (response.data.jobs[0] != undefined)
                setPostOfferData(response.data.jobs[0]);
              else
                setPostOfferData({
                  //email: "",
                  //password: "",
                  company: "",
                  // firstName:"",
                  // lastName:"",
                  gigTitle: "",
                  gigType: "",
                  description: "",
                  skillsRequired: [],
                  startDate: "",
                  endDate: "",
                  hourlyRate: "",
                  rateOnSkills: "",
                  assesment: "",
                  providerId: localStorage.getItem("_id"),
                  providerEmail: localStorage.getItem("emailId"),
                });
            });

          //setGigProviderId(response.data.user[0]._id);
          //   setLoginData({
          //     snackbarOpen: true,
          //     snackbarMsg: props.isPswdReset?"Info : Reset Successful! Click on 'Search Gig Seekers' to find new seekers":"Info : Profile load Successful! Click on 'Search Gig Seekers' to find new seekers",
          //   });
        } else
          setPostOfferData({
            //email: "",
            //password: "",
            company: "",
            // firstName:"",
            // lastName:"",
            gigTitle: "",
            gigType: "",
            description: "",
            skillsRequired: [],
            startDate: "",
            endDate: "",
            hourlyRate: "",
            rateOnSkills: "",
            assesment: "",
            providerId: "",
            providerEmail: "",
          });
      },
      (error) => {
        console.log("error" + error);
      }
    );
    // else
    // setPostOfferData({
    //   //email: "",
    //   //password: "",
    //    company: "",
    //   // firstName:"",
    //   // lastName:"",
    //   gigTitle: "",
    //   gigType: "",
    //   description: "",
    //   skillsRequired: [],
    //   startDate: "",
    //   endDate: "",
    //   hourlyRate: "",
    //   rateOnSkills: "",
    //   assesment: "",
    //   providerId:localStorage.getItem("_id"),
    //   providerEmail:localStorage.getItem("emailId")
    // });
  };
  useEffect(() => {
    loadUser();
  }, []);
  const formRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postOfferData.providerId = localStorage.getItem("_id");
    postOfferData.providerEmail = localStorage.getItem("emailId");
    let _jobId;
    postData(`${backendUrl}/jobs`, { ...postOfferData })
      .then(
        (response) => {
          setCircularProgressHidden(true);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Submision Successful!",
          });
          _jobId = response.data._id;
        },
        (error) => {
          console.log("error" + error);
          setCircularProgressHidden(true);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Error submitting data please try again.",
          });
        }
      )
      .then(() => {
        if (_jobId != undefined) {
          localStorage.setItem("jobId", _jobId);
          history.push(
            "/gigproviderotherdetails/" + "editPost"
          );
        } else {
          console.log("no response id" + _jobId);
        }
      });
  };
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <PaperDetails>
        <div hidden={CircularProgressHidden}>
          <CircularProgress hidden={CircularProgressHidden} />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginData.snackbarOpen}
          autoHideDuration={5000}
          message={loginData.snackbarMsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={snackBarCloseEvent}
            >
              x
            </IconButton>,
          ]}
          onClose={() => setLoginData({ snackbarOpen: false })}
        />
        <Grid container>
          <Grid item sm={6}>
            <img className="Gridimage1" src={GridImage1} />
          </Grid>
          <Grid item sm={6}>
            <div style={{ marginLeft: 5 }}>
              {" "}
              <PageHeader title="Gig Details" progressBarValue={25} />
            </div>

            <Grid item sm={12}>
              <Grid item sm={12} name="upload_attachments">
                <Paper style={{ marginLeft: 10, marginRight: 10 }}>
                  <Grid container>
                    <Grid item sm={12}>
                      <p className="TitleStyle">Title</p>
                      <Grid container>
                        <Grid item sm={10}>
                          <TextField
                            className={classes.textFeild}
                            placeholder="Eg. Softfare Developer,Automation Tester"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={postOfferData.gigTitle}
                            onChange={(e) => {
                              let value = e.target.value;
                              setPostOfferData({
                                ...postOfferData,
                                gigTitle: value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <p className="TitleStyle">Add more about Gig</p>
                      <Grid container>
                        <Grid item sm={10}>
                          <TextField
                            className={classes.textFeild}
                            variant="outlined"
                            multiline
                            rows={4}
                            placeholder="Describe details here..."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={postOfferData.description}
                            onChange={(e) => {
                              let value = e.target.value;
                              setPostOfferData({
                                ...postOfferData,
                                description: value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <p className="TitleStyle">Company</p>
                      <Grid container>
                        <Grid item sm={10}>
                          <TextField
                            className={classes.textFeild}
                            variant="outlined"
                            placeholder="Eg. Gig Jobs pvt ltd."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={postOfferData.company}
                            onChange={(e) => {
                              let value = e.target.value;
                              setPostOfferData({
                                ...postOfferData,
                                company: value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <p className="TitleStyle">Gig Type </p>
                      <div style={{ marginLeft: 20 }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            defaultValue="parttime"
                            value={postOfferData.gigType}
                            name="otherdetailsradiogroup"
                            onChange={(e, value) => {
                              setPostOfferData({
                                ...postOfferData,
                                gigType: "" + value,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="parttime"
                              control={<Radio />}
                              label="Part Time"
                            ></FormControlLabel>
                            <FormControlLabel
                              value="fulltime"
                              control={<Radio />}
                              label="Full Time"
                            />
                            <FormControlLabel
                              value="hourly"
                              control={<Radio />}
                              label="Hourly"
                            />
                            <FormControlLabel
                              value="Seasonal"
                              control={<Radio />}
                              label="Seasonal"
                            />
                          </RadioGroup>
                          <br />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <br />
            <Grid container>
              <Grid item xs={12}>
                <div>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={
                      "/gigproviderotherdetails/" +"editPost"
                     
                    }
                  >
                    <Controls.Button text="Skip" />
                  </Link>
                  <Controls.Button
                    type="submit"
                    text="Save and Continue"
                    className={classes.submitBtn}
                    onClick={handleSubmit}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperDetails>
      <Footer></Footer>
    </>
  );
};

export default GigProviderSignUp;
