import React, { useState } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  TextField,
  Paper,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";
import Snackbar from "@material-ui/core/Snackbar";
import { backendUrl } from "../config";
import Footer from "../resusable/footer";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { signInWithGoogle } from "../firebaseConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createUserWithEmailPswd } from "../firebaseConfig";
import { FacebookLoginButton } from "react-social-login-buttons";
import {GoogleLoginButton} from "react-social-login-buttons"
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Controls from "../resusable/controls/Controls";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  textFeild: {
    margin: "12px !important",
  },
}));

function CommonSignUp() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    emailVerified:"",
    otpVerified:"",
  });
  const [userType, setUserType] = useState("seeker");
  const [postOfferData, setPostOfferData] = useState({
    _id: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",

    //company: "",
    // gigTitle: "",
    // gigType: "",
    // description: "",
    // skillsRequired: [],
    // startDate: "",
    // endDate: "",
    // hourlyRate: "",
    // rateOnSkills: "",
    // assesment: "",
  });

  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const [CircularProgressHidden, setCircularProgressHidden] = useState(true);
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [SubmitOpen, setSubmitOpen] = useState(false);
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };
  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  const checkPswdStrength = (pswd) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(pswd)) {
      return true;
    } else return false;
  };

  const handlesubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    if (checkPswdStrength(userData.password)) {
      createUserWithEmailPswd(userData.email, userData.password)
        .then((result) => {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: result.message,
          });

          userData.password = "*******";
          if (userType === "seeker") {
            postData(`${backendUrl}/jobseekers`, { ...userData }).then(
              (response) => {
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Submision Successful!",
                });

                localStorage.setItem("emailId", response.responseStatus.email);
                localStorage.setItem("_id", response.responseStatus._id);
                localStorage.setItem("profile", "seeker");
                localStorage.setItem("name", response.responseStatus.firstName);
                //alert("---"+ localStorage.getItem("emailId"))
                setSubmitOpen(true);
                //history.push("/gigseekersignup/" + userData.email);
                // axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
                //   (response) => {
                //     if (response.data.user[0] != undefined)
                //       setUserData(response.data.user[0]);
                //   },
                //   (error) => {
                //     console.log("error" + error);
                //   }
                // );
              },
              (error) => {
                setCircularProgressHidden(true);
                console.log("error" + error);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Error submitting data please try again.",
                });
              }
            );
          } else {
            postOfferData.email = userData.email;
            postOfferData.password = userData.password;
            postOfferData.firstName = userData.firstName;
            postOfferData.lastName = userData.lastName;

            postData(`${backendUrl}/jobproviders`, { ...postOfferData })
              .then((response) => {
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Submision Successful!",
                });
                localStorage.setItem("_id", response.data._id);
              })
              .then(() => {
                localStorage.setItem("emailId", userData.email);
                localStorage.setItem("profile", "provider");
                localStorage.setItem("name", userData.firstName);
                setSubmitOpen(true); 
                // history.push("/gigprovidersignup/" + "new");
                // axios
                //   .get(`${backendUrl}/jobproviders/` + postOfferData.email)
                //   .then(
                //     (response) => {
                //       if (response.data.user[0] != undefined)
                //         setPostOfferData(response.data.user[0]);
                //     },
                //     (error) => {
                //       console.log("error" + error);
                //     }
                //   );
              })
              .catch((error) => {
                console.log("error" + error);
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Error submitting data please try again.",
                });
              });
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert("SignUp Error: " + errorCode + "  " + errorMessage);
        });
    } else {
      setCircularProgressHidden(true);
      setLoginData({
        snackbarOpen: true,
        snackbarMsg:
          "Error: Set a strong password with 8 charecters or long with,atleast 1 each of lowercase, upper case,numeric and special characters .",
      });
    }
  };
  const signUpWithGoogleClicked = (e) => {
    setLoginData({
      snackbarOpen: true,
      snackbarMsg:
        "Info: You are signing up as " +
        userType +
        " please recheck and proceed.",
    });

    signInWithGoogle()
      .then((response) => {
        if (response.success) {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: response.msg,
          });
          userData.email = response.userEmail;
          userData.firstName = response.firstName;
          userData.lastName = response.firstName;
          userData.password = "*******";
          if (userType === "seeker") {
            postData(`${backendUrl}/jobseekers`, { ...userData }).then(
              (response) => {
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Submision Successful!",
                });

                localStorage.setItem("emailId", response.responseStatus.email);
                localStorage.setItem("_id", response.responseStatus._id);
                localStorage.setItem("profile", "seeker");
                localStorage.setItem("name", response.responseStatus.firstName);
                //alert("---"+ localStorage.getItem("emailId"))         
                history.push("/gigseekersignup/" + "seeker");
                axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
                  (response) => {
                    if (response.data.user[0] != undefined)
                      setUserData(response.data.user[0]);
                  },
                  (error) => {
                    console.log("error" + error);
                  }
                );
              },
              (error) => {
                setCircularProgressHidden(true);
                console.log("error" + error);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg:
                    "Error submitting data email might be already registered please try again.",
                });
              }
            );
          } else {
            postOfferData.email = userData.email;
            postOfferData.password = userData.password;
            postOfferData.firstName = userData.firstName;
            postOfferData.lastName = userData.lastName;

            postData(`${backendUrl}/jobproviders`, { ...postOfferData })
              .then((response) => {
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Submision Successful!",
                });
                localStorage.setItem("_id", response.data._id);
              })
              .then(() => {
                localStorage.setItem("emailId", userData.email);
                localStorage.setItem("profile", "provider");
                localStorage.setItem("name", userData.firstName);

                history.push("/gigprovidersignup/" + "new");
                axios
                  .get(`${backendUrl}/jobproviders/` + postOfferData.email)
                  .then(
                    (response) => {
                      if (response.data.user[0] != undefined)
                        setPostOfferData(response.data.user[0]);
                    },
                    (error) => {
                      console.log("error" + error);
                    }
                  );
              })
              .catch((error) => {
                console.log("error" + error);
                setCircularProgressHidden(true);
                setLoginData({
                  snackbarOpen: true,
                  snackbarMsg: "Error submitting data please try again.",
                });
              });
          }
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Failed, please try again!" + response.msg,
          });
        }
      })
      .catch((err) => {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Failed, please try again!" + err.msg,
        });
      });
  };

  const formRef = React.useRef();
  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <form
          ref={formRef}
          id="seekerForm"
          onSubmit={handlesubmit}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item sm={2}>
                  <a href="/">
                    <img className="Logo" src={LogoImage} /></a>
                  </Grid>
                  <Grid item sm={7}></Grid>
                  <Grid item sm={1}>
                    <p className="Already-a-member">Already a member?</p>
                  </Grid>
                  <Grid item sm={1}>
                    <div class="Rectangle-Copy">
                      <Link
                        to={"/login"}
                        style={{ textDecoration: "none" }}
                        className="Join"
                      >
                        <Button>
                          <p className="Sign-in">Sign in</p>
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid item sm={12}>
                    <div hidden={CircularProgressHidden}>
                      <CircularProgress hidden={CircularProgressHidden} />
                    </div>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={5000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
                    <p className="Sign-up-faster-with">Sign Up faster with</p>
                    {/* <img className="Signupwiht" src={SignupImage}  onClick={(e)=>{signUpWithGoogleClicked(e)}}/> */}
                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item sm={4} />
                        <Grid item sm={4}>
                          <GoogleLoginButton
                            onClick={(e) => {
                              signUpWithGoogleClicked(e);
                            }}
                          />
                        </Grid>

                        <Grid item sm={2} />
                        {/* <Grid item sm={4}>
                          <FacebookLoginButton />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <br />
                  </Grid>
                  <Grid item sm={12}>
                    <Paper style={{ border: "solid 1px #dcdcdc" }}>
                      <Grid item sm={12}>
                        <p className="Join-a-Global-networ">
                          <br />
                          Join a Global network of Professionals and
                          Companies
                        </p>
                      </Grid>
                      <Grid item sm={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={12}>
                            <TextField
                              className={classes.textFeild}
                              className={classes.textFeild}
                              placeholder="First Name"
                              label="First Name"
                              variant="outlined"
                              required
                              type="text"
                              value={userData.firstName}
                              onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^\w\s]/gi, "");
                                setUserData({
                                  ...userData,
                                  firstName: value,
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                      <CreateIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              className={classes.textFeild}
                              placeholder="Last name"
                              label="Last name"
                              variant="outlined"
                              required
                              type="text"
                              value={userData.lastName}
                              onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^\w\s]/gi, "");
                                setUserData({
                                  ...userData,
                                  lastName: value,
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                      <CreateIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              className={classes.textFeild}
                              placeholder="Email"
                              label="Email"
                              variant="outlined"
                              required
                              type="email"
                              value={userData.email}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                      <EmailIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <TextField
                              className={classes.textFeild}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="Password"
                              required
                              label={"Password"}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <>
                                          <VisibilityIcon
                                            style={{ width: "15" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <VisibilityOffIcon
                                            style={{ width: "15" }}
                                          />
                                        </>
                                      )}
                                    </IconButton>

                                    <Link
                                      to={"/passwordPolicy"}
                                      style={{
                                        textDecoration: "none",
                                        color: "grey",
                                      }}
                                    >
                                      <ErrorOutlineIcon
                                        style={{ width: "15" }}
                                      />
                                    </Link>
                                  </InputAdornment>
                                ),
                              }}
                              value={userData.password}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  password: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                                onClose={handleSubmitClose}
                                open={SubmitOpen}
                                style={{
                                  border: "1px solid",
                                  borderColor: "black",
                                }}
                              >
                                <DialogTitle
                                  id="simple-dialog-title"
                                  style={{ background: "white" }}
                                >
                                  <Grid item xs={12}>
                                    <Grid container name="Submit">
                                      <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                          <CreditScoreIcon></CreditScoreIcon>
                                          <br />
                                          Awesome!
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </DialogTitle>
                                <Paper style={{ background: "white" }}>
                                  <Grid
                                    container
                                    name="Submit"
                                    className="GridMarginStyle"
                                  >
                                    <Grid item xs={2} />
                                    <Grid item xs={8}>
                                      <Typography align="center">
                                        A verification mail is sent on the email-id please verify and proceed to sign in.
                                        <br />
                                        <br />
                                      </Typography>
                                      <Grid item xs={12}>
                                        <Grid container>
                                        <Grid item xs={4}/>
                                        <Controls.Button
                                          text="Go to Sign In"
                                          onClick={(e) => {
                                            setSubmitOpen(false); 
                                            history.push("/login") 
                                          }}
                                        />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <br />
                                  </Grid>
                                  <br />
                                </Paper>
                              </Dialog>

                      <Grid item sm={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            defaultValue="seeker"
                            name="row-radio-buttons-group"
                            onChange={(e, value) => {
                              setUserType("" + value);
                            }}
                          >
                            <FormControlLabel
                              value="seeker"
                              control={<Radio />}
                              label="Iam a Seeker"
                            ></FormControlLabel>
                            <FormControlLabel
                              value="provider"
                              control={<Radio />}
                              label="Iam a Provider"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item sm={12}>
                        <p className="By-clicking-Agree-">
                          By clicking Agree & Join, you agree to the GigJobs
                          <Link
                            to={"/termsAndConditions"}
                            style={{ textDecoration: "none" }}
                          >
                            <b class="text-style-1"> Terms & Conditoins</b>{" "}
                          </Link>{" "}
                          and
                          <Link
                            to={"/privacyPolicy"}
                            style={{ textDecoration: "none" }}
                          >
                            <b class="text-style-2"> Privacy Policy</b>.{" "}
                          </Link>
                        </p>
                      </Grid>
                      <br />
                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} />
                          <Grid item sm={4}>
                            <div class="Join-for-FreeRectangle">
                              {/* <Button
                            type="submit"
                              onClick={() =>{ 
                                formRef.current.reportValidity()}}
                            >
                              <p className="Join-for-Free"> </p>
                            </Button> */}
                              <Button
                                type="submit"
                                onClick={() => {
                                  formRef.current.reportValidity();
                                }}
                              >
                                <p className="Join-for-Free">
                                  {"Agree and Join"}
                                </p>
                                {/* </Link> */}
                              </Button>
                            </div>
                            <br />
                          </Grid>
                          <Grid item sm={4}></Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item sm={3} />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </form>
      </div>
    </>
  );
}

export default CommonSignUp;
