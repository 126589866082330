import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';
import MuiPaper from '@mui/material/Paper';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid,Button ,Paper ,makeStyles } from '@material-ui/core';
import { Link } from "react-router-dom";
import Controls from "../resusable/controls/Controls";
import { useForm, Form } from '../resusable/useForm';
import Footer from "../resusable/footer";

const initialFValues = {
    id: 0,
    fullName: '',
    email: '',
    mobile: '',
    city: '',
    gender: 'male',
    departmentId: '',
    hireDate: new Date(),
    isPermanent: false,
};
const useStyles = makeStyles(theme => ({
    submitBtn: {
        float: 'right'
    }
}))
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
       /* borderBottom: 0,*/
    },
    '&:before': {
        display: 'none',
    },
    marginBottom: '0.9em'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        color: '#34495e'
    },
    '.MuiOutlinedInput-root': {
        margin: '0.4rem'
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
    width: '50%',
    margin: '0 auto 2em auto',
    paddingBottom: '1em',
    backgroundColor: '#f6f6f6',
    boxShadow : 'none'
}));

function BankDetailsPage() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('fullName' in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required."
        if ('email' in fieldValues)
            temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
        if ('mobile' in fieldValues)
            temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
        if ('departmentId' in fieldValues)
            temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const classes = useStyles();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()){
          //  employeeService.insertEmployee(values)
            resetForm()
        }
    }
   
    return (
        <>
                <MainHeader></MainHeader>
                <PaperDetails>
                    <PageHeader title="Bank Details" subTitle="Bank details is required to deposit your earnings" />
                    <Grid container>
                        
                        <Grid item xs={12}>
                            <div>
                                <Controls.Button
                                    text="Skip"
                                    onClick={resetForm} />
                                     <Link to = "/experienceDetails">
                                <Controls.Button
                                    type="submit"
                                    text="Save and Continue" className={classes.submitBtn}/>
                                 
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </PaperDetails>
                <Footer></Footer>
        </>

    )
}

export default BankDetailsPage
