import React, { useState } from "react";
import SignupImage from "../resources/signupwith.png";
import LogoImage from "../resources/logo@3x.png";
import {
  Grid,
  TextField,
  Paper,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";
import Snackbar from "@material-ui/core/Snackbar";
import { backendUrl } from "../config";
import Footer from "../resusable/footer";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signInWithEmailPswd, resetPswdLink ,signInWithGoogle,signInWithFacebook} from "../firebaseConfig";

import { FacebookLoginButton } from "react-social-login-buttons";
import {GoogleLoginButton} from "react-social-login-buttons"

const useStyles = makeStyles((theme) => ({
  textFeild: {
    margin: "12px !important",
  },
}));

function CommonLogin() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        startDate: "",
        endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    emailVerified:"",
    otpVerified:"",
  });
  const [postOfferData, setPostOfferData] = useState({
    _id: "",
    bankName: "",
    gigTitle: "",
    email: "",
    password: "",
    company: "",
    gigType: "",
    description: "",
    skillsRequired: [],
    startDate: "",
    endDate: "",
    hourlyRate: "",
    rateOnSkills: "",
    assesment: "",
  });
  const [loginData, setLoginData] = useState({
    emailId: "",
    password: "",
    newPassword: "",
    otp: "",
    isvalid: "",
    snackbarOpen: false,
    snackbarMsg: "",
    id: "",
  });

  const downloadImageBySeekerId = async (source, fileId,type) => {
    try {
      let ext = fileId.split('.').pop();
      axios.get(`${backendUrl}/getSeekerIdImage/` + source+"/"+fileId+"/"+type+"/"+ext,).then(
        (response) => {
          localStorage.setItem("userImage", response.data);
        })
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("photo not found")
      }
    }
  };

  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  const [userType, setUserType] = useState("seeker");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [CircularProgressHidden, setCircularProgressHidden] = useState(true);
  const handlesubmit = (e) => {
    e.preventDefault();
    setCircularProgressHidden(false);
    loginData.emailId = userData.email;
    loginData.password = userData.password;
    signInWithEmailPswd(userData.email, userData.password).then((res) => {
      if (!res.isRegistered) {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: res.message,
        });
        //alert("check email and password");
        setCircularProgressHidden(true);
      } else {
        if (
          loginData.emailId === "admin@gmail.com" 
        ) {
          localStorage.setItem("profile", "admin");
          localStorage.setItem("_id", "asdfseade11adfreewdere");
          localStorage.setItem(
            "name",
           "Admin"
          );
          history.push("/admin");
        } else {
          if (userType === "seeker") {
            axios
              .post(`${backendUrl}/jobseekers/findseekerById`, loginData)
              .then(
                (response) => {
                  setCircularProgressHidden(true);
                  //parseResponse(response);
                  if (response.data.user.length != 0) {
                    localStorage.setItem("token", response.data.JWTtoken);
                    localStorage.setItem("emailId", loginData.emailId);
                    localStorage.setItem("_id", response.data.user[0]._id);
                    localStorage.setItem("profile", "seeker");
                    localStorage.setItem(
                      "name",
                      response.data.user[0].firstName
                    );
                    //image path save in local storage
                    downloadImageBySeekerId(
                      response.data.user[0]._id,
                      response.data.user[0].photoId,
                      "image"
                    );
                    //alert( localStorage.getItem("_id"))
                    setLoginData({
                      emailId: loginData.emailId,
                      id: response.data.user[0]._id,
                      password: "",
                      isvalid: "true",
                      snackbarOpen: true,
                      snackbarMsg: "Login Successful!",
                    });
                    if(response.data.user[0].phoneNo!="")
                    history.push("/seekersearchjobListing");
                    else
                    history.push("/gigseekersignup/")
                  } else {
                    setLoginData({
                      emailId: "",
                      password: "",
                      snackbarOpen: true,
                      snackbarMsg: "Error : Invalid User Id or password",
                    });
                  }
                },
                (error) => {
                  setCircularProgressHidden(true);
                  console.log("error" + error);
                  setLoginData({
                    emailId: "",
                    password: "",
                    isvalid: "false",
                    snackbarOpen: true,
                    snackbarMsg:
                      "Error : Failed at server, pls check internet and try again",
                  });
                }
              );
          } else {
            postOfferData.email = userData.email;
            postOfferData.password = userData.password;
            axios
              .post(`${backendUrl}/jobproviders/findproviderById`, loginData)
              .then(
                (response) => {
                  setCircularProgressHidden(true);
                  localStorage.setItem("emailId", loginData.emailId);
                  //alert(localStorage.getItem("emailId"))
                  if (response.data.user.length != 0) {
                    localStorage.setItem("emailId", loginData.emailId);
                    localStorage.setItem("_id", response.data.user[0]._id);
                    localStorage.setItem("profile", "provider");
                    localStorage.setItem(
                      "name",
                      response.data.user[0].firstName
                    );
                    //alert( localStorage.getItem("_id"))
                    setLoginData({
                      emailId: loginData.emailId,
                      password: "",
                      isvalid: "true",
                      id: response.data.user[0]._id,
                      snackbarOpen: true,
                      snackbarMsg: "Login Successful!",
                    });     
                    history.push("/gigproviderjobposts/")
                  } else {
                    setLoginData({
                      emailId: "",
                      password: "",
                      snackbarOpen: true,
                      snackbarMsg: "Error : Invalid User Id or password",
                    });
                  }
                },
                (error) => {
                  setCircularProgressHidden(true);
                  console.log("error" + error);
                  setLoginData({
                    emailId: "",
                    password: "",
                    newPassword: "",
                    otp: "",
                    isvalid: "false",
                    snackbarOpen: true,
                    snackbarMsg:
                      "Error : Failed at server, pls check internet and try again",
                  });
                }
              );
          }
        }
      }
    });
  };

  const signUpWithFacebookClicked=(e)=>{

    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Info: You are signing up as "+userType+" please recheck and proceed.",
    });
    signInWithFacebook().then((response)=>{
      if(response.success)
      {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: response.msg,
        });
        loginData.emailId=response.userEmail;
    if (
      loginData.emailId === "admin@gmail.com" 
    ) {
      localStorage.setItem("profile", "admin");
      history.push("/admin");
    } else {
      if (userType === "seeker") {
        axios
          .post(`${backendUrl}/jobseekers/findseekerById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              //parseResponse(response);
              if (response.data.user.length != 0) {
                localStorage.setItem("token", response.data.JWTtoken);
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "seeker");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                downloadImageBySeekerId(
                  response.data.user[0]._id,
                  response.data.user[0].photoId,
                  "image"
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  id: response.data.user[0]._id,
                  password: "",
                  isvalid: "true",
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/seekersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password or role",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      } else {
        postOfferData.email = userData.email;
        postOfferData.password = userData.password;
        axios
          .post(`${backendUrl}/jobproviders/findproviderById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              localStorage.setItem("emailId", loginData.emailId);
              //alert(localStorage.getItem("emailId"))
              if (response.data.user.length != 0) {
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "provider");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  password: "",
                  isvalid: "true",
                  id: response.data.user[0]._id,
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/providersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                newPassword: "",
                otp: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      }
    }} else{
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Failed, please try again!"+response.msg,
      });
    }
   }).catch((err)=>{
    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Failed, please try again!"+err.msg,
    });
   })

  }
  const signUpWithGoogleClicked=(e)=>{

    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Info: You are signing up as "+userType+" please recheck and proceed.",
    });
    signInWithGoogle().then((response)=>{
      if(response.success)
      {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: response.msg,
        });
        loginData.emailId=response.userEmail;
    if (
      loginData.emailId === "admin@gmail.com" 
    ) {
      localStorage.setItem("profile", "admin");
      history.push("/admin");
    } else {
      if (userType === "seeker") {
        axios
          .post(`${backendUrl}/jobseekers/findseekerById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              //parseResponse(response);
              if (response.data.user.length != 0) {
                localStorage.setItem("token", response.data.JWTtoken);
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "seeker");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                downloadImageBySeekerId(
                  response.data.user[0]._id,
                  response.data.user[0].photoId,
                  "image"
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  id: response.data.user[0]._id,
                  password: "",
                  isvalid: "true",
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/seekersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password or role",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      } else {
        postOfferData.email = userData.email;
        postOfferData.password = userData.password;
        axios
          .post(`${backendUrl}/jobproviders/findproviderById`, loginData)
          .then(
            (response) => {
              setCircularProgressHidden(true);
              localStorage.setItem("emailId", loginData.emailId);
              //alert(localStorage.getItem("emailId"))
              if (response.data.user.length != 0) {
                localStorage.setItem("emailId", loginData.emailId);
                localStorage.setItem("_id", response.data.user[0]._id);
                localStorage.setItem("profile", "provider");
                localStorage.setItem(
                  "name",
                  response.data.user[0].firstName
                );
                //alert( localStorage.getItem("_id"))
                setLoginData({
                  emailId: loginData.emailId,
                  password: "",
                  isvalid: "true",
                  id: response.data.user[0]._id,
                  snackbarOpen: true,
                  snackbarMsg: "Login Successful!",
                });
                history.push("/providersearchjobListing");
              } else {
                setLoginData({
                  emailId: "",
                  password: "",
                  snackbarOpen: true,
                  snackbarMsg: "Error : Invalid User Id or password",
                });
              }
            },
            (error) => {
              setCircularProgressHidden(true);
              console.log("error" + error);
              setLoginData({
                emailId: "",
                password: "",
                newPassword: "",
                otp: "",
                isvalid: "false",
                snackbarOpen: true,
                snackbarMsg:
                  "Error : Failed at server, pls check internet and try again",
              });
            }
          );
      }
    }} else{
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Failed, please try again!"+response.msg,
      });
    }
   }).catch((err)=>{
    setLoginData({
      snackbarOpen: true,
      snackbarMsg: "Failed, please try again!"+err.msg,
    });
   })

  }

  const formRef = React.useRef();
  const history = useHistory();

  const classes = useStyles();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <form
          ref={formRef}
          id="seekerForm"
          onSubmit={handlesubmit}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item sm={2}>
                  <a href="/">
                    <img className="Logo" src={LogoImage} />
                    </a>
                  </Grid>
                  <Grid item sm={7}></Grid>
                </Grid>
              </Paper>
              <Grid container spacing={3}>
                <Grid item sm={3} />
                <Grid item sm={6}>
                  <Grid item sm={12}>
                    <div hidden={CircularProgressHidden}>
                      <CircularProgress hidden={CircularProgressHidden} />
                    </div>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={loginData.snackbarOpen}
                      autoHideDuration={5000}
                      message={loginData.snackbarMsg}
                      action={[
                        <IconButton
                          key="close"
                          color="inherit"
                          onClick={snackBarCloseEvent}
                        >
                          x
                        </IconButton>,
                      ]}
                      onClose={() => setLoginData({ snackbarOpen: false })}
                    />
                    <p className="Sign-up-faster-with">Sign In faster with</p>
                   

                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item sm={4} />
                        <Grid item sm={4}>
                          <GoogleLoginButton
                            onClick={(e) => {
                              signUpWithGoogleClicked(e);
                            }}
                          />
                        </Grid>

                        <Grid item sm={2} />
                        {/* <Grid item sm={4}>
                          <FacebookLoginButton onClick={(e) => {
                              signUpWithFacebookClicked(e);
                            }}/>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    {/* <img className="Signupwiht" src={SignupImage}  onClick={(e)=>{signUpWithGoogleClicked(e)}} /> */}
                    
                    <br />
                  </Grid>
                  <Grid item sm={12}>
                    <Paper style={{ border: "solid 1px #dcdcdc" }}>
                      <Grid item sm={12}>
                        <p className="Join-a-Global-networ">
                          Or Sign In with Email Id
                        </p>
                        <Grid item sm={12}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              defaultValue="seeker"
                              name="row-radio-buttons-group"
                              onChange={(e, value) => {
                                setUserType("" + value);
                              }}
                            >
                              <FormControlLabel
                                value="seeker"
                                control={<Radio />}
                                label="Iam a Seeker"
                              ></FormControlLabel>
                              <FormControlLabel
                                value="provider"
                                control={<Radio />}
                                label="Iam a Provider"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Grid>
                          <Grid item sm={12}>
                            <TextField
                              className={classes.textFeild}
                              placeholder="Email"
                              label="Email"
                              variant="outlined"
                              required
                              type="email"
                              value={userData.email}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility">
                                      <EmailIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <TextField
                              className={classes.textFeild}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="Password"
                              required
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              value={userData.password}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  password: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12}></Grid>
                      <br />
                      <Grid item sm={12}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} />
                          <Grid item sm={4}>
                            <div class="Join-for-FreeRectangle" >
                              {/* <Button
                            type="submit"
                              onClick={() =>{ 
                                formRef.current.reportValidity()}}
                            >
                              <p className="Join-for-Free"> </p>
                            </Button> */}

                              <Button
                                type="submit"
                               
                                onClick={() => {
                                  formRef.current.reportValidity();
                                }}
                              >
                                
                                <p className="Join-for-Free">{"Sign in"}</p>
                                {/* </Link> */}
                              </Button>
                            </div>
                            <br />
                            <>
                              <Link
                                to={"/signUp"}
                                style={{ textDecoration: "none",color:"brown" }}
                              >
                                <b class="text-style-1"> {"New here?"}</b>
                              </Link>
                              /
                              <Link
                                to={"/login"}
                                style={{ textDecoration: "none" ,color:"#f3670d"}}
                                onClick={() => {
                                  if (userData.email != "")
                                    resetPswdLink(userData.email).then((res)=>{
                                      setLoginData({
                                        snackbarOpen:true,
                                        snackbarMsg:res.message
                                      })
                                    }).catch((err)=>{
                                      setLoginData({
                                        snackbarOpen:true,
                                        snackbarMsg:err.message
                                      })
                                    })
                                  else 
                                  setLoginData({
                                    snackbarOpen:true,
                                    snackbarMsg:"Please enter email and try agian"
                                  })
                                }}
                                //style={{ textDecoration: "none" }}
                              >
                                <b class="text-style-1">{"Forgot Password?"}</b>
                              </Link> {"OR "}
                              <Link
                                to={"/loginWithPhone"}
                                style={{ textDecoration: "none" ,color:"blue"}}
                                //style={{ textDecoration: "none" }}
                              >
                                <b class="text-style-1">{"Login with Mobile?"}</b>
                              </Link>
                            </>
                          </Grid>
                          <Grid item sm={4}></Grid>
                        
                        </Grid>
                      </Grid>
                      <br/>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item sm={3} />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Footer />
        </form>
      </div>
    </>
  );
}

export default CommonLogin;
