import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ReactRoundedImage from "react-rounded-image";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import Arrow from "../resources/arrow@3x.png";

import { Grid } from "@material-ui/core";
export default function DiscoverUsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  return (
    <Grid container>
    <Grid item sm={12}>
      <Box sx={{ display: "flex", alignItems: "right", textAlign: "center" }}>
        <Tooltip title="">
          <IconButton onClick={handleClick} size="small">
            <p className="Best-Online-Gigs-Net">Discover Us &nbsp; </p>
            <ReactRoundedImage
              image={Arrow}
              roundedSize="1"
              imageWidth="20"
              imageHeight="20"
            ></ReactRoundedImage>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 10,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        
      >
        <MenuItem
          onClick={() => {
            history.push("/aboutUs");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"About Us"}
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            history.push("/services");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"Services"}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/contactUs");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"Contact us"}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/termsAndConditions");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"T & C"}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/refundAndCancellations");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"Refund"}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/privacyPolicy");
          }}
          style={{fontFamily:"Poppins-Regular",fontSize:"16px",color:"black"}}
        >
          {"Privacy Policy"}
        </MenuItem>
      </Menu>
    </Grid>
    </Grid>
  );
}
