import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  makeStyles,
  TextField,
  IconButton,
} from "@material-ui/core";
import download from "downloadjs";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import ArrowForwardIosSharp from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Controls from "../resusable/controls/Controls";
import { useForm, Form } from "../resusable/useForm";
import Footer from "../resusable/footer";
import { useHistory } from "react-router-dom";
import { backendUrl } from "../config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import Download from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";


const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};
const INewCertification = {
  name: "",
  issuedBy: "",
  link: "",
};

const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    //   borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "#34495e",
  },
  ".MuiOutlinedInput-root": {
    margin: "0.4rem",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    float: "right",
  },
  addMoreBtn: {
    backgroundColor: "black",
    borderRadius: 0,
    padding: "8px",
    fontSize: "0.8em",
  },
  removeBtn: {
    backgroundColor: "black",
    borderRadius: 0,
    padding: "8px",
    fontSize: "0.8em",
  },
  uploadInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    marginTop: "15px !important",
    borderRadius: "4px",
    "&.input": {
      padding: "18.5px 14px",
    },
  },

  "&.MuiButton-contained": {
    color: "#f3670d !important",
  },
}));
function CertificationsDetails() {
  const history = useHistory();
  const location = useLocation();
  let email = localStorage.getItem("emailId")//location.pathname.substring(23, location.pathname.length);
  const [loginData, setLoginData] = useState({
    snackbarOpen: false,
    snackbarMsg: "",
  });
  const snackBarCloseEvent = (event) => {
    setLoginData({
      snackbarOpen: false,
      snackbarMsg: "",
    });
  };
  useEffect(() => {
    loadUser();
  }, []);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dateOfBirth: "",
    gender: "",
    phoneNo: "",
    alternatePhoneNo: "",
    Nationality: "",
    address: "",
    zip: "",
    qualifications: [
      {
        schoolName: "",
        degree: "",
        specialization: "",
        //startDate: "",
        //endDate: "",
      },
    ],
    experiences: [
      {
        title: "",
        company: "",
        location: "",
        //startDate: "",
        //endDate: "",
      },
    ],
    secondarySkills: [],
    certifications: [
      {
        name: "",
        issuedBy: "",
        link: "",
      },
    ],
    awards: [
      {
        name: "",
        year: "",
      },
    ],
    gigType: "",
    banckName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    accountType: "",
    panCard: "",
    adharCard: "",
    photo: "",
    photoId: "",
    resume: "",
    resumeId: "",
    link: "",
  });
  const classes = useStyles();

  const [CircularProgressHidden, setCircularProgressHidden] = useState(false);
  const handleSubmit = (e) => {
    setCircularProgressHidden(false);
    e.preventDefault();
    if (userData.link != "") handleOnUploadCertificate(e);

    if (true) {      
      axios.post(`${backendUrl}/jobseekers`, userData).then(
        (response) => {
          setCircularProgressHidden(true);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Submision Successful!",
          });
          history.push("/awardsDetails/");
          axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
            (response) => {
              if (response.data.user[0] != undefined)
                setUserData(response.data.user[0]);
            },
            (error) => {
              console.log("error" + error);
            }
          );
        },
        (error) => {
          setCircularProgressHidden(true);
          console.log("error" + error);
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Error submitting data please try again.",
          });
        }
      );
    }
  };

  const handleChangeInput = (event, index) => {
    const newCertification = userData.certifications.map((i, elindex) => {
      if (index === elindex) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setUserData((userData) => ({
      ...userData,
      certifications: newCertification,
    }));
  };

  const handleChangeInputFileUpload = (event, index) => {
    userData.link = event.target.files[0]
    const newCertification = userData.certifications.map((i, elindex) => {
      if (index === elindex) {
        i.link = event.target.files[0].name;
      }
      return i;
    });
    setUserData((userData) => ({
      ...userData,
      certifications: newCertification,
    }));
  };

  const downloadFileBySeekerId = async (source, fileId, type) => {
    try {
      let ext = fileId.split('.').pop();
      const result = await axios.get(
        `${backendUrl}/downloadCertificateBySeekerId/` + source+"/"+fileId+"/"+type+"/"+ext,
        {
          responseType: "blob",
        }
      );
      const filename = fileId;
      setCircularProgressHidden(true);
      setLoginData({
        snackbarOpen: true,
        snackbarMsg: "Download Successful!",
      });
      return download(result.data, filename, "application/"+ext);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error Downloading",
        });
      }
    }
  };

  const handleOnUploadCertificate = async (event) => {
    event.preventDefault();
    try {
        let file = userData.link;
        let filname = file.name;
        let ext = filname.split('.').pop();
        if (ext=="pdf" || ext=="docx" || ext=="doc" || ext=="xlsx" || ext=="xls") {
          const title = filname;
          const description = filname;
          if (title.trim() !== "" && description.trim() !== "") {
            if (file) {
              const formData = new FormData();
              formData.append(
                "gigSeekerId",
                localStorage.getItem("_id") + ""
              );
              formData.append("file", file);
              formData.append("title", title);
              formData.append("description", description);
              await axios
                .post(`${backendUrl}/upload`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(() => {
                  setLoginData({
                    snackbarOpen: true,
                    snackbarMsg: "File Uploaded Successfull.",
                  });
                  history.push("/awardsDetails/" );
                });
            } else {
              setLoginData({
                snackbarOpen: true,
                snackbarMsg: "Please select a file to add.",
              });
            }
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please enter all the field values.",
          });
        }
        } else {
          setLoginData({
            snackbarOpen: true,
            snackbarMsg: "Please upload only valid format",
          });
        }
    } catch (error) {
      error.response &&
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "" + error.response.data,
        });
    }
  };

  const loadUser = () => {
    //let emailId ={email} ; //props.emailid;
    // if(!isCurrentURL("/gigSeekerLogin"))
    // emailId=localStorage.getItem('emailId');

    axios
      .get(
        `${backendUrl}/jobseekers/` + email
        //,{headers: {"AccessToken": localStorage.getItem("token")}}
      )
      .then(
        (response) => {
          setCircularProgressHidden(true);
          if (
            response.data.user != undefined &&
            response.data.user[0] != undefined
          ) {
            setUserData(response.data.user[0]);
            //setgigSeekerId(response.data.user[0]._id);
            setLoginData({
              snackbarOpen: true,
              snackbarMsg: "Info: Profile load Successful",
            });
          } else {
          }
        },
        (error) => {
          console.log("error" + error);
        }
      );
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length != 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);


  const handleAddIcon = () => {

    userData.certifications.push({ id: uuidv4(), ...INewCertification });
    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Add Successful!",
        });
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };
  const handleRemoveIcon = () => {
    userData.certifications.splice(userData.certifications.length - 1);
    axios.post(`${backendUrl}/jobseekers`, userData).then(
      (response) => {
        setCircularProgressHidden(true);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Remove Successful!",
        });
        axios.get(`${backendUrl}/jobseekers/` + userData.email).then(
          (response) => {
            if (response.data.user[0] != undefined)
              setUserData(response.data.user[0]);
          },
          (error) => {
            console.log("error" + error);
          }
        );
      },
      (error) => {
        setCircularProgressHidden(true);
        console.log("error" + error);
        setLoginData({
          snackbarOpen: true,
          snackbarMsg: "Error submitting data please try again.",
        });
      }
    );
  };

  const handleAccExpand=(idx)=>{
    if(idx===0)
    return true;
   };

  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <PaperDetails>
        <div hidden={CircularProgressHidden}>
          <CircularProgress hidden={CircularProgressHidden} />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginData.snackbarOpen}
          autoHideDuration={5000}
          message={loginData.snackbarMsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={snackBarCloseEvent}
            >
              x
            </IconButton>,
          ]}
          onClose={() => setLoginData({ snackbarOpen: false })}
        />
        <PageHeader
          title="Certifications"
          subTitle="Highlight your profile by sharing certifications with Recruiters"
          progressBarValue={60}
        />
        {userData.certifications.map((certifications, index) => (
          <>
          <br/>
          <Paper elevation={5}>
            <Accordion expanded={handleAccExpand(index)}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Certifications {index+1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Form>
                    <Grid container>
                      <Grid item xs={12}>
                        <Controls.Input
                          name="name"
                          label="Certificate Title"
                          //value={values.jobTitle}
                          error={errors.name}
                          value={certifications.name}
                          onChange={(e) => handleChangeInput(e, index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 0 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 0 }}
                    >
                      <Grid item xs={6}>
                        <Controls.Input
                          label="Isssued By"
                          name="issuedBy"
                          //value={values.company}
                          error={errors.company}
                          value={certifications.issuedBy}
                          onChange={(e) => handleChangeInput(e, index)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Attach Certificate"
                          variant="outlined"
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChangeInputFileUpload(e, index)}
                        />
                        <p className="Only-PDF-Doc-PPT-a">
                            Only PDF, Docx allowed
                        </p>
                        <Grid container>
                          <Grid item sm={1} />
                          <Grid
                            item
                            sm={1}
                            hidden={certifications.link == ""}
                          >
                            <Download
                             hidden={certifications.link == ""}
                              onClick={() => {
                                downloadFileBySeekerId(
                                  localStorage._id + "",
                                  certifications.link,
                                  "application"
                                );
                              }}
                            />
                          </Grid>
                          {/* <Grid
                            item
                            sm={1}
                            hidden={certifications.link == ""}
                          >
                            <CancelIcon
                              onClick={() => {
                                setUserData({
                                  ...userData,
                                  link : "",
                                });
                              }}
                            />
                          </Grid> */}
                          <Grid item sm={6}>
                            <p style={{ margin: "0" }}>
                              {certifications.link}
                            </p>
                          </Grid>
                            </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Typography>
              </AccordionDetails>
            </Accordion>
            </Paper>
          </>
        ))}
        <Grid container>
          <Grid item style={{ margin: "0 auto" }}>
            <Controls.Button
              text="+ Add More"
              className={classes.addMoreBtn}
              onClick={() => {
                handleAddIcon();
              }}
            />
            <Controls.Button
              text="x Remove"
              className={classes.removeBtn}
              onClick={() => {
                handleRemoveIcon();
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={12}>
            <div>
              <Link
                style={{ textDecoration: "none" }}
                to={"/awardsDetails/"}
              >
                <Controls.Button text="Skip" />
              </Link>
              <Controls.Button
                type="submit"
                text="Save and Continue"
                className={classes.submitBtn}
                onClick={handleSubmit}
              />
            </div>
          </Grid>
        </Grid>
      </PaperDetails>
      <Footer></Footer>
    </>
  );
}

export default CertificationsDetails;
