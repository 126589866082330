import React from "react";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import WorkIcon from "@mui/icons-material/Work";
import GroupsIcon from "@mui/icons-material/Groups";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { withStyles } from "@material-ui/core/styles";
import GridImage1 from "../resources/image@3x.png";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Controls from "../resusable/controls/Controls";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import PageHeader from "../resusable/pageHeader";
import { makeStyles } from "@material-ui/core/styles";
import ListItemButton from "@mui/material/ListItemButton";
const WhiteTextTypography = withStyles({
  root: {
    color: "#34495e",
  },
})(Typography);
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "90%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));

const useStyles = makeStyles({
  ButtonMarginStyle: {},
  wrapper: {
    //border: "solid 1px #dcdcdc",
    backgroundColor: "#f6f6f6",
    margin: "0 1rem",
  },
});
const Admin = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyles();

  return (
    <>
      <MainHeader />

      <br />

      <PaperDetails>
        <Grid xs={12}>
          <div style={{ marginLeft: 25 }}>
            <br />
            <PageHeader
              title="Administrator "
              subTitle="Perform privileged action , know payment details and many more. Use below buttons to navigte quick."
            />
          </div>
        </Grid>
        <Grid container sm={12}>
          <Grid item xs={2}>
            <Box className={classes.wrapper}>
              <List component="nav">
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    className={classes.jobHeading}
                  >
                    <AssistantDirectionIcon />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.jobHeading}
                  >
                    &nbsp; &nbsp; &nbsp;{"Navigate to"}
                  </Typography>
                </ListItemButton>
              </List>
              <Divider />
              <Grid item sm={12} name="ButtonsGrid">
                <br />
                <br />
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/admin/SeekerList"}
                >
                  <GroupsIcon
                    sx={{ color: "black" }}
                    style={{ marginLeft: 10 }}
                  />
                  <Controls.Button
                    className={classes.submitBtn}
                    type="submit"
                    text="Gig Seekers"
                  ></Controls.Button>
                </Link>
                <br />
                <br />
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/admin/ProviderList"}
                >
                  <WorkIcon
                    sx={{ color: "black" }}
                    style={{ marginLeft: 10 }}
                  />
                  <Controls.Button
                    className={classes.submitBtn}
                    type="submit"
                    text="Gig Providers"
                  />
                </Link>
                <br />
                <br />
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/admin/Placements"}
                >
                  <HowToRegIcon
                    sx={{ color: "black" }}
                    style={{ marginLeft: 10 }}
                  />
                  <Controls.Button
                    className={classes.submitBtn}
                    type="submit"
                    text="Placements"
                  />
                </Link>
                <br />
                <br />
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={10}>
            <Paper elevation={0} style={{ backgroundColor: "#f6f6f6" }}>
              <img className="Gridimage1" src={GridImage1} />
            </Paper>
            <br />
          </Grid>
        </Grid>
      </PaperDetails>
      <Footer />
    </>
  );
};
export default Admin;
