import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

import {_firebaseConfig} from "../ThirdpartyConfig"

const config = _firebaseConfig;


firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () =>
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      localStorage.setItem("emailVerified", "yes");
      //await sendEmailVerificationNow(user);
      return {
        userEmail: user.email,
        firstName: user.displayName,
        success: true,
        msg: "Sign Up Successful ! use the same to login.",
      };
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      return {
        user: "na",
        fileName: "na",
        success: false,
        msg: "" + errorCode + " " + errorMessage,
      };
      // ...
    });

const auth0 = getAuth();
const provider0 = new FacebookAuthProvider();
provider0.addScope("user_birthday");
export const signInWithFacebook = () =>
  signInWithPopup(auth0, provider0)
    .then(async (result) => {
      // The signed-in user info.
      const user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      await sendEmailVerificationNow(user);
      return {
        userEmail: user.email,
        firstName: user.displayName,
        success: true,
        msg: "Sign Up Successful ! use the same to login.",
      };

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);
      return {
        user: "na",
        fileName: "na",
        success: false,
        msg: "" + errorCode + " " + errorMessage,
      };

      // ...
    });

const auth1 = getAuth();
export const createUserWithEmailPswd = async (email, password) =>
  createUserWithEmailAndPassword(auth1, email, password)
    .then(async (userCredential) => {
      // Signed in
      let user = userCredential.user;
      await sendEmailVerificationNow(user);
      return {
        status: "suscess",
        userDetails: user,
        isRegistered: true,
        message:
          "User creation successful. Email verification link please check and follow the steps mailed.",
      };
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert("failed---" + errorCode + "  " + errorMessage);
      return {
        status: "failed",
        isRegistered: false,
        message: "Error: " + errorCode + "  " + errorMessage,
      };
      // ..
    });

export const sendEmailVerificationNow = (user) => {
  //alert("check your email and verify urself will go ahead only once verified");
  if(user.email.includes("test_")){
    return {
      status: "suscess",
      userDetails: user,
      isRegistered: true,
      message: "Email verification mail sent successful",
    };
  }
  sendEmailVerification(user)
    .then(() => {
      return {
        status: "suscess",
        userDetails: user,
        isRegistered: true,
        message: "Email verification mail sent successful",
      };
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      //alert(""+errorCode+"  "+errorMessage)
      return {
        status: "failed",
        isRegistered: false,
        message:
          "Error :Email verification mail sending failed.\n" +
          errorCode +
          "  " +
          errorMessage,
      };
    });
};

export const signInWithEmailPswd = (email, password) =>
  signInWithEmailAndPassword(auth1, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      //alert("email verification status " + user.emailVerified);
      let msg = "Sign in successful.";
      if(user.email.includes("test_")||user.email.includes("admin@gmail.com")){
        localStorage.setItem("emailVerified", "yes");
        return {
          status: "suscess",
          isRegistered: true,
          message: msg,
          emailVerified: user,
        };
      }
      if (user.emailVerified) {
        localStorage.setItem("emailVerified", "yes");
        return {
          status: "suscess",
          isRegistered: true,
          message: msg,
          emailVerified: user,
        };
  
      } else {
        msg = "Error: Please verify your emailId/Password";
        localStorage.setItem("emailVerified", "no");
        return {
          status: "falied",
          isRegistered: false,
          message: msg,
          emailVerified: user,
        };
  
      }
      //alert("successFull SignIn")
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      //alert(""+errorCode+"  "+errorMessage)
      return {
        status: "failed",
        isRegistered: false,
        message:
          "Error : Check email id, password and role.\n" +
          errorCode +
          "  " +
          errorMessage,
        emailVerified: "",
      };
    });

export const signOutLogOut = () =>
  signOut(auth1)
    .then(() => {
      //alert("SignedOut")
      return {
        status: "suscess",
        isRegistered: true,
        message: "Sign out successful",
      };
      // Sign-out successful.
    })
    .catch((error) => {
      //alert("error Signing out")
      return {
        status: "failed",
        isRegistered: false,
        message: "Sign out failed",
      };
      // An error happened.
    });

export const resetPswdLink = (email) =>
  sendPasswordResetEmail(auth1, email)
    .then(() => {
      //alert("Password reset email sent!");
      return {
        status: "suscess",
        isRegistered: true,
        message:
          "Success: Password reset email sent, please follow the steps e-mailed!",
      };
      // ..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      //alert(""+errorCode+"  "+errorMessage)

      return {
        status: "failed",
        isRegistered: false,
        message: "Error: " + errorCode + "  " + errorMessage,
      };
      // ..
    });

// const recaptchaauth = getAuth();
// window.recaptchaVerifier = new RecaptchaVerifier('root', {}, recaptchaauth);
// const appVerifier = window.recaptchaVerifier;

// const PhoneNumberauth = getAuth();
// export const signInWithPhoneNumberVerification =(phoneNumber)=>signInWithPhoneNumber(PhoneNumberauth, phoneNumber, appVerifier)
//   .then((confirmationResult) => {
//     // SMS sent. Prompt user to type the code from the message, then sign the
//     // user in with confirmationResult.confirm(code).
//     window.confirmationResult = confirmationResult;
//     // ...
//   })
//   .catch((error) => {
//     // Error; SMS not sent
//     // ...
//   });

export default firebase;
