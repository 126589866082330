import React from "react";
import {
  Grid,
  Paper,
  makeStyles,
  TextField,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "../resusable/pageHeader";
import MainHeader from "../resusable/mainHeader";
import Footer from "../resusable/footer";
import { margin } from "@mui/system";
import Controls from "../resusable/controls/Controls";
import {useEffect} from 'react'
import EmailIcon from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
const PaperDetails = styled(MuiPaper)(({ theme }) => ({
  width: "50%",
  margin: "0 auto 2em auto",
  paddingBottom: "1em",
  backgroundColor: "#f6f6f6",
  boxShadow: "none",
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    //  paddingLeft:theme.spacing(4),

    fontFamily: ["Poppins-Bold", "Open Sans"].join(","),
    margin: "0",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    textAlign: "center",
  },
  textFeild: {
    margin: "6px !important",
  },
}));
function ServiceRequests() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader></MainHeader>
      <br />
      <Grid sm={12}>
        <PaperDetails>
          <PageHeader
            title="Services"
            subTitle="Connecting gig's with global opportunities. We make remote gig talent & opportunities accessible."
          />
          <Grid sm={12}>
            <Paper >
                <Grid container sm={12}>
                    <Grid item sm={1}/>
                    <Grid item sm={11}>
            <Typography style={{ margin: 10 }}>
                <p className="Join-a-Global-networ">
                 
                {/* Sign up to get information about our services.Email us support@gigjobs.info Or */}
                Do you want to hear from us about our services? All you need to do is to sign up or email us at support@gigjobs.info
                  </p></Typography>
              <Grid container>
                <Grid item sm={7}>
                  {" "}
                  <TextField
                    className={classes.textFeild}
                    placeholder="Email"
                    label="Email"
                    variant="outlined"
                    required
                    fullWidth
                    type="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <EmailIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={1}/>
                <Grid item sm={4}>
                <div style={{ marginTop:10}}>
                  <Link style={{ textDecoration: "none"}} to={"/signup"}>
                    <Controls.Button text="Join Us" />
                  </Link>
                </div>
                </Grid>
              </Grid>
              </Grid>
              </Grid>
            </Paper>
          </Grid>
        </PaperDetails>
      </Grid>
      <Footer></Footer>
    </>
  );
}

export default ServiceRequests;
